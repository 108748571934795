import { useDispatch } from "react-redux";
import {
  CloseConfirmationDialog,
  OpenConfirmationDialog,
} from "@app/store/actions/core.actions";
import { ConfirmationDialog } from "@app/types/confirmation.dialog.interface";

export const useConfirmationDialog = () => {
  const dispatch = useDispatch();

  const openConfirmationDialog = (confirmationDialog: ConfirmationDialog) => {
    dispatch(OpenConfirmationDialog(confirmationDialog));
  };

  const closeConfirmationDialog = () => {
    dispatch(CloseConfirmationDialog());
  };

  return { openConfirmationDialog, closeConfirmationDialog };
};
