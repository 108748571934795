import { useApi } from "@app/hooks";
import { PaginatedResponse } from "@app/types";
import { Itinerary } from "@app/itineraries/types/interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCustomItineraryGeneralInfos,
  selectCustomItineraryGeneralInfosLoaded,
} from "@app/store/selectors/itineraries.selectors";
import { useEffect } from "react";
import { LoadCustomItineraryGeneralInfos } from "@app/store/actions/itineraries.actions";

export const useGeneralInfoCustomItinerary = () => {
  const { request } = useApi();
  const dispatch = useDispatch();
  const customItineraryGeneralInfostLoaded: boolean = useSelector(
    selectCustomItineraryGeneralInfosLoaded
  );
  const customItineraryGeneralInfos: Itinerary = useSelector(
    selectCustomItineraryGeneralInfos
  );

  useEffect(() => {
    if (customItineraryGeneralInfostLoaded) {
      return;
    }
    getGeneralInfosCustomItinerary();
  }, []);

  const getGeneralInfosCustomItinerary = async () => {
    //It has only general data: description, images and an optional video
    const activeCustomItineraryGeneralInfos = await request<
      PaginatedResponse<Itinerary>
    >({
      url: `records/general_infos_custom_itinerary`,
      method: "GET",
      queryParams: {
        datafy_fields: "id,description,images.file,images.id,video",
        active: "True",
        pageSize: 1,
      },
    });
    dispatch(
      LoadCustomItineraryGeneralInfos(
        activeCustomItineraryGeneralInfos.results[0]
      )
    );
  };

  return { customItineraryGeneralInfos };
};
