import { Control, Controller, FieldValues } from "react-hook-form";
import React, { useState } from "react";
import { DatePicker as DatePickerUI } from "@material-ui/pickers";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { FieldPath } from "react-hook-form/dist/types";
import { InputAdornment } from "@material-ui/core";

interface DatePickerProps {
  control: Control<FieldValues>;
  name: string;
  label: string;
  required: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  asBirthday?: boolean;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsDate"
  >;
  inputVariant?: "outlined" | "filled" | "standard";
  minDate?: Date;
  maxDate?: Date;
  minDateMessage?: string;
  maxDateMessage?: string;
}

export const DatePicker = ({
  inputVariant = "outlined",
  control,
  name,
  label,
  required,
  disablePast,
  disableFuture,
  rules = {},
  asBirthday = false,
  minDateMessage,
  minDate,
  maxDate,
  maxDateMessage,
}: DatePickerProps) => {
  const [datePickerError, setDatePickerError] = useState(null);
  const [open, setOpen] = useState(false);

  const onError = (data) => {
    setDatePickerError(data);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <DatePickerUI
          autoOk
          allowKeyboardControl={false}
          className="full-width"
          required={required}
          inputVariant={inputVariant}
          onChange={onChange}
          value={value}
          label={label}
          onBlur={onBlur}
          openTo={asBirthday ? "year" : "date"}
          minDate={minDate}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          maxDate={maxDate}
          disableFuture={disableFuture}
          onOpen={onOpen}
          onClose={onClose}
          error={(!!error || !!datePickerError) && !open}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={"cursor-pointer"}>
                <img
                  alt="icon"
                  src="/icons/date-picker.svg"
                  width={20}
                  height={18}
                />
              </InputAdornment>
            ),
          }}
          disablePast={disablePast}
          format="MMM dd, yyyy"
          helperText={
            error && !open
              ? error.message
              : !!datePickerError
              ? datePickerError
              : null
          }
          onError={onError}
        />
      )}
      rules={
        required
          ? {
              ...rules,
              required: "This filed is required.",
              validate: (value: string) =>
                !!datePickerError && !open ? datePickerError : null,
            }
          : rules
      }
    />
  );
};

export default DatePicker;
