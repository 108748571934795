import { Layout, PageNotFound } from "@app/components";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useItinerary } from "@app/itineraries/hooks";
import { Box } from "@material-ui/core";
import { ItineraryDetails } from "@app/itineraries/components";
import ItineraryPlaceholder from "@app/itineraries/components/itinerary-placeholder";

interface QueryParams {
  id: string;
}

const Itinerary = () => {
  const { id } = useParams<QueryParams>();
  const { itinerary, loading } = useItinerary(+id);
  const history = useHistory();

  const backToItineraries = () => {
    history.push("/itineraries");
  };

  const renderContent = () => {
    if (loading) {
      return <ItineraryPlaceholder />;
    }
    return (
      <Box paddingTop={4} className={"page-min-height"}>
        <ItineraryDetails itinerary={itinerary} onBack={backToItineraries} />
      </Box>
    );
  };

  const displayNotFound = () => {
    return <PageNotFound />;
  };

  const renderPage = () => {
    if (!loading && !itinerary) {
      return displayNotFound();
    }
    return <Layout>{renderContent()}</Layout>;
  };

  return renderPage();
};

export default Itinerary;
