import { GeneralInfosItinerary } from "@app/types";
import React from "react";
import { HTMLContent } from "@app/components";

interface TermsAndConditionsProps {
  generalInfosItinerary: GeneralInfosItinerary;
}

const TermsAndConditions = ({
  generalInfosItinerary,
}: TermsAndConditionsProps) => {
  return (
    <div className="terms">
      <HTMLContent content={generalInfosItinerary.termsAndConditionsWeb} />
    </div>
  );
};

export default TermsAndConditions;
