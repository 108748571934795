import React from "react";
import styled from "styled-components";
import SocialBar from "./social-bar";
import { Box, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const Background = styled.div<{ backgroundPath: string }>`
  background-image: url(${(props) => props.backgroundPath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 397px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Link = styled(NavLink)`
  color: #ffffff;
  text-decoration: none;
`;

interface Page {
  to: string;
  text: string;
}

const Footer = () => {
  const pages: Page[] = [
    {
      to: "/itineraries",
      text: "Itineraries",
    },
    {
      to: "/pre-post-tours",
      text: "Pre & Post Tours",
    },
    {
      to: "/bookings",
      text: "Bookings",
    },
    {
      to: "/quotes",
      text: "Quotes",
    },
    {
      to: "/profile",
      text: "Settings",
    },
  ];

  const renderPages = () => {
    return pages.map((page, index) => (
      <Box key={index} marginBottom={1}>
        <Link to={page.to} exact rel={"noreferrer"}>
          {page.text}
        </Link>
      </Box>
    ));
  };

  return (
    <Background backgroundPath="/footer.png">
      <Box className={"layout-container"} display={"flex"} color={"white"}>
        <Box width={"100%"}>
          <Box marginBottom={2}>
            <SocialBar />
          </Box>
          <Typography variant={"subtitle2"}>
            © 2021 ROMANIAN ADVENTURES
          </Typography>
        </Box>
        <Box width={"100%"} display={"flex"} flexDirection={"row"}>
          <Box width={"100%"}>{renderPages()}</Box>

          <Box width={"100%"} color={"white"}>
            <Box marginBottom={1}>
              <Link to="/" exact target={"_blank"} rel={"noreferrer"}>
                Privacy Policy
              </Link>
            </Box>
            <Link to={"/"} target={"_blank"} rel={"noreferrer"} color={"white"}>
              Terms & Conditions
            </Link>
          </Box>
        </Box>
      </Box>
    </Background>
  );
};

export default Footer;
