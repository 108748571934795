import React from "react";
import ContentLoader from "react-content-loader";
import { Box } from "@material-ui/core";

interface QuotesPlaceholderProps {
  numberOfRows?: number;
}

const QuotesPlaceholder: React.FC<QuotesPlaceholderProps> = ({
  numberOfRows = 9,
}: QuotesPlaceholderProps) => {
  const rowList = () => {
    const list = [];
    for (let i = 0; i < numberOfRows; i++) {
      list.push(
        <Box width={"100%"}>
          <ContentLoader
            width={"100%"}
            height={100}
            viewBox="0 0 1920 100"
            backgroundColor="#eaeced"
            foregroundColor="#ffffff"
          >
            <rect x="40" y="21" rx="3" ry="3" width="160" height="30" />
            <rect x="330" y="21" rx="3" ry="3" width="141" height="30" />
            <rect x="600" y="20" rx="3" ry="3" width="130" height="30" />
            <circle cx="1570" cy="29" r="11" />
            <rect x="1680" y="20" rx="10" ry="10" width="200" height="30" />
            <rect x="0" y="71" rx="3" ry="3" width="1920" height="2" />
          </ContentLoader>
        </Box>
      );
    }

    return list;
  };

  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      {rowList()}
    </Box>
  );
};

export default QuotesPlaceholder;
