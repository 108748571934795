import { useApi } from "@app/hooks";
import { useDispatch, useSelector } from "react-redux";
import { User } from "@app/types/user";
import { SetUser } from "@app/store/actions/auth.actions";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";

export const useProfile = () => {
  const { request, loading } = useApi();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const updateProfile = async (data: User) => {
    const _user = await request<User>({
      url: `records/users/${user.id}`,
      method: "PATCH",
      body: data,
    });
    dispatch(SetUser(_user));
  };

  return { updateProfile, loading };
};
