import React from "react";

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = ({ children, index, value }: TabPanelProps) => {
  return (
    <div hidden={value !== index}>{value === index && <>{children}</>}</div>
  );
};

export default TabPanel;
