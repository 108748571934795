import React from "react";
import { Box } from "@material-ui/core";
import { get } from "lodash";
import { BookingStatuses } from "@app/bookings/types/enums/";

interface BookingStatusProps {
  status: BookingStatuses;
}

const statusColors = {
  [BookingStatuses.ON_REQUEST]: "241, 190, 124",
  [BookingStatuses.CONFIRMED]: "81, 166, 170",
  [BookingStatuses.TRAVELED]: "198, 183, 162",
  [BookingStatuses.CANCELED]: "203, 20, 23",
  [BookingStatuses.DEPOSITED]: "24, 114, 181",
  [BookingStatuses.PAID_IN_FULL]: "99, 159, 68",
};

const BookingStatus = ({ status }: BookingStatusProps) => {
  const color = get(statusColors, status);
  return (
    <Box
      style={{
        background: `rgba(${color}, 0.2) 0 0 no-repeat padding-box`,
        color: `rgb(${color})`,
        lineHeight: "13px",
        fontSize: "13px",
      }}
      minWidth={75}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={6}
      padding={"8px 0px"}
    >
      {status}
    </Box>
  );
};

export default BookingStatus;
