import { AppState } from "../reducers";
import { ItinerariesState } from "@app/store/reducers/itineraries.reducer";
import { createSelector } from "reselect";
import { ItineraryCluster } from "@app/itineraries/types/interfaces";

const getItinerariesState = (state: AppState): ItinerariesState =>
  state.itineraries;

export const selectItineraries = createSelector(
  getItinerariesState,
  (state) => {
    return Object.values(state.itineraries);
  }
);

export const selectItinerariesLoaded = createSelector(
  getItinerariesState,
  (state) => state.itinerariesLoaded
);

export const selectItinerary = createSelector(getItinerariesState, (state) => {
  return state.itineraries[state.itinerary];
});

export const selectItineraryById = (id: number) =>
  createSelector(getItinerariesState, (state) => state.itineraries[id]);

export const selectItineraryPrice = (id: number) =>
  createSelector(getItinerariesState, (state) =>
    state.itineraries[id]?.clusters.reduce(
      (total, current: ItineraryCluster) =>
        total + (current.cluster?.price ? current.cluster.price : 0),
      0
    )
  );

export const selectItineraryDays = (id: number) =>
  createSelector(getItinerariesState, (state) =>
    state.itineraries[id]?.clusters.reduce(
      (total, current: ItineraryCluster) =>
        total + (current.cluster?.days ? current.cluster.days.length : 0),
      0
    )
  );

export const selectCustomItinerary = createSelector(
  getItinerariesState,
  (state) => state.customItinerary
);

export const selectCustomItineraryDays = createSelector(
  getItinerariesState,
  (state) =>
    state.customItinerary?.clusters?.reduce(
      (total, current: ItineraryCluster) =>
        total + (current.cluster?.days ? current.cluster.days.length : 0),
      0
    )
);

export const selectCustomItineraryPrice = createSelector(
  getItinerariesState,
  (state) =>
    state.customItinerary?.clusters.reduce(
      (total, current: ItineraryCluster) =>
        total + (current.cluster?.price ? current.cluster.price : 0),
      0
    )
);

export const selectItineraryGeneralInfos = createSelector(
  getItinerariesState,
  (state) => {
    return state.generalInfosItinerary;
  }
);

export const selectItineraryGeneralInfosLoaded = createSelector(
  selectItineraryGeneralInfos,
  (generalInfosItinerary) => {
    return !!generalInfosItinerary;
  }
);

export const selectCustomItineraryGeneralInfos = createSelector(
  getItinerariesState,
  (state) => {
    return state.customItineraryGeneralInfos;
  }
);

export const selectCustomItineraryGeneralInfosLoaded = createSelector(
  selectCustomItineraryGeneralInfos,
  (itinerary) => {
    return !!itinerary;
  }
);
