import { useQuery } from "@app/hooks";
import React, { useEffect, useRef } from "react";
import { Layout, Button, PublicPage } from "@app/components";
import Colors from "@app/types/colors.enum";
import ItinerarySection from "@app/components/itinerary-section";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { useHistory } from "react-router-dom";
import { useItineraries } from "@app/itineraries/hooks";
import * as authActions from "@app/store/actions/auth.actions";
import { CreateItineraryDialogRef } from "@app/itineraries/types/refs";
import CreateItineraryDialog from "@app/itineraries/containers/create-itinerary-dialog";
import { Box, Typography } from "@material-ui/core";

const Itineraries = () => {
  const dispatch = useDispatch();
  const createItineraryDialogRef = useRef<CreateItineraryDialogRef>(null);
  const user = useSelector(selectCurrentUser);
  const history = useHistory();
  const { state } = useQuery();
  const { loading: loadingItineraries, itineraries } = useItineraries();

  useEffect(() => {
    if (state?.openCreateItineraryDialog) {
      onOpenCreateItineraryDialog();
    }
  }, []);

  const onOpenLoginModal = () => {
    dispatch(authActions.OpenLoginDialog());
  };

  const onSelectItinerary = (id: number) => {
    if (!user) {
      history.replace({
        state: {
          returnUrl: `/itineraries/${id}`,
        },
      });
      return onOpenLoginModal();
    }
    history.push(`/itineraries/${id}`);
  };

  const onOpenCreateItineraryDialog = () => {
    if (!user) {
      return onOpenLoginModal();
    }
    createItineraryDialogRef.current.onOpen();
  };

  return (
    <Layout parallaxHeader={true}>
      <PublicPage>
        <Box
          className={"parallax-page-min-height"}
          flexDirection={"column"}
          display={"flex"}
          alignItems={"center"}
          paddingTop={4}
        >
          <Box marginBottom={4}>
            {" "}
            <Typography variant={"h5"} align={"center"} className={"bold"}>
              Choose one of our ready-made authentic experiences
            </Typography>
          </Box>
          <ItinerarySection
            itineraries={itineraries}
            loading={loadingItineraries}
            onClick={onSelectItinerary}
          />
          <Box
            marginBottom={4}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gridGap={8}
          >
            <Typography variant={"h5"} align={"center"} className={"bold"}>
              Need more flexibility?
            </Typography>
            <Box marginBottom={2}>
              <Typography variant={"body1"} align={"center"}>
                Create your client&#39;s dream vacation with a simple drag and
                drop system. Choose from many of our pre-made trip clusters to
                design the right trip for your travelers. After you complete the
                reservation, one of our destination experts will review it and
                adjust it where necessary. Please allow up to 48hrs for any
                revisions
              </Typography>
            </Box>
            <Button
              title="Design your own itinerary"
              width={310}
              height={43}
              color={Colors.PRIMARY}
              hoverColor={Colors.HOVER_PRIMARY}
              withBorder={false}
              textColor={Colors.WHITE}
              onClick={onOpenCreateItineraryDialog}
            />
          </Box>
        </Box>
      </PublicPage>
      <CreateItineraryDialog ref={createItineraryDialogRef} />
    </Layout>
  );
};

export default Itineraries;
