import React from "react";
import { Cluster, ItineraryTab } from "@app/itineraries/types/interfaces";
import {
  ItineraryDays,
  ItineraryDescription,
  ItinerarySummary,
  ItineraryTabs,
} from "@app/itineraries/components/index";
import { Box, Typography } from "@material-ui/core";
import { BackButton } from "@app/components";
import IconText from "@app/components/icon-text";
import ScrollToTop from "@app/components/scroll-to-top";

interface Props {
  cluster: Cluster;
  onBack?: () => void;
}

const ClusterDetails = ({ cluster, onBack }: Props) => {
  const days = cluster?.days ? cluster.days.length : 0;

  const getClusters = () => {
    return [cluster];
  };

  const itinerariesTabs = (): ItineraryTab[] => {
    return [
      {
        label: "Description",
        component: <ItineraryDescription cluster={cluster} />,
      },
      {
        label: "Itinerary",
        component: <ItineraryDays clusters={getClusters()} />,
      },
      {
        label: "Summary",
        component: <ItinerarySummary clusters={getClusters()} />,
      },
      // {
      //   label: "Terms and conditions",
      //   component: <></>,
      // },
    ];
  };

  return (
    <ScrollToTop>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"start"}
        marginBottom={4}
      >
        <BackButton title={"Back"} onClick={onBack} />
      </Box>
      <Typography variant={"h1"}>{cluster?.name}</Typography>
      <Box
        textAlign={"left"}
        letterSpacing={"0px"}
        color={"#000"}
        paddingTop={"4px"}
        marginBottom={3}
      >
        <IconText
          variant={"h4"}
          icon={"/icons/clock.svg"}
          text={`${days} ${days === 1 ? "day" : "days"}/${days - 1} ${
            days - 1 === 1 ? "night" : "nights"
          }`}
        />
      </Box>
      <ItineraryTabs tabs={itinerariesTabs()} />
    </ScrollToTop>
  );
};

export default ClusterDetails;
