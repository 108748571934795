import Itineraries from "@app/itineraries/containers/itineraries";
import Profile from "@app/profile/containers/profile";
import Itinerary from "@app/itineraries/containers/itinerary";
import { Quotes } from "@app/quotes/containers";
import CreateItinerary from "@app/itineraries/containers/create-itinerary";
import { Booking } from "@app/bookings/containers";
import PrePostTours from "@app/pre-post-tours/containers/pre-post-tours";
import PrePostTour from "@app/pre-post-tours/containers/pre-post-tour";

export const publicRoutes = [
  {
    key: "itineraries",
    exact: true,
    path: "/itineraries",
    component: Itineraries,
  },
  {
    key: "pre-post-tours",
    exact: true,
    path: "/pre-post-tours",
    component: PrePostTours,
  },
];

export const privateRoutes = [
  {
    key: "profile",
    exact: true,
    path: "/profile",
    component: Profile,
  },
  {
    key: "itinerary",
    exact: true,
    path: "/itineraries/new",
    component: CreateItinerary,
  },
  {
    key: "itinerary",
    exact: true,
    path: "/itineraries/:id",
    component: Itinerary,
  },
  {
    key: "quotes",
    exact: true,
    path: "/quotes",
    component: Quotes,
  },
  {
    key: "bookings",
    exact: true,
    path: "/bookings",
    component: Booking,
  },
  {
    key: "pre-post-tour",
    exact: true,
    path: "/pre-post-tours/:id",
    component: PrePostTour,
  },
];
