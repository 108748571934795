import { AppState } from "../reducers";
import { createSelector } from "reselect";
import { AuthState } from "@app/store/reducers/auth.reducer";

const authState = (state: AppState): AuthState => state.auth;

export const selectCurrentUser = createSelector(
  authState,
  (state) => state.currentUser
);

export const selectIsAuth = createSelector(selectCurrentUser, (user) => !!user);

export const selectOpenLoginDialog = createSelector(
  authState,
  (state) => state.openLogin
);
