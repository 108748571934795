import React from "react";
import { Box, Typography } from "@material-ui/core";

interface PaginatorProps {
  loadMore: () => void;
  loadPrevious: () => void;
  size: number;
  page: number;
  pageSize: number;
  loading: boolean;
  paginatorText: string;
}

const TablePaginator = ({
  loadMore,
  loadPrevious,
  size,
  page,
  pageSize,
  loading,
  paginatorText,
}: PaginatorProps) => {
  const startIndex = (page - 1) * pageSize + 1;
  const maxIndex = page * pageSize;
  const endIndex = maxIndex <= size ? maxIndex : size;
  const previousIconUrl =
    page > 1 && !loading
      ? "/icons/left-available.svg"
      : "/icons/left-unavailable.svg";
  const nextIconUrl =
    page * pageSize < size && !loading
      ? "/icons/right-available.svg"
      : "/icons/right-unavailable.svg";

  return (
    <Box display={"flex"} flexDirection={"row-reverse"} alignItems={"center"}>
      <img
        className={"cursor-pointer"}
        onClick={loadMore}
        alt="next"
        src={nextIconUrl}
        width={9}
        height={15}
      />
      <img
        className={"cursor-pointer"}
        onClick={loadPrevious}
        style={{ marginRight: "32px" }}
        alt="previous"
        src={previousIconUrl}
        width={9}
        height={15}
      />
      <Typography variant={"body2"} style={{ marginRight: "20px" }}>
        {`Showing ${startIndex} - ${endIndex} of ${size} ${paginatorText}`}
      </Typography>
    </Box>
  );
};

export default TablePaginator;
