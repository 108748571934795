import { RawQuote } from "@app/quotes/types/interfaces";

export class Quote {
  id: number;
  name: string;
  date: string;
  agentName: string;
  isDownloading: boolean;

  constructor(data: RawQuote) {
    this.id = data.id;
    this.name = data.name;
    this.isDownloading = false;
    this.date = data.createdAt.split("T")[0];
    if (data?.agent?.firstName) {
      this.agentName = data.agent.firstName + " " + data.agent.lastName;
    }
  }
}
