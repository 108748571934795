import React from "react";
import Colors from "@app/types/colors.enum";
import { Button } from "@app/components/index";

interface PrimaryButtonProps {
  title?: string;
  loading?: boolean;
  type?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const PrimaryButton = ({
  title = "Save",
  loading,
  onClick,
  type,
  disabled,
}: PrimaryButtonProps) => (
  <Button
    type={type ? type : "submit"}
    title={title}
    loading={loading}
    width={182}
    height={43}
    color={Colors.PRIMARY}
    hoverColor={Colors.HOVER_PRIMARY}
    textColor={Colors.WHITE}
    withBorder={false}
    onClick={onClick}
    disabled={disabled}
  />
);

export default PrimaryButton;
