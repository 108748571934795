import React, { ReactNode } from "react";
import UITabs from "@material-ui/core/Tabs";

interface TabsProps {
  value: number;
  onChange: (value: number) => void;
  children: ReactNode;
}

export const Tabs = ({ children, value, onChange }: TabsProps) => {
  return (
    <UITabs
      textColor="primary"
      indicatorColor="primary"
      value={value}
      onChange={(e, value) => onChange(value)}
    >
      {children}
    </UITabs>
  );
};

export default Tabs;
