import React from "react";
import Lottie from "react-lottie";
import { Box } from "@material-ui/core";

const loaderOptions = {
  loop: true,
  autoplay: true,
  path: "/animations/loader.json",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ScreenLoader = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"absolute"}
      height={"100%"}
      width={"100%"}
    >
      <Lottie options={loaderOptions} height={150} width={150} />
    </Box>
  );
};

export default ScreenLoader;
