import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  CancelButton,
  Dialog,
  PrimaryButton,
  TextInput,
} from "@app/components";
import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { QuoteDialogRef } from "@app/itineraries/types/refs";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCustomItinerary,
  selectCustomItineraryPrice,
  selectItineraryById,
  selectItineraryPrice,
} from "@app/store/selectors/itineraries.selectors";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { useQuote } from "@app/quotes/hooks";
import { useSnackbars } from "@app/hooks";
import { SaveQuote } from "@app/quotes/types/interfaces";
import {
  ClusterDay,
  CustomItinerary,
  Itinerary,
  ItineraryCluster,
} from "@app/itineraries/types/interfaces";
import { Image } from "@app/types/image";
import { useQuoteDay, useQuoteDayAction } from "@app/itineraries/hooks";
import {
  selectPrePostTourById,
  selectPrePostTourPrice,
} from "@app/store/selectors/pre-post-tours.selectors";

interface QueryParams {
  id: string;
}

const QuoteDialog = React.forwardRef<QuoteDialogRef>((props, ref) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { control, handleSubmit, setValue } = useForm({
    mode: "all",
  });
  const [open, setOpen] = useState<boolean>(false);
  const [useCustomItinerary, setUseCustomItinerary] = useState<boolean>(false);
  const { id } = useParams<QueryParams>();
  const [usePrePostTour, setUsePrePostTour] = useState<boolean>(false);
  const itinerary = useCustomItinerary
    ? useSelector(selectCustomItinerary)
    : usePrePostTour
    ? useSelector(selectPrePostTourById(+id))
    : useSelector(selectItineraryById(+id));
  const price = useSelector(
    useCustomItinerary
      ? selectCustomItineraryPrice
      : (itinerary as Itinerary)?.type
      ? selectPrePostTourPrice(+id)
      : selectItineraryPrice(+id)
  );
  const user = useSelector(selectCurrentUser);
  const { saveQuote } = useQuote();
  const { openSuccess } = useSnackbars();
  const { bulkCreateQuoteDayActions } = useQuoteDayAction();
  const { addQuoteDay } = useQuoteDay();

  useImperativeHandle(
    ref,
    (): QuoteDialogRef => ({
      onOpen: (useCustomItinerary = false, usePrePostTour = false) =>
        onOpen(useCustomItinerary, usePrePostTour),
    })
  );

  const onOpen = (useCustomItinerary: boolean, usePrePostTour: boolean) => {
    setUseCustomItinerary(useCustomItinerary);
    setUsePrePostTour(usePrePostTour);
    setOpen(true);
  };

  useEffect(() => setValue("name", ""), [open]);

  const onClose = () => setOpen(false);

  const onSubmitForm = async (data) => {
    setLoading(true);
    const quote: SaveQuote = {
      name: data.name,
      agent: user.id,
      agency: user.agency,
      itineraryPrice: price,
    };
    if (useCustomItinerary) {
      quote.lookingFor = (itinerary as CustomItinerary).lookingFor;
      quote.timeOfTravel = (itinerary as CustomItinerary).timeOfTravel;
      quote.lengthOfStay = (itinerary as CustomItinerary).lengthOfStay;
      quote.itineraryName = (itinerary as CustomItinerary).name;
    } else {
      quote.itineraryName = itinerary.name;
      quote.itinerary = (itinerary as Itinerary).id;
      quote.inclusions = (itinerary as Itinerary).inclusions;
    }

    try {
      const _quote = await saveQuote(quote);
      const days = getDays();

      const quoteDayActions = [];
      for (const quoteDay of days) {
        const _quoteDay = await addQuoteDay({
          ...quoteDay,
          quote: _quote.id,
        });
        quoteDayActions.push(
          ...quoteDay.actions.map((action) => {
            return {
              ...action,
              quoteDay: _quoteDay.id,
              name: action.name,
              image: action.image.id,
            };
          })
        );
      }
      await bulkCreateQuoteDayActions(quoteDayActions);
      setLoading(false);
      openSuccess({
        message: "Itinerary successfully saved as quote! ",
        link: "/quotes",
        linkMessage: "Go to quotes",
      });
    } finally {
      onClose();
    }
  };

  const getDays = () => {
    const days: ClusterDay[] = itinerary.clusters
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .reduce((allDays: ClusterDay[], cluster: ItineraryCluster) => {
        if (cluster?.cluster?.days) {
          allDays.push(
            ...cluster.cluster.days.sort((a, b) => (a.order > b.order ? 1 : -1))
          );
        }
        return allDays;
      }, []);
    return days.map((day: ClusterDay, index) => {
      return {
        ...day.day,
        images: day.day.images.map((image: Image) => image.id),
        order: index + 1,
      };
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Save itinerary as quote"
      titleVariant={"h1"}
    >
      <Box width={450} margin={4}>
        <Typography variant={"body1"} align={"center"}>
          Save the quote for later usage.
        </Typography>
        <Box paddingTop={2}>
          <form className="full-width" onSubmit={handleSubmit(onSubmitForm)}>
            <TextInput
              required={true}
              name="name"
              type="text"
              label="Quote name"
              control={control}
            />
            <Box
              paddingTop={2}
              className="gap-l"
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <CancelButton onClose={onClose} />
              <PrimaryButton loading={loading} />
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
});

QuoteDialog.displayName = "QuoteDialog";

export default QuoteDialog;
