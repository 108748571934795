import React from "react";
import ContentLoader from "react-content-loader";
import { Box } from "@material-ui/core";

interface ClusterListPlaceholderProps {
  numberOfCards?: number;
}

const ClusterListPlaceholder = ({
  numberOfCards = 3,
}: ClusterListPlaceholderProps) => {
  const cardList = () => {
    const list = [];
    for (let i = 0; i < numberOfCards; i++) {
      list.push(
        <ContentLoader width={"100%"} height={100} viewBox="0 0 500 100">
          <rect x="4" y="8" rx="16" ry="16" width="7" height="86" />
          <rect x="6" y="8" rx="16" ry="16" width="675" height="8" />
          <rect x="6" y="86" rx="16" ry="16" width="669" height="8" />
          <rect x="495" y="8" rx="16" ry="16" width="6" height="86" />
          <rect x="20" y="25" rx="3" ry="3" width="100" height="10" />
          <rect x="20" y="45" rx="3" ry="3" width="200" height="10" />
          <rect x="20" y="65" rx="3" ry="3" width="300" height="10" />
          <rect x="330" y="25" rx="16" ry="16" width="160" height="50" />
        </ContentLoader>
      );
    }

    return list;
  };

  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyItems={"center"}
      display={"flex"}
      flexDirection={"column"}
      gridTemplateColumns={"repeat(auto-fill, minmax(308px, 1fr))"}
      className={"gap-l"}
    >
      {cardList()}
    </Box>
  );
};

export default ClusterListPlaceholder;
