import { getType } from "typesafe-actions";
import * as actions from "@app/store/actions/itineraries.actions";
import { CustomItinerary, Itinerary } from "@app/itineraries/types/interfaces";
import { GeneralInfosItinerary } from "@app/types";

export interface ItinerariesState {
  itineraries: { [id: number]: Itinerary };
  itinerary: number;
  customItinerary: CustomItinerary;
  itinerariesLoaded: boolean;
  generalInfosItinerary: GeneralInfosItinerary;
  customItineraryGeneralInfos: Itinerary;
}

const initialState: ItinerariesState = {
  itineraries: {},
  itinerary: null,
  customItinerary: null,
  itinerariesLoaded: false,
  generalInfosItinerary: null,
  customItineraryGeneralInfos: null,
};

export default (state = initialState, action): ItinerariesState => {
  switch (action.type) {
    case getType(actions.LoadItineraries): {
      const itineraries = action.payload.reduce(
        (result, itinerary: Itinerary) => {
          result[itinerary.id] = itinerary;
          return result;
        },
        {}
      );
      return {
        ...state,
        itineraries: { ...itineraries },
        itinerariesLoaded: true,
      };
    }
    case getType(actions.LoadItinerary): {
      const itinerary: Itinerary = action.payload;
      return {
        ...state,
        itinerary: itinerary.id,
        itineraries: {
          ...state.itineraries,
          [itinerary.id]: {
            ...state.itineraries[itinerary.id],
            ...itinerary,
            loaded: true,
          },
        },
      };
    }
    case getType(actions.SetCustomItineraryFilters): {
      const customItinerary: CustomItinerary = action.payload;
      return {
        ...state,
        customItinerary: { ...state.customItinerary, ...customItinerary },
      };
    }
    case getType(actions.LoadItineraryGeneralInfos): {
      const generalInfosItinerary: GeneralInfosItinerary = action.payload;
      return {
        ...state,
        generalInfosItinerary,
      };
    }
    case getType(actions.LoadCustomItineraryGeneralInfos): {
      const customItineraryGeneralInfos: Itinerary = action.payload;
      return {
        ...state,
        customItineraryGeneralInfos,
      };
    }

    default:
      return state;
  }
};
