import React, { useEffect, useState } from "react";
import Tab from "@material-ui/core/Tab";
import Colors from "@app/types/colors.enum";
import { Layout, Button, TabPanel, Tabs } from "@app/components";
import { ProfileForm, TravelAgencyInformation } from "@app/profile/components";
import { User } from "@app/types/user";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { useProfile } from "@app/profile/hooks";
import { useQuery, useSnackbars } from "@app/hooks";
import { useAgency } from "@app/profile/hooks/agency.hook";
import { useUpdateAgency } from "@app/profile/hooks/update-agency.hook";
import { AgencyDetails } from "@app/profile/types/interfaces";
import { Box, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import PageContainer from "@app/components/page-container";

const Profile = () => {
  const [value, setValue] = useState(0);
  const user = useSelector(selectCurrentUser);
  const { openSuccess, openError } = useSnackbars();
  const { updateProfile, loading: loadingUpdateProfile } = useProfile();
  const { agency, loading: loadingAgency } = useAgency();
  const { updateAgency, loading: loadingUpdateAgency } = useUpdateAgency();
  const { state } = useQuery();

  useEffect(() => {
    if (state?.agencyTab && user?.masterAccount) {
      setValue(1);
    }
  }, [user]);

  const onEditProfile = async (data: User) => {
    await updateProfile(data);
    openSuccess({
      message: "Field successfully updated!",
    });
  };

  const onEditAgency = async (data: AgencyDetails) => {
    await updateAgency(data);
    openSuccess({
      message: "Field successfully updated!",
    });
  };

  const renderHeader = () => {
    return (
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography component="h1">My Account</Typography>
        <NavLink to="/bookings" exact className={"remove-decoration"}>
          <Button
            type="button"
            title="My Bookings"
            width={180}
            height={43}
            color={Colors.PRIMARY}
            hoverColor={Colors.HOVER_PRIMARY}
            textColor={Colors.WHITE}
            withBorder={false}
          />
        </NavLink>
      </Box>
    );
  };

  return (
    <Layout>
      <PageContainer renderHeader={renderHeader}>
        <Tabs value={value} onChange={(value) => setValue(value)}>
          <Tab label="Profile" />
          {user?.masterAccount && <Tab label="Travel Agency Information" />}
        </Tabs>
        <TabPanel value={value} index={0}>
          <Box minHeight={400}>
            <ProfileForm
              loading={loadingUpdateProfile}
              onSubmit={onEditProfile}
              user={user}
            />
          </Box>
        </TabPanel>
        {user?.masterAccount && (
          <TabPanel value={value} index={1}>
            <Box minHeight={400}>
              <TravelAgencyInformation
                loadingUpdateAgency={loadingUpdateAgency}
                agency={agency}
                loadingAgency={loadingAgency}
                onSubmit={onEditAgency}
              />
            </Box>
          </TabPanel>
        )}
      </PageContainer>
    </Layout>
  );
};

export default Profile;
