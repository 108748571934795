import React from "react";
import { useForm, useFormState } from "react-hook-form";
import {
  UploadButton,
  TextInput,
  CancelButton,
  PrimaryButton,
} from "@app/components";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@material-ui/core";

interface FormProps {
  onClose: () => void;
  onSubmit: (data: FormData) => void;
  loading: boolean;
}

const RegisterAgencyForm = ({ onClose, onSubmit, loading }: FormProps) => {
  const { control, handleSubmit, register, watch, setValue } = useForm({
    mode: "onTouched",
  });
  const { errors } = useFormState({ control });

  const onSubmitForm = (data) => {
    const formData = getFormData(data);
    formData.append("w9", data.w9[0], "W9 " + uuidv4() + ".pdf");
    formData.append(
      "userAgreement",
      data.userAgreement[0],
      "User Agreement " + uuidv4() + ".pdf"
    );
    onSubmit(formData);
  };

  const getFormData = (data) =>
    Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box display={"flex"} flexDirection={"column"} className={"gap-l"}>
        <TextInput
          required={true}
          name="customerId"
          type="text"
          label="Customer ID"
          control={control}
        />
        <TextInput
          required={true}
          name="agencyName"
          type="text"
          label="Agency name"
          control={control}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          className={"gap-sm"}
        >
          <TextInput
            required={true}
            name="streetAddress"
            type="text"
            label="Street address"
            control={control}
          />
          <TextInput
            required={false}
            name="addressDetails"
            type="text"
            label="Suite/Floor"
            control={control}
          />
        </Box>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          className={"gap-sm"}
        >
          <TextInput
            required={true}
            name="city"
            type="text"
            label="City"
            control={control}
          />
          <TextInput
            required={true}
            name="state"
            type="text"
            label="State"
            control={control}
          />
        </Box>
        <TextInput
          required={true}
          name="zipCode"
          type="text"
          label="Zip Code"
          control={control}
          rules={{
            pattern: {
              value: /^[0-9]*$/i,
              message: "Invalid zip code",
            },
          }}
        />
        <TextInput
          required={true}
          name="country"
          type="text"
          label="Country"
          control={control}
        />
        <TextInput
          required={true}
          name="emailAddress"
          type="email"
          label="Email address"
          control={control}
          rules={{
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: "Invalid email address",
            },
          }}
        />
        <TextInput
          required={true}
          name="phoneNumber"
          type="tel"
          label="Phone number"
          control={control}
          rules={{
            pattern: {
              value: /^[0-9]*$/i,
              message: "Invalid phone number",
            },
            maxLength: {
              value: 15,
              message: "The maximum length is 15",
            },
          }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          className={"gap-sm"}
        >
          <TextInput
            required={true}
            name="managerFirstName"
            type="text"
            label="Manager first name"
            control={control}
          />

          <TextInput
            required={true}
            name="managerLastName"
            type="text"
            label="Manager last name"
            control={control}
          />
        </Box>
        <TextInput
          required={true}
          name="managerEmailAddress"
          type="email"
          label="Manager email address"
          control={control}
          rules={{
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: "Invalid email address",
            },
          }}
        />
        <TextInput
          required={true}
          name="managerPhoneNumber"
          type="tel"
          label="Manager phone number"
          control={control}
          rules={{
            pattern: {
              value: /^[0-9]*$/i,
              message: "Invalid phone number",
            },
            maxLength: {
              value: 15,
              message: "The maximum length is 15",
            },
          }}
        />
        <TextInput
          required={false}
          name="hostAgency"
          type="text"
          label="Consortia/Host agency"
          control={control}
        />
        <UploadButton
          title="Attach W-9 information"
          register={register}
          name="w9"
          watch={watch}
          setValue={setValue}
          required={true}
        />
        <UploadButton
          title="Attach User agreement"
          register={register}
          name="userAgreement"
          watch={watch}
          setValue={setValue}
          required={true}
        />
        {(errors.userAgreement || errors.w9) && (
          <Box textAlign={"center"} className={"text-color-red"}>
            Please attach the User agreement and W-9 documents
          </Box>
        )}
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={3}
          className={"gap-l"}
        >
          <CancelButton onClose={onClose} />
          <PrimaryButton loading={loading} title="Submit" />
        </Box>
      </Box>
    </form>
  );
};

export default RegisterAgencyForm;
