import React, { useEffect, useRef, useState } from "react";
import {
  Cluster,
  Itinerary,
  ItineraryCluster,
} from "@app/itineraries/types/interfaces";
import IconText from "@app/components/icon-text";
import { Box, ImageList, ImageListItemBar } from "@material-ui/core";
import ImageListItem from "@material-ui/core/ImageListItem";
import { Image } from "@app/types/image";
import { ImageSliderDialog } from "@app/components";
import { ImageSliderDialogRef } from "@app/types/image-slider-dialog-ref.interface";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface ItineraryDescriptionProps {
  itinerary?: Itinerary;
  cluster?: Cluster;
}

const useStyles = makeStyles({
  root: {
    padding: "0 16px 0 0 !important",
  },
  firstImages: {
    margin: "0 !important",
    width: "75% !important",
  },
  nextImages: {
    margin: "0 !important",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "25% !important",
  },
  itemBar: {
    borderRadius: "16px",
    textAlign: "center",
    background: "#00000080 0% 0% no-repeat padding-box",
  },
});

const ItineraryDescription = ({
  itinerary,
  cluster,
}: ItineraryDescriptionProps) => {
  const [firstImages, setFirstImages] = useState([]);
  const [nextImages, setNextImages] = useState([]);
  const [morePhotos, setMorePhotos] = useState(0);
  const imageSliderDialogRef = useRef<ImageSliderDialogRef>(null);
  const classes = useStyles();

  useEffect(() => {
    if (itinerary) {
      setFirstImages(
        itinerary.images.slice(0, 2).map((image: Image) => image?.file)
      );
      setNextImages(
        itinerary.images.slice(2, 5).map((image: Image) => image?.file)
      );
      setMorePhotos(itinerary.images.length - 5);
    }
  }, [itinerary]);

  const getDescriptions = () => {
    return cluster
      ? cluster.description.map((description: string, index: number) => {
          return (
            <Typography variant={"body1"} key={index}>
              {description}
            </Typography>
          );
        })
      : itinerary?.description.map((description: string, index: number) => {
          return (
            <Typography variant={"body1"} key={index}>
              {description}
            </Typography>
          );
        });
  };

  const onShowSwiper = () => {
    imageSliderDialogRef.current.onOpen(itinerary.images);
  };

  const displayItemBar = (index: number) => {
    if (index === 2 && morePhotos) {
      return (
        <div onClick={onShowSwiper}>
          <ImageListItemBar
            className={`full-width full-height cursor-pointer ${classes.itemBar}`}
            title={`+${morePhotos} photos`}
          />
        </div>
      );
    }
    return <></>;
  };

  const getPrice = () => {
    return cluster
      ? cluster?.price
      : itinerary?.clusters.reduce(
          (total, current: ItineraryCluster) =>
            total + (current.cluster?.price ? current.cluster.price : 0),
          0
        );
  };

  const rowHeight = 450;
  const smallImage = 141;

  const getImages = () => {
    return cluster ? (
      <img
        src={cluster?.image?.file}
        width={"100%"}
        alt={cluster?.image?.file}
        height={509}
        style={{
          borderRadius: "16px",
          objectFit: "cover",
        }}
      />
    ) : (
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        marginBottom={2}
      >
        <ImageList className={classes.firstImages} rowHeight={rowHeight}>
          {firstImages.map((item, index) => (
            <ImageListItem
              className={classes.root}
              key={index}
              cols={1}
              rows={1}
            >
              <img
                className={"image-radius"}
                src={item}
                alt={item}
                width={"100%"}
                height={rowHeight}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <ImageList className={classes.nextImages} rowHeight={smallImage}>
          {nextImages.map((item, index) => (
            <ImageListItem
              className="no-padding full-width"
              key={index}
              cols={1}
              rows={1}
            >
              <img
                className={"image-radius"}
                src={item}
                alt={item}
                width={"100%"}
                height={smallImage}
              />
              {displayItemBar(index)}
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    );
  };

  const getVideo = () => {
    if (!itinerary?.video) {
      return <></>;
    }
    return (
      <Box width={"100%"}>
        <iframe
          className={"border-radius-16"}
          width="100%"
          height="622"
          src={itinerary.video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    );
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} paddingBottom={8}>
        <Box
          paddingBottom={2}
          display={"flex"}
          flexDirection={"column"}
          className={"gap-xs"}
        >
          {getDescriptions()}
        </Box>
        <Box display={"flex"} justifyContent={"start"} marginBottom={4}>
          <Box
            bgcolor={"#f8f9fc"}
            borderRadius={"8px"}
            color={"#274d4f"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            padding={"8px 32px"}
          >
            <IconText
              variant={"h4"}
              icon={"/icons/price-tag.svg"}
              text={getPrice() + "$"}
            />
          </Box>
        </Box>
        {getImages()}
        {getVideo()}
      </Box>
      <ImageSliderDialog ref={imageSliderDialogRef} />
    </>
  );
};

export default ItineraryDescription;
