import React, { useState } from "react";
import { Image } from "@app/types/image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/thumbs/thumbs.scss";
import "swiper/components/navigation/navigation.scss";
import SwiperClass from "swiper/types/swiper-class";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "@app/theme";
SwiperCore.use([Thumbs, Navigation]);

const useStyles = makeStyles({
  pagination: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
  },
});

interface Props {
  images: Image[];
}

const ImageSwiper = ({ images }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const classes = useStyles();

  const onSlideChange = (swiperClass: SwiperClass) => {
    setActiveIndex(swiperClass.realIndex + 1);
  };

  const getImages = () => {
    return images.map((image: Image, index: number) => {
      return (
        <SwiperSlide key={index}>
          <img src={image?.file} alt={"image"} />
        </SwiperSlide>
      );
    });
  };

  return (
    <Box width={"100%"} height={"100%"} padding={12} bgcolor={"#000000E6"}>
      <Box
        paddingTop={1.5}
        paddingBottom={1.5}
        className={classes.pagination}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Typography variant={"body1"} className={"text-color-white"}>
          {`${activeIndex}/${images.length}`}
        </Typography>
      </Box>
      <Swiper
        onSlideChange={onSlideChange}
        className={"mainSwiper"}
        navigation={true}
        loop={true}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {getImages()}
      </Swiper>
      <Swiper
        className={"thumbsSwiper"}
        onSwiper={setThumbsSwiper}
        spaceBetween={24}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
      >
        {getImages()}
      </Swiper>
    </Box>
  );
};

export default ImageSwiper;
