import { useState } from "react";
import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from "axios";
import { Token } from "@app/types/token";
import { ApiError } from "@app/types/api-error";
import { LogOut } from "@app/store/actions/auth.actions";
import { DATAFY_API_URL } from "@app/constants";
import camelcaseKeys from "camelcase-keys";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { LoadAgency } from "@app/store/actions/agency.actions";

interface ApiErrorMessage {
  detail: string;
  code: string;
}

interface RequestParams {
  url: string;
  method: Method;
  body?: { [id: string]: any };
  headers?: { [id: string]: string };
  queryParams?: { [id: string]: any };
}

export const TOKEN_LOCAL_STORAGE = "token";
const axiosInstance: AxiosInstance = axios.create({
  baseURL: DATAFY_API_URL,
});

const refreshPath = "/auth/token";
const logInPath = "/auth/signIn";

const unauthenticatedRoutes = [logInPath, refreshPath];

export const useApi = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const axiosRequest = (
    url: string,
    method: Method,
    body?: { [id: string]: any },
    headers?: { [id: string]: string },
    queryParams?: { [id: string]: string }
  ): Promise<any> => {
    setLoading(true);
    return axiosInstance
      .request({
        url,
        method,
        data: body,
        headers,
        params: queryParams,
      })
      .then((response: any) => camelcaseKeys(response.data, { deep: true }))
      .catch((response: AxiosError<ApiErrorMessage>) => {
        const message = handleResponseError(response.response);
        return Promise.reject(message);
      })
      .finally(() => setLoading(false));
  };

  const request = async <T extends any>({
    url,
    method,
    body,
    headers = {},
    queryParams = {},
  }: RequestParams): Promise<T> => {
    let token = getToken();

    if (token?.accessToken && isAccessTokenExpired(token.accessToken)) {
      try {
        token = await refreshAccessToken(token.refreshToken);
      } catch (err) {
        logOut(true);
        return Promise.reject("Refresh token failed");
      }
    }

    if (token?.accessToken && !unauthenticatedRoutes.includes(url)) {
      headers["x-authentication"] = token.accessToken;
    }

    return axiosRequest(url, method, body, headers, queryParams);
  };

  const logOut = (openLoginModal: boolean = false) => {
    if (history) {
      history.push("/itineraries");
    }
    dispatch(LogOut(openLoginModal));
    window.localStorage.removeItem(TOKEN_LOCAL_STORAGE);
  };

  const logIn = async (email: string, password: string, returnUrl?: string) => {
    const token: Token = await axiosRequest(logInPath, "POST", {
      email,
      password,
    });

    setToken(token);
    if (returnUrl) {
      history.push(returnUrl);
    }
  };

  const handleResponseError = (
    response: AxiosResponse<ApiErrorMessage>
  ): ApiError => {
    const message = new ApiError();
    message.status = response?.status || 500;
    message.message = response?.data?.detail || "Something went wrong";
    message.code = response?.data?.code || "error";
    message.data = response?.data || {};

    // if (response?.status === 401) {
    //   logOut();
    // }

    return message;
  };

  const refreshAccessToken = async (refreshToken: string): Promise<Token> => {
    const token: Token = await axiosRequest(refreshPath, "POST", {
      refreshToken,
    });

    setToken(token);
    return token;
  };

  const getToken = (): Token => {
    return JSON.parse(window.localStorage.getItem(TOKEN_LOCAL_STORAGE));
  };

  const setToken = (token: Token) => {
    window.localStorage.setItem(TOKEN_LOCAL_STORAGE, JSON.stringify(token));
  };

  const isAccessTokenExpired = (accessToken: string): boolean => {
    const decoded = jwtDecode<{ exp: number }>(accessToken);
    return Date.now() / 1000 - decoded.exp > 0;
  };

  const getAccessToken = (): string => {
    const token = getToken();
    return token?.accessToken;
  };

  const refreshIfExpired = async (): Promise<void> => {
    const token = getToken();
    if (token?.accessToken && isAccessTokenExpired(token.accessToken)) {
      await refreshAccessToken(token.refreshToken);
    }
  };

  return {
    request,
    logIn,
    logOut,
    loading,
    getAccessToken,
    refreshIfExpired,
  };
};
