import { useLocation } from "react-router-dom";

export const useQuery = () => {
  const search = useLocation().search;
  const state: any = useLocation().state;
  const pathname: string = useLocation().pathname;
  const query = new URLSearchParams(search);

  return { query, state, pathname };
};
