import React, { ReactNode, useEffect, useRef } from "react";
import LoginDialog from "@app/modules/auth/containers/login-dialog";
import ChangePasswordDialog from "@app/modules/auth/containers/change-password-dialog";
import {
  ChangePasswordDialogRef,
  LoginDialogRef,
  RegisterAgencyRef,
} from "@app/itineraries/types/refs";
import * as authActions from "@app/store/actions/auth.actions";
import { useQuery } from "@app/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RegisterAgencyDialog from "@app/modules/auth/containers/register-agency-dialog";
import { selectIsAuth } from "@app/store/selectors/auth.selectors";

interface Props {
  children: ReactNode;
}

const PublicPage = ({ children }: Props) => {
  const changePasswordDialogRef = useRef<ChangePasswordDialogRef>(null);
  const loginDialogRef = useRef<LoginDialogRef>(null);
  const { query, state } = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const registerAgencyDialogRef = useRef<RegisterAgencyRef>(null);
  const isAuth = useSelector(selectIsAuth);

  useEffect(() => {
    if (query.get("login")) {
      if (!isAuth) {
        dispatch(authActions.OpenLoginDialog());
      }
      query.delete("login");
      history.replace({
        search: query.toString(),
        state: state,
      });
    }
  }, []);

  useEffect(() => {
    if (query.get("agency")) {
      query.delete("agency");
      if (!isAuth) {
        registerAgencyDialogRef.current.onOpen();
      }
      history.replace({
        search: query.toString(),
        state: state,
      });
    }
  }, []);

  useEffect(() => {
    // Reset password flow
    const resetPasswordToken = query.get("resetPasswordCode");
    if (resetPasswordToken) {
      changePasswordDialogRef.current.open(resetPasswordToken);
    }
  }, []);

  return (
    <>
      {children}
      <LoginDialog ref={loginDialogRef} />
      <ChangePasswordDialog ref={changePasswordDialogRef} />
      <RegisterAgencyDialog ref={registerAgencyDialogRef} />
    </>
  );
};

export default PublicPage;
