import React from "react";
import ContentLoader from "react-content-loader";
import { Box } from "@material-ui/core";

interface BookingsPlaceholderProps {
  numberOfRows?: number;
}

const BookingsPlaceholder: React.FC<BookingsPlaceholderProps> = ({
  numberOfRows = 9,
}: BookingsPlaceholderProps) => {
  const rowList = () => {
    const list = [];
    for (let i = 0; i < numberOfRows; i++) {
      list.push(
        <Box width={"100%"}>
          <ContentLoader
            width={"100%"}
            height={100}
            viewBox="0 0 1920 100"
            backgroundColor="#eaeced"
            foregroundColor="#ffffff"
          >
            <rect x="50" y="21" rx="3" ry="3" width="40" height="30" />
            <rect x="200" y="21" rx="3" ry="3" width="170" height="30" />
            <rect x="450" y="20" rx="3" ry="3" width="180" height="30" />
            <rect x="670" y="20" rx="3" ry="3" width="80" height="30" />
            <rect x="820" y="20" rx="3" ry="3" width="80" height="30" />
            <rect x="970" y="20" rx="3" ry="3" width="120" height="30" />
            <rect x="1110" y="20" rx="3" ry="3" width="120" height="30" />
            <rect x="1280" y="20" rx="3" ry="3" width="120" height="30" />
            <rect x="1460" y="20" rx="3" ry="3" width="120" height="30" />
            <rect x="1605" y="20" rx="3" ry="3" width="120" height="30" />
            <circle cx="1785" cy="29" r="11" />
            <circle cx="1890" cy="29" r="11" />
            <rect x="0" y="71" rx="3" ry="3" width="1920" height="2" />
          </ContentLoader>
        </Box>
      );
    }

    return list;
  };

  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyItems={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      {rowList()}
    </Box>
  );
};

export default BookingsPlaceholder;
