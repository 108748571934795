import { useRaApi } from "@app/hooks";
import { CreateBooking } from "@app/itineraries/types/interfaces";
import { useState } from "react";
import { Booking } from "@app/itineraries/types/classes";
import { useDownloadBookingHook } from "@app/bookings/hooks";

export const useBookings = () => {
  const { request } = useRaApi();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const { getBookingPdf } = useDownloadBookingHook(bookings, setBookings);

  const addBooking = async (data: CreateBooking) => {
    return await request({
      url: `booking/book-itinerary`,
      method: "POST",
      data: data,
    });
  };

  return { addBooking, getBookingPdf };
};
