import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { PasswordInput, PrimaryButton } from "@app/components";
import { Box, Typography } from "@material-ui/core";

interface Props {
  onSubmit: (password: string) => void;
  onLogout?: () => void;
  loading: boolean;
}

const ChangePasswordForm = (props: Props) => {
  const { control, handleSubmit, watch } = useForm({
    mode: "onSubmit",
  });
  const password = useRef<string>(null);
  password.current = watch("password", "");

  const onSubmit = (data: { [key: string]: string }) =>
    props.onSubmit(data["password"]);

  const onLogout = () => props.onLogout();
  const displayLogout = !!props.onLogout;

  return (
    <>
      <Box marginBottom={4} marginX={4}>
        <Typography variant={"body2"} align={"center"}>
          Your new password must be different from your previous used passwords.{" "}
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={"password"}
          control={control}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <PasswordInput
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              label={"Password"}
              error={!!error}
              helperText={error?.message}
            />
          )}
          rules={{
            required: "Password is required",
          }}
        />

        <Box marginTop={3} marginBottom={2}>
          <Controller
            name={"confirmationPassword"}
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <PasswordInput
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                label={"Confirm Password"}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              validate: (value: string) =>
                value !== password.current ? "Passwords do not match" : null,
              required: "Confirmation Password is required",
            }}
          />
        </Box>

        {displayLogout && (
          <Box display={"flex"} justifyContent={"end"} marginRight={1}>
            <Typography
              align={"right"}
              className={"cursor-pointer"}
              onClick={onLogout}
              variant={"body2"}
            >
              Log out
            </Typography>
          </Box>
        )}

        <Box
          display={"flex"}
          justifyContent={"center"}
          marginTop={displayLogout ? 2 : 4}
        >
          <PrimaryButton loading={props.loading} title="Confirm" />
        </Box>
      </form>
    </>
  );
};

export default ChangePasswordForm;
