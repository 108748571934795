import React, { useImperativeHandle, useRef, useState } from "react";
import { Dialog } from "@app/components";
import { ChangePasswordForm } from "@app/modules/auth/components";
import { useChangePassword } from "@app/modules/auth/hooks";
import { Box } from "@material-ui/core";
import { useConfirmationDialog } from "@app/hooks/confirmation-dialog.hook";
import { useApi, useSnackbars } from "@app/hooks";
import { SecurePasswordDialogRef } from "@app/itineraries/types/refs";

const SecurePasswordDialog = React.forwardRef<SecurePasswordDialogRef>(
  (props, ref) => {
    const { logOut } = useApi();
    const [open, setOpen] = useState<boolean>(false);
    const { loading, changePassword } = useChangePassword();
    const { openConfirmationDialog } = useConfirmationDialog();
    const { openError } = useSnackbars();
    const onCloseRef = useRef<() => void>(null);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    useImperativeHandle(
      ref,
      (): SecurePasswordDialogRef => ({
        open: (onClose: () => void) => {
          onCloseRef.current = onClose;
          onOpen();
        },
      })
    );

    const onChangePassword = async (password: string) => {
      try {
        await changePassword(password);
        onClose();
        openConfirmationDialog({
          title: "Password updated!",
          onClose: () => onCloseRef.current(), // emit the Secure Flow was closed
        });
      } catch (e) {
        openError(e.message || "Something went wrong, please try again later");
      }
    };

    if (!open) {
      return null;
    }

    const onLogout = () => {
      logOut();
      onClose();
    };

    return (
      <Dialog
        open={true}
        hideClose={true}
        disableClosing={true}
        title={"Set up your password"}
      >
        <Box width={500}>
          <ChangePasswordForm
            onLogout={onLogout}
            loading={loading}
            onSubmit={onChangePassword}
          />
        </Box>
      </Dialog>
    );
  }
);

SecurePasswordDialog.displayName = "SetupLogoDialog";

export default SecurePasswordDialog;
