import { createAction } from "typesafe-actions";
import { CustomItinerary, Itinerary } from "@app/itineraries/types/interfaces";
import { GeneralInfosItinerary } from "@app/types";

const LOAD_ITINERARIES = "[Itineraries] Set Itineraries";
const LOAD_ITINERARY = "[Itineraries] Set Itinerary";
const SET_CUSTOM_ITINERARY_FILTERS =
  "[Itineraries] Set Custom Itinerary Filters";
const LOAD_ITINERARY_GENERAL_INFOS =
  "[Itineraries] Set Itinerary General Information";
const LOAD_CUSTOM_ITINERARY_GENERAL_INFOS =
  "[Itineraries] Set Custom Itinerary General Information";

export const LoadItineraries = createAction(
  LOAD_ITINERARIES,
  (itineraries: Itinerary[]) => itineraries
)<Itinerary[]>();

export const LoadItinerary = createAction(
  LOAD_ITINERARY,
  (itinerary: Itinerary) => itinerary
)<Itinerary>();

export const SetCustomItineraryFilters = createAction(
  SET_CUSTOM_ITINERARY_FILTERS,
  (customItinerary: CustomItinerary) => customItinerary
)<CustomItinerary>();

export const LoadItineraryGeneralInfos = createAction(
  LOAD_ITINERARY_GENERAL_INFOS,
  (generalInfosItinerary: GeneralInfosItinerary) => {
    return generalInfosItinerary;
  }
)<GeneralInfosItinerary>();

export const LoadCustomItineraryGeneralInfos = createAction(
  LOAD_CUSTOM_ITINERARY_GENERAL_INFOS,
  (itinerary: Itinerary) => {
    return itinerary;
  }
)<Itinerary>();
