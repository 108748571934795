import { createAction } from "typesafe-actions";
import { Snackbar } from "@app/types/snackbar";
import { ConfirmationDialog } from "@app/types/confirmation.dialog.interface";
import { LoadingDialog } from "@app/types/loading-dialog.interface";

const OPEN_SNACKBAR = "[Snackbar] Open";
const CLOSE_SNACKBAR = "[Snackbar] Close";
const OPEN_CONFIRMATION_DIALOG = "[Confirmation Dialog] Open";
const CLOSE_CONFIRMATION_DIALOG = "[Confirmation Dialog] Close";
const OPEN_LOADING_DIALOG = "[Loading Dialog] Open";
const CLOSE_LOADING_DIALOG = "[Loading Dialog] Close";

export const OpenSnackbar = createAction(
  OPEN_SNACKBAR,
  (snackbar: Snackbar) => snackbar
)<Snackbar>();
export const CloseSnackbar = createAction(CLOSE_SNACKBAR)<void>();
export const OpenConfirmationDialog = createAction(
  OPEN_CONFIRMATION_DIALOG,
  (confirmationDialog: ConfirmationDialog) => confirmationDialog
)<ConfirmationDialog>();
export const CloseConfirmationDialog = createAction(
  CLOSE_CONFIRMATION_DIALOG
)<void>();
export const OpenLoadingDialog = createAction(
  OPEN_LOADING_DIALOG,
  (loadingDialog: LoadingDialog) => loadingDialog
)<LoadingDialog>();
export const CloseLoadingDialog = createAction(CLOSE_LOADING_DIALOG)<void>();
