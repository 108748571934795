import React from "react";
import ContentLoader from "react-content-loader";
import { Box } from "@material-ui/core";

interface ItineraryCardListPlaceholderProps {
  numberOfCards?: number;
}

const ItineraryCardListPlaceholder = ({
  numberOfCards = 4,
}: ItineraryCardListPlaceholderProps) => {
  const cardList = () => {
    const list = [];
    for (let i = 0; i < numberOfCards; i++) {
      list.push(
        <ContentLoader viewBox="0 0 400 432" height={432} width={"100%"}>
          <rect x="0" y="20" rx="8" ry="8" width="400" height="200" />
          <rect x="0" y="250" rx="0" ry="0" width="400" height="18" />
          <rect x="0" y="275" rx="0" ry="0" width="300" height="20" />
          <rect x="0" y="300" rx="0" ry="0" width="400" height="80" />
        </ContentLoader>
      );
    }

    return list;
  };

  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyItems={"center"}
      display={"grid"}
      gridTemplateColumns={"repeat(auto-fill, minmax(308px, 1fr))"}
      className={"gap-l"}
    >
      {cardList()}
    </Box>
  );
};

export default ItineraryCardListPlaceholder;
