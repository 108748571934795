import React, { ReactNode } from "react";
import styled from "styled-components";

interface TableEmptyResponse {
  children: ReactNode;
}

const StyledResponse = styled.div`
  font-size: 16px;
  color: #969798;
  width: 100%;
  justify-content: center;
  padding-top: 40px;
  display: flex;
`;

const TableEmpty = ({ children }: TableEmptyResponse) => {
  return <StyledResponse>{children}</StyledResponse>;
};

export default TableEmpty;
