import React from "react";
import { Cluster, ClusterDay } from "@app/itineraries/types/interfaces";
import { Box, Typography } from "@material-ui/core";
import { DayTitle } from "@app/itineraries/components/index";

interface ItinerarySummaryProps {
  clusters: Cluster[];
}

const ItinerarySummary = ({ clusters }: ItinerarySummaryProps) => {
  const getItineraryDays = () => {
    const days = clusters.reduce((allDays: ClusterDay[], cluster: Cluster) => {
      if (cluster?.days) {
        allDays.push(
          ...cluster.days.sort((a, b) => (a.order > b.order ? 1 : -1))
        );
      }
      return allDays;
    }, []);
    return days.map((clusterDay: ClusterDay, index: number) => {
      return (
        <div key={index}>
          <DayTitle title={`Day ${index + 1} - ${clusterDay.day.name}`} />
          <Typography variant={"body1"}>{clusterDay.day.summary}</Typography>
        </div>
      );
    });
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      className={"gap-xl"}
      marginBottom={8}
    >
      {getItineraryDays()}
    </Box>
  );
};
export default ItinerarySummary;
