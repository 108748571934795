import { useDispatch } from "react-redux";
import { Snackbar } from "@app/types/snackbar";
import { CloseSnackbar, OpenSnackbar } from "@app/store/actions/core.actions";
import { SnackbarIcons } from "@app/itineraries/types/enums/snackbar-icons.enum";
import { OpenSnackbar as OpenSnackbarProps } from "@app/types/open-snackbar";

export const useSnackbars = () => {
  const dispatch = useDispatch();

  const openSnackbar = (snackbar: Snackbar) => {
    dispatch(OpenSnackbar(snackbar));
  };

  const openError = (snackbar: OpenSnackbarProps) => {
    openSnackbar({
      ...snackbar,
      icon: SnackbarIcons.FAILED,
    });
  };

  const openSuccess = (snackbar: OpenSnackbarProps) => {
    openSnackbar({
      ...snackbar,
      icon: SnackbarIcons.SUCCESS,
    });
  };

  const closeSnackbar = () => {
    dispatch(CloseSnackbar());
  };

  return { openError, openSuccess, closeSnackbar };
};
