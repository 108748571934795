import { useRaApi } from "@app/hooks";
import AgentRequest from "@app/types/agent-request";
import { Agency } from "@app/itineraries/types/interfaces";

export const useSendEmailNotification = () => {
  const { request, loading } = useRaApi();

  const sendNewAgencyEmail = async (data: Agency) => {
    await request({
      url: "/send-email/agency-request",
      method: "POST",
      data: data,
    });
  };

  const sendNewAgentEmail = async (data: AgentRequest) => {
    await request({
      url: "/send-email/agent-request",
      method: "POST",
      data: data,
    });
  };

  return { sendNewAgentEmail, sendNewAgencyEmail, loading };
};
