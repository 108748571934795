import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { BackButton } from "@app/components";
import IconText from "@app/components/icon-text";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Cluster } from "@app/itineraries/types/interfaces";
import {
  ClusterCard,
  ClusterListPlaceholder,
} from "@app/itineraries/components/index";
import { differenceBy } from "lodash";

interface Props {
  loading: boolean;
  onView: (cluster: Cluster, selectedClusters: Cluster[]) => void;
  selectedClusters: Cluster[];
  clusters: Cluster[];
  backToEdit: () => void;
  lengthOfStay: string;
  backToItineraries: () => void;
  onCreateItinerary: (clusters: Cluster[]) => void;
  onReset: () => void;
  onSetSelectedClusters: (selectedClusters: Cluster[]) => void;
}

const ItineraryBuilder = (props: Props) => {
  const [elements, setElements] = useState({
    clusters: [],
    selectedClusters: [],
  });

  useEffect(() => {
    if (props.clusters) {
      setElements(generateLists());
    }
  }, [props.clusters, props.selectedClusters]);

  const generateLists = () => {
    return {
      clusters: differenceBy(
        props.clusters,
        props.selectedClusters,
        (cluster: Cluster) => cluster.id
      ),
      selectedClusters: props.selectedClusters,
    };
  };

  const removeFromList = (list, index) => {
    const result = [...list];
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };

  const addToList = (list, index, element) => {
    const result = [...list];
    result.splice(index, 0, element);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const elementsCopy = { ...elements };
    const sourceList = elementsCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    elementsCopy[result.source.droppableId] = newSourceList;
    const destinationList = elementsCopy[result.destination.droppableId];
    elementsCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );
    props.onSetSelectedClusters(elementsCopy.selectedClusters);
    setElements(elementsCopy);
  };

  const noContent = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <ClusterCard draggedCard={true} />
        <Box paddingTop={9}>
          <img
            src={"/icons/drag-flick.svg"}
            alt={"drag"}
            height={72}
            width={61}
          />
        </Box>
        <Box
          paddingTop={2}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box className={"gap-xxs"} display={"flex"} flexDirection={"row"}>
            <Typography className={"text-color-gray"} variant={"caption"}>
              {"Drag and drop your desired clusters"}
            </Typography>
            <Typography color="primary" variant={"caption"}>
              here
            </Typography>
          </Box>
          <Typography className="text-color-gray" variant={"caption"}>
            from the right panel
          </Typography>
        </Box>
      </Box>
    );
  };

  const getClusters = (_clusters: Cluster[], draggedCard = false) => {
    if (!_clusters.length) {
      return draggedCard ? (
        noContent()
      ) : (
        <Typography>There are no other clusters available.</Typography>
      );
    }
    return _clusters?.map((cluster: Cluster, index: number) => {
      return (
        <Draggable
          key={cluster.id}
          index={index}
          draggableId={cluster.id.toString()}
        >
          {(provided, snapshot) => (
            <div
              onClick={() => {
                if (!draggedCard) {
                  props.onView(cluster, elements.selectedClusters);
                }
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ClusterCard cluster={cluster} draggedCard={draggedCard} />
            </div>
          )}
        </Draggable>
      );
    });
  };

  const renderClusters = () => {
    if (props.loading) {
      return <ClusterListPlaceholder />;
    }

    return (
      <Droppable droppableId="clusters">
        {(provided) => (
          <Box
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
            className={"gap-m"}
            minHeight={"800px"}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {getClusters(elements["clusters"])}

            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        paddingBottom={4}
      >
        <BackButton
          title={"Back"}
          onClick={() => {
            props.backToEdit();
          }}
        />
      </Box>

      <Box display={"flex"} flexDirection={"row"}>
        <Box paddingRight={4} className={"width-50"}>
          <Typography variant={"h1"}>Your own itinerary</Typography>
          <IconText
            text={props.lengthOfStay}
            variant={"h4"}
            icon={"/icons/clock.svg"}
          />
          <Box marginTop={4}>
            <Box display={"flex"} flexDirection={"column"} className={"gap-xs"}>
              <Typography variant={"body2"}>
                Review the options we have pre-created for you on the right-side
                panel.
              </Typography>
              <Typography variant={"body2"}>
                Once you&#39;ve found your favorites, start dragging them over
                to the left-side panel until you reach the length of itinerary
                you desire. 1 night stays can be manually added by our
                destination experts later if needed.
              </Typography>{" "}
              <Typography variant={"body2"}>
                Our destination experts will need to review any Bookings that
                you create to make sure your choices work logistically and
                geographically. Please allow up to 48hrs for revisions.
              </Typography>
            </Box>
            <Droppable droppableId="selectedClusters">
              {(provided) => (
                <Box
                  paddingTop={4}
                  display={"flex"}
                  overflow={"auto"}
                  flexDirection={"column"}
                  className={"gap-m"}
                  height={"420px"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {getClusters(elements["selectedClusters"], true)}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
            <Box
              marginTop={4}
              display={"flex"}
              flexDirection={"row"}
              className={"gap-sm"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button
                onClick={props.onReset}
                variant={"outlined"}
                className={"min-width-100"}
              >
                Reset
              </Button>
              <Button
                className={"min-width-100"}
                onClick={() => props.backToItineraries()}
                variant={"outlined"}
              >
                Exit
              </Button>
              <Button
                onClick={() =>
                  props.onCreateItinerary(elements.selectedClusters)
                }
                variant={"contained"}
                color="primary"
                disabled={!elements.selectedClusters?.length}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          paddingX={4}
          paddingY={4}
          overflow={"auto"}
          height={"800px"}
          marginTop={"88px"}
          display={"flex"}
          flexDirection={"column"}
          className="width-50 gap-l"
          bgcolor={"#D9CAB71A"}
          borderRadius={"8px 0px 0px 0px"}
          alignItems={"center"}
        >
          {renderClusters()}
        </Box>
      </Box>
    </DragDropContext>
  );
};

export default ItineraryBuilder;
