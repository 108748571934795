import { createTheme } from "@material-ui/core/styles";
import Colors from "@app/types/colors.enum";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#f1be7c",
      dark: "#D59C5B",
    },
    secondary: {
      main: "#274d4f",
      dark: "#163133",
    },
    error: {
      main: "#bb2d25",
    },
    success: {
      main: "#639f44",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: "Sofia Pro",
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontWeight: "bold",
        fontSize: "2.5rem",
      },
    },
    MuiButton: {
      outlined: {
        textTransform: "none",
        fontWeight: 400,
        minWidth: "182px",
        height: "43px",
        borderRadius: "22px",
      },
      contained: {
        font: "normal normal bold 16px/24px Sofia Pro !important",
        letterSpacing: "0 !important",
        textTransform: "none",
        fontWeight: "bold",
        color: "#fff !important",
        minWidth: "182px",
        borderRadius: "22px",
        height: "43px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        border: `1px solid ${Colors.LIGHT_GRAY}`,
        background: "#000000 0% 0% no-repeat padding-box !important",
        borderRadius: "11px !important",
        font: "normal normal normal 12px/21px Open Sans",
        letterSpacing: "0px",
        color: "#F5F7FA",
      },
    },
    MuiFilledInput: {
      root: {
        background: "white !important",
        borderRadius: "8px !important",
      },
      underline: {
        "&:after": {
          display: "none",
        },
        "&:before": {
          display: "none",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        background: "white",
        borderRadius: "8px",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiCardContent: {
      root: {
        fontFamily: "Sofia Pro",
        "&:last-child": {
          paddingBottom: "0 important",
        },
      },
    },
    MuiTab: {
      root: {
        "&.MuiTab-root": {
          padding: "0px !important",
          textAlign: "left",
          font: "normal normal bold 14px/30px Sofia Pro",
          letterSpacing: "0px",
          minWidth: "0px !important",
          textTransform: "none",
          width: "auto !important",
          minHeight: "30px !important",
          color: Colors.DARK_GRAY,
          marginRight: "44px !important",
        },
        "&.Mui-selected": {
          color: `${Colors.PRIMARY} !important`,
        },
      },
    },
    MuiTabs: {
      root: {
        width: "100%",
        marginBottom: "24px",
        minHeight: "30px !important",
      },
      flexContainer: {
        borderBottom: `1px solid ${Colors.GRAY}`,
      },
    },
  },
});

export default theme;
