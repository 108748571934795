import React, { useState } from "react";
import { ItineraryTab } from "@app/itineraries/types/interfaces";
import { Tabs, TabPanel } from "@app/components";
import { Tab } from "@material-ui/core";

interface Props {
  tabs: ItineraryTab[];
}

const ItineraryTabs = (props: Props) => {
  const [value, setValue] = useState(0);

  const getTabs = () => {
    return props.tabs.map((tab: ItineraryTab, index: number) => (
      <Tab label={tab.label} key={index} />
    ));
  };

  const getPanels = () => {
    return props.tabs.map((tab: ItineraryTab, index: number) => (
      <TabPanel value={value} index={index} key={index}>
        {tab.component}
      </TabPanel>
    ));
  };

  return (
    <>
      <Tabs value={value} onChange={(value) => setValue(value)}>
        {getTabs()}
      </Tabs>
      {getPanels()}
    </>
  );
};

export default ItineraryTabs;
