import React, { useEffect, useRef } from "react";
import { Cluster } from "@app/itineraries/types/interfaces";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import IconText from "@app/components/icon-text";
import { useSelector } from "react-redux";
import { selectedCluster } from "@app/store/selectors/clusters.selectors";

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderRadius: "8px",
    height: "156px",
  },
  root2: {
    width: "100%",
    background: "#D9CAB70D 0% 0% no-repeat padding-box",
    border: "1px dashed #DEDEDE",
    borderRadius: "16px",
    height: "113px",
  },
  content: {
    padding: "0px",
    height: "100%",
  },
  media: {
    borderRadius: "16px",
  },
  button: {
    "&:hover": {
      filter:
        "invert(100%) sepia(90%) saturate(7422%) hue-rotate(305deg) brightness(105%) contrast(89%)",
    },
  },
});

interface Props {
  cluster?: Cluster;
  draggedCard?: boolean;
}

const ClusterCard = ({ cluster, draggedCard = false }: Props) => {
  const classes = useStyles();
  const ref = useRef(null);
  const currentCluster = useSelector(selectedCluster);

  useEffect(() => {
    if (cluster?.id === currentCluster?.id) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  return (
    <Card
      ref={ref}
      className={
        draggedCard
          ? classes.root2
          : cluster?.id === currentCluster?.id
          ? classes.root + " highlight"
          : classes.root
      }
    >
      <CardContent className={classes.content}>
        <Box
          padding={1.5}
          height={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          className="gap-m"
        >
          <img
            className={classes.button}
            src="/icons/drag&drop.svg"
            alt="drag&drop"
          />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            className="gap-xs"
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              className={"gap-xxs"}
            >
              <Typography className="bold" variant={"subtitle2"}>
                {cluster?.name}
              </Typography>
              {cluster && (
                <Box display={"flex"} flexDirection={"row"} className="gap-xs">
                  <IconText
                    bold={true}
                    variant={"caption"}
                    icon={"/icons/pin.svg"}
                    text={cluster?.location}
                  />
                  <IconText
                    bold={true}
                    variant={"caption"}
                    icon={"/icons/clock.svg"}
                    text={`${cluster?.days?.length} ${
                      cluster?.days?.length === 1 ? "day" : "days"
                    }/${cluster?.days?.length - 1} ${
                      cluster?.days?.length - 1 === 1 ? "night" : "nights"
                    }`}
                  />
                </Box>
              )}
              <Box
                height={draggedCard ? "34px" : "48px"}
                textOverflow="ellipsis"
                fontSize={"12px"}
                overflow="hidden"
              >
                {cluster?.shortDescription}
              </Box>
            </Box>
            {!draggedCard && (
              <Box height={90} width={197}>
                <img
                  height={90}
                  width={197}
                  className={classes.media}
                  src={cluster?.image?.file}
                  alt="cluster"
                />
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ClusterCard;
