import React from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import { Control, Controller, FieldValues } from "react-hook-form";
import Colors from "@app/types/colors.enum";
interface RadioInputProps {
  name: string;
  control: Control<FieldValues>;
  options: string[];
  label;
  whiteContent?: boolean;
  displayAs?: string;
  defaultValue?: string;
}

interface StyleProps {
  whiteContent?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    color: ({ whiteContent }) => (whiteContent ? Colors.WHITE : ""),
  },
}));

const RadioInput = ({
  name,
  control,
  options,
  label,
  whiteContent = false,
  displayAs = "row",
  defaultValue,
}: RadioInputProps) => {
  const generateRadioOptions = () => {
    const classes = useStyles({
      whiteContent: whiteContent,
    });
    return (
      <Box display={"flex"} flexDirection={displayAs}>
        {options.map((option) => (
          <FormControlLabel
            className={whiteContent ? "text-color-white" : "text-color-black"}
            key={option}
            value={option}
            label={option}
            control={<Radio color="primary" className={classes.root} />}
          />
        ))}
      </Box>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : options[0]}
      render={({ field: { onChange, value } }) => (
        <div>
          <FormLabel
            className={whiteContent ? "text-color-white" : "text-color-black"}
          >
            {label}
          </FormLabel>
          <RadioGroup value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
        </div>
      )}
    />
  );
};

export default RadioInput;
