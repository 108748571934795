import React, { useRef, useState } from "react";
import styled from "styled-components";
import UIButton from "@material-ui/core/Button";
import UserDropdown from "./user-dropdown";
import { CircularProgress, Popover } from "@material-ui/core";
import Colors from "@app/types/colors.enum";

interface ButtonProps {
  title: string;
  iconWidth: number;
  iconHeight: number;
  iconSrc: string;
  logOut: () => void;
  loading: boolean;
}
const StyledButton = styled(UIButton)`
  color: #fff;
  text-transform: none;

  .MuiButton-startIcon {
    width: 29px;
    height: 29px;
    padding-top: 3.5px;
    padding-left: 4px;
    background: #f1be7c 0 0 no-repeat padding-box;
    border: 2px solid #ffffff;
    border-radius: 50%;
  }
`;

const StyledDropdown = styled(Popover)`
  margin-top: 14px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${Colors.LIGHT_GRAY};
`;

export const UserButton = ({
  loading,
  title,
  iconSrc,
  iconWidth,
  iconHeight,
  logOut,
}: ButtonProps) => {
  const [open, setOpen] = useState(false);
  const refButton = useRef(null);

  const handleOnButtonClick = () => {
    setOpen(true);
  };

  const handleDropDownClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledButton
        className="no-padding button-text"
        size="small"
        startIcon={
          <img alt="icon" src={iconSrc} height={iconHeight} width={iconWidth} />
        }
        onClick={handleOnButtonClick}
        ref={refButton}
      >
        {loading ? <StyledCircularProgress size={20} /> : title}
      </StyledButton>
      <StyledDropdown
        open={open}
        anchorEl={refButton.current}
        onClose={handleDropDownClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <UserDropdown logOut={logOut} />
      </StyledDropdown>
    </>
  );
};

export default UserButton;
