import React from "react";
import { Box, Typography } from "@material-ui/core";

interface Props {
  title: string;
}

const DayTitle = ({ title }: Props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"start"}
      className={"gap-sm"}
      paddingBottom={4}
    >
      <img alt="icon" src="/icons/calendar.svg" width={29} height={27} />
      <Typography variant={"h5"} className={"bold"}>
        {title}
      </Typography>
    </Box>
  );
};

export default DayTitle;
