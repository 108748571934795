import { Snackbar } from "@app/types/snackbar";
import { getType } from "typesafe-actions";
import {
  CloseConfirmationDialog,
  CloseSnackbar,
  OpenSnackbar,
  OpenConfirmationDialog,
  OpenLoadingDialog,
  CloseLoadingDialog,
} from "@app/store/actions/core.actions";
import { ConfirmationDialog } from "@app/types/confirmation.dialog.interface";
import { LoadingDialog } from "@app/types/loading-dialog.interface";

export interface CoreState {
  snackbar: Snackbar;
  confirmationDialog: ConfirmationDialog;
  loadingDialog: LoadingDialog;
}

const initialState: CoreState = {
  snackbar: null,
  confirmationDialog: null,
  loadingDialog: null,
};

export default (state = initialState, action): CoreState => {
  switch (action.type) {
    case getType(OpenSnackbar): {
      const snackbar: Snackbar = action.payload;

      return {
        ...state,
        snackbar: snackbar,
      };
    }
    case getType(CloseSnackbar): {
      return {
        ...state,
        snackbar: null,
      };
    }
    case getType(OpenConfirmationDialog): {
      const confirmationDialog: ConfirmationDialog = action.payload;
      return {
        ...state,
        confirmationDialog: confirmationDialog,
      };
    }
    case getType(CloseConfirmationDialog): {
      return {
        ...state,
        confirmationDialog: null,
      };
    }
    case getType(OpenLoadingDialog): {
      const loadingDialog: LoadingDialog = action.payload;
      return {
        ...state,
        loadingDialog: loadingDialog,
      };
    }
    case getType(CloseLoadingDialog): {
      return {
        ...state,
        loadingDialog: null,
      };
    }
    default:
      return state;
  }
};
