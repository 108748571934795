import React, { useRef, useState } from "react";
import { Button } from "@app/components";
import Colors from "@app/types/colors.enum";
import { Box } from "@material-ui/core";
import { readFile } from "@app/utils";
import { CropperDialogRef } from "@app/profile/types/refs";
import AgencyLogoDialog from "@app/profile/containers/agency-logo-dialog";

interface AgencyLogoProps {
  logoUrl: string;
}

const AgencyLogo = ({ logoUrl }: AgencyLogoProps) => {
  const inputRef = useRef(null);
  const imageRef = useRef(null);
  const cropperDialogRef = useRef<CropperDialogRef>(null);
  const [showError, setShowError] = useState<boolean>(false);

  const triggerImageUpload = () => {
    inputRef.current.click();
  };

  const imgLoaded = () => {
    const img = imageRef.current;
    const height = img.naturalHeight;
    const width = img.naturalWidth;

    if (width === height && width <= 500) {
      setShowError(false);
      cropperDialogRef.current.onOpen(img.src);
    } else {
      setShowError(true);
    }
    inputRef.current.value = null;
  };

  const handleImageUpload = async (event) => {
    const imageDataUrl = await readFile(event.target.files[0]);
    const img = new Image();
    img.onload = imgLoaded;
    img.src = imageDataUrl;
    imageRef.current = img;
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      width={"35%"}
    >
      {!logoUrl && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={"logo-background"}
        >
          <img
            className={"image-contain"}
            src={"/icons/image-gallery.svg"}
            width={25}
            height={23}
          />
        </Box>
      )}
      {logoUrl && (
        <img
          className={"rounded-image image-contain"}
          src={logoUrl}
          width={100}
          height={100}
        />
      )}
      <Box
        alignContent={"center"}
        fontSize={"27px"}
        fontWeight={600}
        marginTop={4}
      >
        Company logo
      </Box>
      <Box
        marginTop={3}
        fontSize={16}
        textAlign={"center"}
        color={Colors.DARK_GRAY}
      >
        {
          "Please upload a logo that has an aspect ratio of 1:1. It should have the same width and height, but no larger than 500x500px."
        }
      </Box>
      <Box marginTop={1} fontSize={16}>
        {"Press Add File to add your logo."}
      </Box>
      <Box marginTop={4}>
        <Button
          title={"Add File"}
          width={120}
          height={40}
          color={Colors.PRIMARY}
          hoverColor={Colors.HOVER_PRIMARY}
          textColor={Colors.WHITE}
          withBorder={false}
          onClick={triggerImageUpload}
        />
        <input
          className="display-none"
          multiple={false}
          accept="image/jpeg, image/png"
          ref={inputRef}
          type="file"
          onChange={handleImageUpload}
        />
        <AgencyLogoDialog ref={cropperDialogRef} />
      </Box>
      {showError && (
        <Box marginTop={1} fontSize={14} color={Colors.ERROR}>
          {
            "The aspect ratio of the uploaded logo is different than 1:1. Please try again."
          }
        </Box>
      )}
    </Box>
  );
};

export default AgencyLogo;
