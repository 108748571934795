import { IconButton } from "@app/components/index";
import React from "react";

interface Props {
  title: string;
  onClick: () => void;
}

const BackButton = ({ title, onClick }: Props) => {
  return (
    <IconButton
      iconPath="/icons/left-arrow.svg"
      title={title}
      iconWidth={5}
      iconHeight={9}
      width={180}
      height={20}
      withoutHoverColor={true}
      styleClassName="body-text-1 text-color-black"
      onClick={onClick}
    />
  );
};

export default BackButton;
