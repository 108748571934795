import { createAction } from "typesafe-actions";
import { Itinerary } from "@app/itineraries/types/interfaces";

const LOAD_PRE_POST_TOURS = "[Itineraries] Set Pre Post Tours";
const LOAD_PRE_POST_TOUR = "[Itineraries] Set Pre Post Tour";

export const LoadPrePostTours = createAction(
  LOAD_PRE_POST_TOURS,
  (prePostTours: Itinerary[]) => prePostTours
)<Itinerary[]>();

export const LoadPrePostTour = createAction(
  LOAD_PRE_POST_TOUR,
  (prePostTour: Itinerary) => prePostTour
)<Itinerary>();
