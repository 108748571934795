import { useApi } from "@app/hooks";
import { AgencyDetails } from "@app/profile/types/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { LoadAgency } from "@app/store/actions/agency.actions";
import { selectAgency } from "@app/store/selectors/agency.selectors";
import { useEffect } from "react";

export const useAgency = () => {
  const dispatch = useDispatch();
  const { request, loading } = useApi();
  const user = useSelector(selectCurrentUser);
  const agency = useSelector(selectAgency);

  useEffect(() => {
    if (!agency) {
      getAgency();
    }
  }, []);

  const getAgency = async () => {
    const agencyResponse = await request<AgencyDetails>({
      url: `records/agency/${user.agency}`,
      method: "GET",
      queryParams: {
        datafy_fields:
          "website_url,contact_phone_number,contact_email,logo.file,id,itinerary_commission,insurance_commission",
      },
    });
    dispatch(LoadAgency(agencyResponse));
  };

  return { agency, loading };
};
