import { User } from "@app/types/user";
import Table from "@app/components/table";
import React from "react";
import Columns from "@app/types/columns";
import { Booking } from "@app/bookings/types/classes";
import {
  PersonalizeButton,
  BookingDownloadButton,
  BookingStatus,
  BookingsPlaceholder,
} from "@app/bookings/components/";

interface TableProps {
  user: User;
  downloadBooking: (data: Booking) => void;
  loading: boolean;
  data: Booking[];
  page: number;
  size: number;
  pageSize: number;
  loadMore: () => void;
  loadPrevious: () => void;
  onPersonaliseBooking: (bookingId: number) => void;
}

const BookingsTable = ({
  user,
  data,
  page,
  size,
  downloadBooking,
  pageSize,
  loading,
  loadMore,
  loadPrevious,
  onPersonaliseBooking,
}: TableProps) => {
  let columns: Columns[] = [
    {
      cell: (data: Booking) => data.bookingNo,
      name: "Booking no.",
      align: "center",
      key: "bookingNo",
    },
    {
      cell: (data: Booking) => data.clientName,
      name: "Client name",
      align: "center",
      key: "clientName",
    },
    {
      cell: (data: Booking) => data.amount + "$",
      name: "Amount",
      align: "center",
      key: "amount",
    },
    {
      cell: (data: Booking) => data.commission + "$",
      name: "Agent commission",
      align: "center",
      key: "commission",
    },
    {
      cell: (data: Booking) =>
        new Date(data.arrivalDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
      name: "Arrival date",
      align: "center",
      key: "arrivalDate",
      style: { "min-width": "90px" },
    },
    {
      cell: (data: Booking) =>
        new Date(data.returnDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
      name: "Return date",
      align: "center",
      key: "returnDate",
      style: { "min-width": "90px" },
    },
    {
      cell: (data: Booking) =>
        new Date(data.depositPayment).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
      name: "Deposit payment",
      align: "center",
      key: "depositPayment",
      style: { "min-width": "90px" },
    },
    {
      cell: (data: Booking) =>
        new Date(data.finalPayment).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
      name: "Final payment",
      align: "center",
      key: "finalPayment",
      style: { "min-width": "90px" },
    },
    {
      cell: (data: Booking) => <BookingStatus status={data.status} />,
      name: "Status",
      align: "center",
      key: "status",
      style: { "padding-left": 0, "padding-right": 0 },
    },
    {
      cell: (data: Booking) => {
        if (!data.isPersonalized) {
          return (
            <PersonalizeButton
              bookingId={data.id}
              openDialog={onPersonaliseBooking}
            />
          );
        }
        return <></>;
      },
      name: "Personalize",
      align: "center",
      key: "personalize",
    },
    {
      cell: (data: Booking) => (
        <BookingDownloadButton
          booking={data}
          downloadPdf={downloadBooking}
          loading={data.isDownloading}
        />
      ),
      name: "PDF",
      align: "center",
      key: "pdf",
    },
  ];

  if (user?.masterAccount) {
    columns = [
      ...columns.slice(0, 2),
      {
        cell: (data: Booking) => data.agentName,
        name: "Agent name",
        style: { width: "12%" },
        align: "center",
        key: "agentName",
      },
      ...columns.slice(2),
    ];
  }

  return (
    <Table
      data={data}
      loading={loading}
      page={page}
      size={size}
      loadMore={loadMore}
      loadPrevious={loadPrevious}
      pageSize={pageSize}
      columns={columns}
      emptyText={"There are no bookings!"}
      paginatorText={"bookings"}
      TablePlaceholder={BookingsPlaceholder}
    />
  );
};

export default BookingsTable;
