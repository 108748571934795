import React from "react";
import Lottie from "react-lottie";
import { Dialog } from "@app/components/index";
import Typography from "@material-ui/core/Typography";
import { Box, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Colors from "@app/types/colors.enum";

interface ConfirmationDialogProps {
  title: string;
  open: boolean;
  message: string;
  buttonText?: string;
  onClose: (success?: boolean) => void;
  optionalActionButtonTitle?: string;
  optionalFunction?: () => void;
  optionalButtonLoading?: boolean;
}

const defaultOptions = {
  loop: false,
  autoplay: true,
  path: "/animations/success_animation.json",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ConfirmationDialog = ({
  title,
  open,
  message,
  ...props
}: ConfirmationDialogProps) => {
  const onClose = () => props.onClose();
  const onCloseSuccess = () => props.onClose(true);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      titleVariant={"h5"}
      bold={true}
    >
      <Box
        width={450}
        marginY={4}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        className="gap-l"
      >
        {message && (
          <Typography variant={"body1"} align={"center"}>
            {message}
          </Typography>
        )}{" "}
        <Lottie options={defaultOptions} height={110} width={118} />
        {props.optionalActionButtonTitle && (
          <Button
            onClick={props.optionalFunction}
            variant={"contained"}
            color="primary"
          >
            {props.optionalButtonLoading ? (
              <CircularProgress
                style={{
                  color: Colors.LIGHT_GRAY,
                }}
                size={20}
              />
            ) : (
              props.optionalActionButtonTitle
            )}
          </Button>
        )}
        <Button
          onClick={onCloseSuccess}
          variant={props.optionalActionButtonTitle ? "outlined" : "contained"}
          color={props.optionalActionButtonTitle ? "inherit" : "primary"}
        >
          {props.buttonText || "Done"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
