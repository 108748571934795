import { useEffect, useState } from "react";

export const usePagination = (getData, setData, pageSize = 25) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      call();
    }
  }, [page, refreshing]);

  const call = async () => {
    const responseData = await getData(page, pageSize);
    setData(responseData.results);
    setSize(responseData.count);
    setLoading(false);
    setRefreshing(false);
  };

  const refresh = () => {
    setPage(1);
    setRefreshing(true);
  };
  const loadMore = () => {
    if (!loading && page * pageSize < size) {
      setPage(page + 1);
    }
  };
  const loadPrevious = () => {
    if (!loading && page > 1) {
      setPage(page - 1);
    }
  };

  return {
    page,
    size,
    loading,
    refreshing,
    loadMore,
    loadPrevious,
    refresh,
  };
};
