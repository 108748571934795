import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

interface IconTextProps {
  icon: string;
  text: string;
  variant: Variant;
  bold?: boolean;
}

const IconText = ({ icon, text, variant, bold = false }: IconTextProps) => {
  return (
    <div className="flex-row-start-center">
      <img alt="icon" src={icon} width={12} height={12} />
      <Box marginTop={"4px"} marginLeft={"7.35px"}>
        <Typography className={bold ? "bold" : ""} variant={variant}>
          {text}
        </Typography>
      </Box>
    </div>
  );
};

export default IconText;
