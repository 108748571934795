import Header from "./header";
import Footer from "./footer";
import React, { ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
  parallaxHeader?: boolean;
}

export const Layout = ({ children, parallaxHeader }: LayoutProps) => {
  return (
    <div>
      <Header parallaxHeader={parallaxHeader} />
      <div
        style={{ marginTop: parallaxHeader ? "400px" : "100px" }}
        className={"layout-container"}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
