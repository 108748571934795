import { useApi } from "@app/hooks";
import { LoadItinerary } from "@app/store/actions/itineraries.actions";
import { useDispatch, useSelector } from "react-redux";
import { Itinerary } from "@app/itineraries/types/interfaces";
import { selectItineraryById } from "@app/store/selectors/itineraries.selectors";
import { useEffect } from "react";

export const useItinerary = (
  id: number
): { itinerary: Itinerary; loading: boolean } => {
  const { request, loading } = useApi();
  const itinerary = useSelector(selectItineraryById(id));
  const dispatch = useDispatch();

  useEffect(() => {
    // If we already have the itinerary in store, don't load again
    if (itinerary && itinerary.loaded) {
      dispatch(LoadItinerary(itinerary));
      return;
    }

    getItinerary(id);
  }, []);

  const getItinerary = async (id: number) => {
    const fields =
      "id,name,clusters.cluster.price,description,clusters.order,clusters.cluster.days,clusters.cluster.days.order,clusters.cluster.days.day.name,clusters.cluster.days.day.images.file,clusters.cluster.days.day.images.id,clusters.cluster.days.day.actions.name,clusters.cluster.days.day.actions.description,clusters.cluster.days.day.actions.image.file,clusters.cluster.days.day.actions.image.id,images.file,images.id,video,cover_image.file,inclusions,clusters.cluster.days.day.summary," +
      "clusters.cluster.days.day.top_description,clusters.cluster.days.day.bottom_description";
    const filters = {
      active__eq: true,
    };
    const itinerary = await request<Itinerary>({
      url: `records/itinerary/${id}`,
      method: "GET",
      queryParams: {
        ...filters,
        datafy_fields: fields,
        pageSize: 30,
      },
    });

    dispatch(LoadItinerary(itinerary));
  };

  return { itinerary: itinerary?.loaded ? itinerary : null, loading };
};
