import React from "react";
import { Box } from "@material-ui/core";

interface PersonalizeButtonProps {
  bookingId: number;
  openDialog: (bookingId: number) => void;
}

const PersonalizeButton = ({
  bookingId,
  openDialog,
}: PersonalizeButtonProps) => {
  const handleOnClick = () => {
    openDialog(bookingId);
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <img
        className={"cursor-pointer"}
        onClick={handleOnClick}
        alt={"personalize"}
        src={"/icons/edit.svg"}
        width={15}
        height={18}
      />
    </Box>
  );
};

export default PersonalizeButton;
