import { getType } from "typesafe-actions";
import { Cluster } from "@app/itineraries/types/interfaces";
import {
  LoadClusters,
  SetSelectedCluster,
} from "@app/store/actions/clusters.actions";

export interface ClustersState {
  clusters: { [id: number]: Cluster };
  selectedCluster: number;
}

const initialState: ClustersState = {
  clusters: {},
  selectedCluster: null,
};

export default (state = initialState, action): ClustersState => {
  switch (action.type) {
    case getType(LoadClusters): {
      const clusters = action.payload.reduce((result, cluster: Cluster) => {
        result[cluster.id] = cluster;
        return result;
      }, {});
      return { ...state, clusters: { ...clusters } };
    }
    case getType(SetSelectedCluster): {
      return {
        ...state,
        selectedCluster: action.payload,
      };
    }
    default:
      return state;
  }
};
