import { useState } from "react";
import { useApi } from "@app/hooks";
import { User } from "@app/types/user";
import { SetUser } from "@app/store/actions/auth.actions";
import { useDispatch } from "react-redux";

export const useAuth = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>();
  const { logIn: loginRequest, request } = useApi();

  /**
   * Login and load the current user
   */
  const logIn = async (email: string, password: string, returnUrl?: string) => {
    setLoading(true);
    try {
      await loginRequest(email, password, returnUrl);
      const user: User = await request({ url: "/auth/user", method: "GET" });
      dispatch(SetUser(user));
    } finally {
      setLoading(false);
    }
  };

  return { loginIn: loading, logIn };
};
