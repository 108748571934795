import UIButton from "@material-ui/core/Button";
import styled from "styled-components";
import React from "react";
import Colors from "@app/types/colors.enum";
import { CircularProgress } from "@material-ui/core";

interface ButtonProps {
  title: string;
  width: number;
  height: number;
  color: Colors;
  hoverColor: Colors;
  textColor: Colors;
  borderColor?: Colors;
  withBorder: boolean;
  borderRadius?: number;
  type?: string;
  onClick?: () => void;
  size?: string;
  loading?: boolean;
  disabled?: boolean;
}

const StyledButton = styled(
  ({
    width,
    height,
    color,
    hoverColor,
    textColor,
    withBorder,
    borderRadius,
    borderColor,
    ...otherProps
  }) => <UIButton {...otherProps} />
)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => (props.size ? props.size : "16")}px
    ${(props) => (props.size ? "!important" : "")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "22"}px;
  text-transform: none;
  background-color: ${(props) => props.color};
  color: ${(props) => props.textColor};
  border: ${(props) =>
    props.withBorder && props.borderColor
      ? `1px solid ${props.borderColor}`
      : ""};
  &:hover {
    color: ${(props) =>
      props.withBorder && !props.borderColor ? props.hoverColor : ""};
    border: ${(props) =>
      props.withBorder ? `1px solid ${props.hoverColor}` : ""};
    background-color: ${(props) => (!props.withBorder ? props.hoverColor : "")};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${Colors.LIGHT_GRAY};
`;
const Button = ({
  title,
  width,
  height,
  color,
  hoverColor,
  withBorder,
  textColor,
  borderColor,
  borderRadius,
  type,
  onClick,
  size,
  loading,
  disabled,
}: ButtonProps) => {
  return (
    <StyledButton
      type={type ? type : "button"}
      className="button-text"
      width={width}
      size={size}
      height={height}
      color={color}
      hoverColor={hoverColor}
      withBorder={withBorder}
      textColor={textColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? <StyledCircularProgress size={20} /> : title}
    </StyledButton>
  );
};

export default Button;
