import { useApi } from "@app/hooks";
import { LoadItineraries } from "@app/store/actions/itineraries.actions";
import { useDispatch, useSelector } from "react-redux";
import { Itinerary } from "@app/itineraries/types/interfaces";
import {
  selectItineraries,
  selectItinerariesLoaded,
} from "@app/store/selectors/itineraries.selectors";
import { useEffect } from "react";
import { PaginatedResponse } from "@app/types";

/**
 * Load itineraries only once.
 */
export const useItineraries = (): {
  itineraries: Itinerary[];
  loading: boolean;
} => {
  const { request, loading } = useApi();
  const itinerariesLoaded: boolean = useSelector(selectItinerariesLoaded);
  const itineraries: Itinerary[] = useSelector(selectItineraries);
  const dispatch = useDispatch();

  useEffect(() => {
    if (itinerariesLoaded || loading) {
      return;
    }

    loadItineraries();
  }, []);

  const loadItineraries = async () => {
    const filters = {
      type__isnull: true,
      active__eq: true,
    };
    const data = await request<PaginatedResponse<Itinerary>>({
      url: "records/itinerary",
      method: "GET",
      queryParams: {
        ...filters,
        datafy_fields:
          "id,name,cover_description,clusters.cluster.price,clusters.cluster.days,cover_image.file",
        pageSize: 30,
      },
    });

    dispatch(LoadItineraries(data.results));
  };

  return { itineraries, loading };
};
