import { Quote } from "@app/quotes/types/classes";
import { map } from "lodash";
import { downloadFile } from "@app/utils";
import { useRaApi } from "@app/hooks";

export const useDownloadQuoteHook = (quotes, setQuotes) => {
  const { request: downloadApi } = useRaApi();

  const getQuotePdf = async (quote: Quote) => {
    setQuotes(
      map(quotes, (_quote) => {
        if (_quote.id === quote.id) {
          _quote.isDownloading = true;
        }
        return _quote;
      })
    );

    try {
      const response = await downloadApi({
        url: "/quote/generate-pdf",
        method: "POST",
        data: {
          id: quote.id,
        },
        responseType: "blob",
      });

      const name = `${quote.name}.pdf`;
      downloadFile(response, name);
    } finally {
      setQuotes(
        map(quotes, (_quote) => {
          if (_quote.id === quote.id) {
            _quote.isDownloading = false;
          }
          return _quote;
        })
      );
    }
  };

  return { getQuotePdf };
};
