enum BookingStatuses {
  ON_REQUEST = "On request",
  CONFIRMED = "Confirmed",
  TRAVELED = "Traveled",
  CANCELED = "Canceled",
  DEPOSITED = "Deposited",
  PAID_IN_FULL = "Paid in full",
}

export default BookingStatuses;
