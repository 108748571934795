import React from "react";
import { Button, IconButton } from "@material-ui/core";
import styled from "styled-components";
import {
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import Colors from "@app/types/colors.enum";

interface ButtonProps {
  title: string;
  register: UseFormRegister<FieldValues>;
  name: string;
  required: boolean;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 56px;
  border: 1px dashed ${Colors.LIGHT_GRAY};
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  text-align: left !important;
  font: normal normal normal 12px/24px Sofia Pro !important;
  letter-spacing: 0 !important;
  &:hover {
    background-color: ${Colors.LIGHT_GRAY};
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 56px;
  border: 1px solid ${Colors.LIGHT_GRAY};
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  text-align: center !important;
  font: normal normal normal 12px/24px Sofia Pro !important;
  letter-spacing: 0 !important;
  vertical-align: middle !important;
  line-height: 56px !important;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const StyledIconButton = styled(IconButton)`
  border: 1px solid ${Colors.LIGHT_GRAY};
  width: 16px;
  height: 16px;
  padding: 0 !important;
  margin-bottom: -9px;
  margin-right: -7px;
  background: #fff;
  &:hover {
    background: ${Colors.LIGHT_GRAY};
  }
`;

export const UploadButton = ({
  title,
  register,
  required,
  name,
  watch,
  setValue,
}: ButtonProps) => {
  const watchFile = watch(name, null);

  const onRemove = (event) => {
    setValue(name, null);
  };

  const renderButton = () => {
    if (watchFile?.length) {
      return (
        <Content>
          <StyledIconButton aria-label="delete" onClick={onRemove}>
            <img src="/icons/close.svg" width={6.325} height={6.325} />
          </StyledIconButton>
          <StyledDiv>{watchFile[0].name}</StyledDiv>
        </Content>
      );
    }

    return (
      <label htmlFor={name}>
        <StyledButton
          {...{ component: "span" }}
          startIcon={
            <img alt="icon" src="/icons/folder.svg" height={24} width={24} />
          }
        >
          {title}
        </StyledButton>
      </label>
    );
  };

  return (
    <>
      <input
        className="display-none"
        multiple={false}
        accept="application/pdf"
        id={name}
        {...register(name, { required })}
        type="file"
      />
      {renderButton()}
    </>
  );
};

export default UploadButton;
