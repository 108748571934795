import React, { useState } from "react";
import { Accordion, DatePicker, RadioInput, TextInput } from "@app/components";
import { Control, FieldValues } from "react-hook-form";
import { Box } from "@material-ui/core";

interface PassengerCardProps {
  index: number;
  control: Control<FieldValues>;
  onDelete: () => void;
}

const PassengerCard = ({ index, control, onDelete }: PassengerCardProps) => {
  const options = ["Male", "Female"];

  const renderDeleteButton = () => {
    return index > 0 ? (
      <img
        className={"cursor-pointer"}
        src="/icons/delete.svg"
        alt="delete"
        width={15}
        height={18}
        onClick={onDelete}
      />
    ) : (
      ""
    );
  };

  return (
    <div>
      <Accordion
        expanded={true}
        summary={
          <>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"start"}
              className="gap-sm"
            >
              {" "}
              <img
                src="/icons/person.svg"
                alt="person"
                width={17}
                height={17}
              />
              <div className="body-text-1 text-color-white">
                Passenger {index + 1}
              </div>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              className="gap-sm"
            >
              {renderDeleteButton()}
              {/*<img
                src={expanded ? "/icons/arrow-up.svg" : "/icons/arrow-down.svg"}
                alt="arrow"
                width={14}
                height={8}
              />*/}
            </Box>
          </>
        }
        details={
          <>
            <TextInput
              control={control}
              name={`passengers.${index}.fullname`}
              label="Full name"
              variant="filled"
              type="text"
              required={true}
            />
            <DatePicker
              control={control}
              name={`passengers.${index}.birthday`}
              label="Birthday"
              required={true}
              disableFuture={true}
              inputVariant="filled"
              asBirthday={true}
            />
            <RadioInput
              name={`passengers.${index}.gender`}
              label="Gender"
              control={control}
              whiteContent={true}
              options={options}
            />
          </>
        }
      />
    </div>
  );
};

export default PassengerCard;
