import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CancelButton, PrimaryButton, RadioInput } from "@app/components";
import { CustomItinerary } from "@app/itineraries/types/interfaces";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectCustomItinerary } from "@app/store/selectors/itineraries.selectors";
import { isEmpty } from "lodash";

interface FormProps {
  onSubmit: (data: CustomItinerary) => void;
  onClose: () => void;
}

const CreateItineraryForm = ({ onSubmit, onClose }: FormProps) => {
  const { control, handleSubmit, setValue } = useForm({
    mode: "all",
  });
  const customItinerary = useSelector(selectCustomItinerary);

  const periods = ["5-7 nights", "7-10 nights", "10-12 nights", "2 weeks"];
  const seasons = ["Spring", "Summer", "Autumn", "Winter"];
  const purposes = [
    "Relaxation and nature/being off the grid",
    "Adventure and exploration",
    "A mix of both",
  ];

  useEffect(() => {
    if (!isEmpty(customItinerary)) {
      setValue("lengthOfStay", customItinerary.lengthOfStay);
      setValue("timeOfTravel", customItinerary.timeOfTravel);
      setValue("lookingFor", customItinerary.lookingFor);
    }
  }, [customItinerary]);

  const onSubmitForm = (data: CustomItinerary) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box display={"flex"} flexDirection={"column"} className="gap-l">
        <RadioInput
          name="lengthOfStay"
          label="Length of stay:"
          control={control}
          options={periods}
        />
        <RadioInput
          name="timeOfTravel"
          label="Time of travel:"
          control={control}
          options={seasons}
        />
        <RadioInput
          name="lookingFor"
          label="Looking for:"
          displayAs="column"
          control={control}
          options={purposes}
        />
        <Box
          className="gap-l"
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
        >
          <CancelButton onClose={onClose} />
          <PrimaryButton title="See results" />
        </Box>
      </Box>
    </form>
  );
};

export default CreateItineraryForm;
