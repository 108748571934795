import { useApi } from "@app/hooks";
import { Agency } from "@app/itineraries/types/interfaces/agency";

export const useAgency = () => {
  const { request, loading } = useApi();

  const saveAgency = async (data: FormData): Promise<Agency> => {
    return await request<Agency>({
      url: "records/agency",
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  return { saveAgency, loading };
};
