import React from "react";
import { Box, Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { DownloadButton } from "@app/itineraries/components/index";

interface Props {
  loading: boolean;
  onOpenBookDialog: () => void;
  onDownloadPdf: () => void;
  onOpen: () => void;
}

const ItineraryButtons = (props: Props) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"flex-end"}
      className="gap-m"
    >
      <Tooltip title={"Download"} placement="top">
        <div>
          <DownloadButton
            downloadPdf={props.onDownloadPdf}
            loading={props.loading}
          />
        </div>
      </Tooltip>
      <Tooltip title="Quotes will be deleted after 7 days" placement="top">
        <div>
          <Button
            onClick={props.onOpen}
            variant={"contained"}
            color="secondary"
          >
            Save as quote
          </Button>
        </div>
      </Tooltip>
      <Button
        onClick={props.onOpenBookDialog}
        variant={"contained"}
        color="primary"
      >
        Book
      </Button>
    </Box>
  );
};

export default ItineraryButtons;
