import { useApi } from "@app/hooks/index";
import { PaginatedResponse } from "@app/types";
import { useDispatch, useSelector } from "react-redux";
import { GeneralInfosItinerary } from "@app/types/general-infos-itinerary";
import { useEffect } from "react";
import {
  selectItineraryGeneralInfos,
  selectItineraryGeneralInfosLoaded,
} from "@app/store/selectors/itineraries.selectors";
import { LoadItineraryGeneralInfos } from "@app/store/actions/itineraries.actions";

export const useGeneralInfosItinerary = () => {
  const { request } = useApi();
  const dispatch = useDispatch();
  const generalInfosItineraryLoaded: boolean = useSelector(
    selectItineraryGeneralInfosLoaded
  );
  const generalInfosItinerary: GeneralInfosItinerary = useSelector(
    selectItineraryGeneralInfos
  );

  useEffect(() => {
    if (generalInfosItineraryLoaded) {
      return;
    }

    loadActiveGeneralInfosItinerary();
  }, []);

  const loadActiveGeneralInfosItinerary = async () => {
    const activeGeneralInfosItinerary = await request<
      PaginatedResponse<GeneralInfosItinerary>
    >({
      url: `records/general_infos_itinerary`,
      method: "GET",
      queryParams: {
        datafy_fields:
          "terms_and_conditions_web,itinerary_commission_percentage",
        active: "True",
        pageSize: 1,
      },
    });
    dispatch(LoadItineraryGeneralInfos(activeGeneralInfosItinerary.results[0]));
  };

  return { generalInfosItinerary };
};
