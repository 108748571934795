import { getType } from "typesafe-actions";
import { LoadAgency } from "@app/store/actions/agency.actions";
import { AgencyDetails } from "@app/profile/types/interfaces";
import { LogOut } from "@app/store/actions/auth.actions";

export interface AgencyState {
  agency: AgencyDetails;
}

const initialState: AgencyState = {
  agency: null,
};

export default (state = initialState, action): AgencyState => {
  switch (action.type) {
    case getType(LoadAgency): {
      const agency: AgencyDetails = action.payload;
      return {
        ...state,
        agency: agency,
      };
    }
    case getType(LogOut): {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return state;
  }
};
