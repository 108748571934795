import React, { useEffect, useState } from "react";
import Colors from "@app/types/colors.enum";
import { Button, IconButton, ReadonlyInput, TextInput } from "@app/components";
import { useForm } from "react-hook-form";
import { User } from "@app/types/user";
import { Box } from "@material-ui/core";

interface ProfileForm {
  loading: boolean;
  onSubmit: (data: User) => void;
  user: User;
}

const ProfileForm = ({ loading, onSubmit, user }: ProfileForm) => {
  const { control, handleSubmit, setValue } = useForm({
    mode: "all",
  });
  const [readonly, setReadonly] = useState(true);

  useEffect(() => {
    if (!loading) {
      setReadonly(true);
    }
  }, [loading]);

  useEffect(() => {
    if (!readonly) {
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
    }
  }, [readonly]);

  const renderForm = () => {
    if (readonly) {
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          width={"100%"}
          className={"gap-m"}
        >
          <ReadonlyInput
            value={user?.firstName}
            label="First name"
            withBorder={true}
          />
          <ReadonlyInput
            value={user?.lastName}
            label="Last name"
            withBorder={true}
          />
          <IconButton
            title="Edit"
            iconPath="/icons/edit.svg"
            height={20}
            width={59}
            iconWidth={15}
            iconHeight={15}
            hoverColor={Colors.PRIMARY}
            onClick={() => setReadonly(false)}
          />
        </Box>
      );
    }
    return (
      <form className={"width-100"} onSubmit={handleSubmit(onSubmit)}>
        <Box
          className={"gap-m"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
        >
          <TextInput
            required={true}
            name="firstName"
            type="text"
            label="First name"
            control={control}
          />
          <TextInput
            required={true}
            name="lastName"
            type="text"
            label="Last name"
            control={control}
          />
          <Box marginTop={2}>
            <Button
              type="submit"
              title="Save"
              loading={loading}
              width={180}
              height={43}
              color={Colors.PRIMARY}
              hoverColor={Colors.HOVER_PRIMARY}
              textColor={Colors.WHITE}
              withBorder={false}
            />
          </Box>
        </Box>
      </form>
    );
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-end"}
      width={549}
    >
      {renderForm()}
      <ReadonlyInput value={user?.email} label="Email" withBorder={false} />
    </Box>
  );
};

export default ProfileForm;
