import React from "react";
import {
  createStyles,
  Dialog as MatDialog,
  IconButton,
  Theme,
  WithStyles,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Variant } from "@material-ui/core/styles/createTypography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(3),
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  hideClose: boolean;
  onClose: () => void;
  titleVariant?: Variant;
  bold?: boolean;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children,
    classes,
    onClose,
    titleVariant = "h6",
    hideClose,
    bold = false,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className="bold" variant={titleVariant} align={"center"}>
        {children}
      </Typography>

      {!hideClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <img src="/icons/close.svg" width={17} height={17} />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))(MuiDialogContent);

interface Props {
  title: string;
  titleVariant?: Variant;

  open: boolean;
  children: React.ReactNode;
  hideClose?: boolean;
  onClose?: () => void;
  disableClosing?: boolean;
  bold?: boolean;
}

const Dialog = (props: Props) => {
  return (
    <MatDialog
      disableRestoreFocus={true}
      disableBackdropClick={props.disableClosing}
      PaperProps={{
        style: {
          borderRadius: 16,
          maxWidth: "fit-content",
        },
      }}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle
        hideClose={props.hideClose}
        onClose={props.onClose}
        titleVariant={props.titleVariant}
        bold={props.bold}
      >
        {props.title}
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </MatDialog>
  );
};

export default Dialog;
