import { useApi, usePagination, useRaApi } from "@app/hooks";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import {
  PersonalizeFormData,
  RawBooking,
} from "@app/bookings/types/interfaces";
import { Booking } from "@app/bookings/types/classes";
import { PaginatedResponse } from "@app/types";
import { downloadFile } from "@app/utils";
import { useEffect, useState } from "react";
import { map } from "lodash";
import { useDownloadBookingHook } from "@app/bookings/hooks/download-booking-hook";

export const useBookings = (pageSize = 15) => {
  const { request, loading } = useApi();
  const { request: downloadApi } = useRaApi();
  const user = useSelector(selectCurrentUser);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [personalizeLoading, setPersonalizeLoading] = useState<boolean>(false);
  const [bookingsLoading, setBookingsLoading] = useState<boolean>(false);
  const { getBookingPdf } = useDownloadBookingHook(bookings, setBookings);

  const getBookings = async (page: number, pageSize: number) => {
    const defaultFields =
      "id,created_at,insurance_price,itinerary_price,insurance_commission,itinerary_commission,arrival_date,return_date,deposit_payment,final_payment,status,mobility_issue,client_name";
    const masterFields = user.masterAccount
      ? ",agent.first_name,agent.last_name"
      : "";

    const fields = defaultFields + masterFields;
    const filters = user.masterAccount
      ? { agency__eq: user.agency }
      : { agent__eq: user.id };

    setBookingsLoading(true);

    const response = await request<PaginatedResponse<RawBooking | Booking>>({
      url: "records/booking",
      method: "GET",
      queryParams: {
        ...filters,
        datafy_fields: fields,
        pageSize: pageSize,
        page: page,
      },
    });

    setBookingsLoading(false);

    response.results = response.results.map(
      (data: RawBooking) => new Booking(data)
    );

    return response;
  };

  const { page, size, loadMore, loadPrevious } = usePagination(
    getBookings,
    setBookings,
    pageSize
  );

  const personalizeBooking = async (
    id: number,
    bookingPersonalize: PersonalizeFormData
  ) => {
    setPersonalizeLoading(true);
    await request<Booking>({
      url: `records/booking/${id}`,
      method: "PATCH",
      body: bookingPersonalize,
    });
    setPersonalizeLoading(false);
    setBookings(
      map(bookings, (_booking) => {
        if (_booking.id === id) {
          _booking.isPersonalized = true;
        }
        return _booking;
      })
    );
  };

  return {
    bookings,
    personalizeBooking,
    personalizeLoading,
    bookingsLoading,
    getBookingPdf,
    page,
    size,
    loadMore,
    loadPrevious,
    loading,
  };
};
