import { TOKEN_LOCAL_STORAGE, useApi } from "./use-api.hook";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { useEffect, useState } from "react";
import { SetUser } from "@app/store/actions/auth.actions";
import { User } from "@app/types/user";
import { Token } from "@app/types/token";

/**
 * Hook used for loading the current user. If the current user is already loaded, skip
 * Check first if we have access and current t
 */
export const useLoadUser = () => {
  const { request } = useApi();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    const token: Token = JSON.parse(
      window.localStorage.getItem(TOKEN_LOCAL_STORAGE)
    );

    if (!token) {
      setLoading(false);
      return;
    }

    getUser();
  }, []);

  const getUser = async (): Promise<void> => {
    try {
      const user: User = await request({ url: "/auth/user", method: "GET" });
      await dispatch(SetUser(user));
    } finally {
      setLoading(false);
    }
  };

  return { user, loading };
};
