import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

interface DownloadButtonProps {
  downloadPdf: () => void;
  loading: boolean;
}
const ImageContainer = styled.div`
  display: flex;
  place-content: center;
`;

const DownloadButton = ({ downloadPdf, loading }: DownloadButtonProps) => {
  const handleOnClick = () => {
    downloadPdf();
  };

  const renderButton = () => {
    if (loading) {
      return <CircularProgress size={20} />;
    }
    return (
      <img
        className={"cursor-pointer"}
        onClick={handleOnClick}
        alt="close"
        src={"/icons/download.svg"}
        width={15}
        height={18}
      />
    );
  };
  return <ImageContainer>{renderButton()}</ImageContainer>;
};

export default DownloadButton;
