import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import Colors from "@app/types/colors.enum";

interface LoadingSpinnerProps {
  color: Colors;
  size: number;
}

const StyledCircularProgress = styled(({ color, ...otherProps }) => (
  <CircularProgress {...otherProps} />
))`
  color: ${(props) => props.color};
`;

const LoadingSpinner = ({ color, size }: LoadingSpinnerProps) => {
  return <StyledCircularProgress size={size} color={color} />;
};

export default LoadingSpinner;
