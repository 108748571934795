import { useApi } from "@app/hooks";
import AgentRequest from "@app/types/agent-request";

export const useAgent = () => {
  const { request, loading } = useApi();

  const saveAgent = async (data: AgentRequest): Promise<AgentRequest> => {
    return await request<AgentRequest>({
      url: "records/agent_request",
      method: "POST",
      body: data,
    });
  };

  return { saveAgent, loading };
};
