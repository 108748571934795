import { createAction } from "typesafe-actions";
import { Cluster } from "@app/itineraries/types/interfaces";

const LOAD_CLUSTERS = "[Clusters] Load Clusters";
const SET_SELECTED_CLUSTER = "[Clusters] Set Selected Cluster";

export const LoadClusters = createAction(
  LOAD_CLUSTERS,
  (clusters: Cluster[]) => clusters
)<Cluster[]>();

export const SetSelectedCluster = createAction(
  SET_SELECTED_CLUSTER,
  (cluster: number) => cluster
)<number>();
