import React, { ReactNode } from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import styled from "styled-components";
import { SnackbarIcons } from "@app/itineraries/types/enums/snackbar-icons.enum";
import { useDispatch } from "react-redux";
import { CloseSnackbar } from "@app/store/actions/core.actions";

interface SnackBarProps {
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
  open: boolean;
  onClose: () => void;
  message: string;
  children?: ReactNode;
  icon: SnackbarIcons;
}

const StyledSnackBar = styled(Snackbar)`
  left: 0 !important;
  bottom: 0 !important;

  .MuiSnackbarContent-root {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #0000001a;
    border-radius: 8px 8px 0px 0px;
    padding-top: 20px;
    padding-left: 49px;
    padding-right: 40px;
    text-align: left;
    font: normal normal normal 16px/24px Sofia Pro;
    letter-spacing: 0px;
    color: #33383e;
    height: 65px;
  }
  .MuiSnackbarContent-message {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`;

const MessageSnackBar = ({
  vertical,
  horizontal,
  open,
  onClose,
  message,
  children,
  icon,
}: SnackBarProps) => {
  const dispatch = useDispatch();

  const onHandleClose = () => {
    dispatch(CloseSnackbar());

    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledSnackBar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onHandleClose}
      autoHideDuration={5000}
      message={
        <>
          <img src={icon} alt="checkmark" width={21} height={21} />
          {message}
          {children}
        </>
      }
      action={
        <IconButton onClick={onHandleClose}>
          <img alt="close" src="/icons/close.svg" width={11} height={11} />
        </IconButton>
      }
      key={vertical + horizontal}
    />
  );
};

export default MessageSnackBar;
