import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadingDialog } from "@app/store/selectors/core.selectors";
import { CloseConfirmationDialog } from "@app/store/actions/core.actions";
import LoadingDialog from "@app/components/loading-dialog";

const LoadingDialogManager = () => {
  const loadingDialog = useSelector(selectLoadingDialog);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(CloseConfirmationDialog());
    loadingDialog.onClose();
  };

  return (
    <>
      {loadingDialog && (
        <LoadingDialog
          open={true}
          title={loadingDialog.title}
          message={loadingDialog.message}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default LoadingDialogManager;
