import Button from "@material-ui/core/Button";
import styled from "styled-components";
import React from "react";
import Colors from "@app/types/colors.enum";

interface ButtonProps {
  title: string;
  iconPath: string;
  width: number;
  height: number;
  hoverColor?: Colors;
  iconWidth: number;
  iconHeight: number;
  withoutHoverColor?: boolean;
  type?: string;
  color?: string;
  onClick?: () => void;
  styleClassName?: string;
}

const StyledButton = styled(
  ({ width, height, hoverColor, withoutHoverColor, color, ...otherProps }) => (
    <Button {...otherProps} />
  )
)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  text-transform: none;
  color: ${(props) => (props.color ? props.color : "")};
  img {
    filter: ${(props) =>
      props.color
        ? "invert(100%) sepia(90%) saturate(7422%) hue-rotate(305deg) brightness(105%) contrast(89%)"
        : ""};
  }
  &:hover {
    color: ${(props) => props.hoverColor};
    background-color: ${Colors.WHITE};
    img {
      filter: ${(props) =>
        props.withoutHoverColor
          ? ""
          : "invert(100%) sepia(90%) saturate(7422%) hue-rotate(305deg) brightness(105%) contrast(89%)"};
    }
  }
  .MuiButton-startIcon {
    display: flow-root;
    margin-right: 4px;
    margin-top: -3px;
  }
`;

export const IconButton = ({
  iconPath,
  title,
  width,
  height,
  type,
  hoverColor,
  iconWidth,
  iconHeight,
  styleClassName,
  withoutHoverColor,
  color,
  onClick,
}: ButtonProps) => {
  return (
    <StyledButton
      disableRipple
      type={type ? type : "button"}
      className={`${styleClassName ? styleClassName : "button-text"}`}
      width={width}
      height={height}
      hoverColor={hoverColor}
      onClick={onClick}
      color={color}
      withoutHoverColor={withoutHoverColor}
      startIcon={
        <img
          src={iconPath}
          width={iconWidth}
          height={iconHeight}
          color={Colors.PRIMARY}
          alt="icon"
        />
      }
    >
      {title}
    </StyledButton>
  );
};

export default IconButton;
