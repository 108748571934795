import React from "react";
import { Box, CircularProgress, Tooltip } from "@material-ui/core";
import { Booking } from "@app/bookings/types/classes";

interface DownloadButtonProps {
  booking: Booking;
  downloadPdf: (booking: Booking) => void;
  loading: boolean;
}

const BookingDownloadButton = ({
  booking,
  downloadPdf,
  loading,
}: DownloadButtonProps) => {
  const handleOnClick = () => {
    downloadPdf(booking);
  };

  const renderButton = () => {
    if (loading) {
      return <CircularProgress size={20} />;
    }
    return (
      <img
        className={"cursor-pointer"}
        onClick={handleOnClick}
        alt="close"
        src={"/icons/download.svg"}
        width={15}
        height={18}
      />
    );
  };

  return (
    <Tooltip title={"Download"} placement="top">
      <div>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {renderButton()}
        </Box>
      </div>
    </Tooltip>
  );
};

export default BookingDownloadButton;
