import { useDispatch, useSelector } from "react-redux";
import { useApi } from "@app/hooks";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { AgencyDetails } from "@app/profile/types/interfaces";
import { LoadAgency } from "@app/store/actions/agency.actions";

export const useUpdateAgency = () => {
  const dispatch = useDispatch();
  const { request, loading } = useApi();
  const user = useSelector(selectCurrentUser);

  const updateAgency = async (data: AgencyDetails) => {
    const agencyResponse = await request<AgencyDetails>({
      url: `records/agency/${user.agency}`,
      method: "PATCH",
      body: data,
    });
    dispatch(LoadAgency(agencyResponse));
  };

  const uploadImage = async (formData: FormData) => {
    const agencyResponse = await request<AgencyDetails>({
      url: `records/agency/${user.agency}`,
      method: "PATCH",
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(LoadAgency(agencyResponse));
  };

  return { updateAgency, uploadImage, loading };
};
