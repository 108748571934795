import React from "react";
import { useForm } from "react-hook-form";
import { PrimaryButton, TextInput } from "@app/components";
import { Box, Typography } from "@material-ui/core";

interface Props {
  onReset: (email: string) => void;
  loading: boolean;
}

const ResetPasswordForm = (props: Props) => {
  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
  });

  const onSend = (data: { [key: string]: string }) => {
    const email = data["email"];
    props.onReset(email);
  };

  return (
    <>
      <Box marginBottom={8} marginX={4}>
        <Typography variant={"body2"} align={"center"}>
          Enter the email associated with your account and we’ll send {"\n"} an
          email with instructions to reset your password.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSend)}>
        <TextInput
          required={true}
          name="email"
          type="email"
          label="Email"
          control={control}
          rules={{
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: "Invalid email address",
            },
          }}
        />
        <Box marginTop={4} display={"flex"} justifyContent={"center"}>
          <PrimaryButton loading={props.loading} title="Send" />
        </Box>
      </form>
    </>
  );
};

export default ResetPasswordForm;
