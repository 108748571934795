import { useApi } from "@app/hooks";
import { QuoteDayAction } from "@app/itineraries/types/classes";

export const useQuoteDayAction = () => {
  const { request } = useApi();

  const bulkCreateQuoteDayActions = async (
    quoteDayActions: QuoteDayAction[]
  ) => {
    return await request<QuoteDayAction[]>({
      url: `records/quote_day_action/bulk`,
      method: "POST",
      body: quoteDayActions,
    });
  };

  return { bulkCreateQuoteDayActions };
};
