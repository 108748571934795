import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@material-ui/core";

const PasswordInput = ({
  error,
  helperText,
  onBlur,
  value,
  onChange,
  ...others
}: TextFieldProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <TextField
      className="full-width text-field"
      label={others.label}
      type={visible ? "text" : "password"}
      variant="outlined"
      error={error}
      onBlur={onBlur}
      value={value}
      helperText={helperText}
      onChange={onChange}
      InputProps={{
        // autoComplete: 'new-password',
        endAdornment: (
          <InputAdornment position="end" onClick={() => setVisible(!visible)}>
            <IconButton aria-label="delete">
              <img
                src={
                  visible
                    ? "/icons/visibility_on.svg"
                    : "/icons/visibility_off.svg"
                }
                width={24}
                height={24}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
