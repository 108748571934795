import { AppState } from "@app/store/reducers";
import { CoreState } from "@app/store/reducers/core.reducer";
import { createSelector } from "reselect";

const getCoreState = (state: AppState): CoreState => state.core;

export const selectSnackbars = createSelector(
  getCoreState,
  (state) => state.snackbar
);
export const selectConfirmationDialog = createSelector(
  getCoreState,
  (state) => {
    return state.confirmationDialog;
  }
);
export const selectLoadingDialog = createSelector(
  getCoreState,
  (state) => state.loadingDialog
);
