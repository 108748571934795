import React from "react";
import { Button } from "@app/components/index";
import Colors from "@app/types/colors.enum";

interface CancelButtonProps {
  onClose: () => void;
}

const CancelButton = ({ onClose }: CancelButtonProps) => (
  <Button
    type="button"
    title="Cancel"
    width={182}
    height={43}
    color={Colors.WHITE}
    hoverColor={Colors.LIGHT_GRAY}
    withBorder={true}
    borderColor={Colors.LIGHT_GRAY}
    textColor={Colors.BLACK}
    onClick={onClose}
  />
);

export default CancelButton;
