import { useApi } from "@app/hooks";
import { LoadItinerary } from "@app/store/actions/itineraries.actions";
import { useDispatch, useSelector } from "react-redux";
import { Itinerary } from "@app/itineraries/types/interfaces";
import { useEffect } from "react";
import { selectPrePostTourById } from "@app/store/selectors/pre-post-tours.selectors";
import { LoadPrePostTour } from "@app/store/actions/pre-post-tours.actions";

export const usePrePostTour = (
  id: number
): { prePostTour: Itinerary; loading: boolean } => {
  const { request, loading } = useApi();
  const prePostTour = useSelector(selectPrePostTourById(id));
  const dispatch = useDispatch();

  useEffect(() => {
    // If we already have the itinerary in store, don't load again
    if (prePostTour && prePostTour.loaded) {
      dispatch(LoadPrePostTour(prePostTour));
      return;
    }

    getPrePostTour(id);
  }, []);

  const getPrePostTour = async (id: number) => {
    const filters = {
      active__eq: true,
    };
    const fields =
      "id,name,clusters.cluster.price,description,clusters.order,clusters.cluster.days,clusters.cluster.days.order,clusters.cluster.days.day.name,clusters.cluster.days.day.images.file,clusters.cluster.days.day.images.id,clusters.cluster.days.day.actions.name,clusters.cluster.days.day.actions.description,clusters.cluster.days.day.actions.image.file,clusters.cluster.days.day.actions.image.id,images.file,video,cover_image.file,inclusions,clusters.cluster.days.day.summary," +
      "clusters.cluster.days.day.top_description,clusters.cluster.days.day.bottom_description,type,images.id";

    const itinerary = await request<Itinerary>({
      url: `records/itinerary/${id}`,
      method: "GET",
      queryParams: {
        ...filters,
        datafy_fields: fields,
        pageSize: 30,
      },
    });

    dispatch(LoadPrePostTour(itinerary));
  };

  return { prePostTour: prePostTour?.loaded ? prePostTour : null, loading };
};
