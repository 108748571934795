import { Booking, Passenger, QuoteDay } from "@app/itineraries/types/classes";
import {
  ClusterDay,
  CustomItinerary,
  Itinerary,
  ItineraryCluster,
} from "@app/itineraries/types/interfaces";
import { Image } from "@app/types/image";
import { SaveQuote } from "@app/quotes/types/interfaces";
import { useBookings } from "./bookings.hook";

export const useBookingSubmit = () => {
  const { addBooking } = useBookings();

  const submitBooking = async (
    booking: Booking,
    passengers: Passenger[],
    useCustomItinerary: boolean,
    quote: SaveQuote,
    itinerary: Itinerary | CustomItinerary,
    customItineraryGeneralInfos: Itinerary
  ) => {
    booking.insuranceCommission = parseFloat(
      booking.insuranceCommission.toFixed(2)
    );
    booking.itineraryCommission = parseFloat(
      booking.itineraryCommission.toFixed(2)
    );
    booking.insurancePrice = parseFloat(booking.insurancePrice.toFixed(2));
    booking.itineraryPrice = parseFloat(booking.itineraryPrice.toFixed(2));
    if (useCustomItinerary || quote) {
      booking.lookingFor = useCustomItinerary
        ? (itinerary as CustomItinerary).lookingFor
        : quote.lookingFor;
      booking.timeOfTravel = useCustomItinerary
        ? (itinerary as CustomItinerary).timeOfTravel
        : quote.timeOfTravel;
      booking.lengthOfStay = useCustomItinerary
        ? (itinerary as CustomItinerary).lengthOfStay
        : quote.lengthOfStay;
    }
    if (!useCustomItinerary || quote) {
      booking.inclusions = quote
        ? quote?.inclusions
        : (itinerary as Itinerary).inclusions;
    }
    if (quote) {
      booking.quoteName = quote.name;
    }

    if (quote && quote.itinerary) {
      booking.itineraryDescription = (quote.itinerary as Itinerary).description;
      booking.itineraryImages = (quote.itinerary as Itinerary).images.map(
        (image: Image) => image.id
      );
    } else if (
      (itinerary as Itinerary)?.description &&
      (itinerary as Itinerary)?.images
    ) {
      booking.itineraryDescription = (itinerary as Itinerary).description;
      booking.itineraryImages = (itinerary as Itinerary).images.map(
        (image: Image) => image.id
      );
    } else {
      booking.itineraryDescription = customItineraryGeneralInfos.description;
      booking.itineraryImages = customItineraryGeneralInfos.images.map(
        (image: Image) => image.id
      );
    }
    const _booking = {
      ...booking,
      itineraryName: quote ? quote.itineraryName : itinerary.name,
    };
    const _passengers = passengers.map((passenger: Passenger) => {
      return {
        ...passenger,
        birthday: new Date(passenger.birthday).toISOString().substring(0, 10),
      };
    });
    const days = quote ? getQuoteDays(quote) : getDays(itinerary);

    const bookingDays = [];
    for (const bookingDay of days) {
      const _bookingDay = {
        ...bookingDay,
        actions: bookingDay[quote ? "quoteDayActions" : "actions"].map(
          (action) => {
            return {
              ...action,
              name: action.name,
              image: action.image.id,
            };
          }
        ),
      };
      bookingDays.push(_bookingDay);
    }
    return await addBooking({
      booking: _booking,
      passengers: _passengers,
      days: bookingDays,
    });
  };

  const getQuoteDays = (quote: SaveQuote) => {
    return quote.quoteDays.map((quoteDay: QuoteDay) => {
      return {
        ...quoteDay,
        images: (quoteDay.images as Image[]).map((image: Image) => image.id),
      };
    });
  };

  const getDays = (itinerary: Itinerary | CustomItinerary) => {
    const days: ClusterDay[] = itinerary?.clusters
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .reduce((allDays: ClusterDay[], cluster: ItineraryCluster) => {
        if (cluster?.cluster?.days) {
          allDays.push(
            ...cluster.cluster.days.sort((a, b) => (a.order > b.order ? 1 : -1))
          );
        }
        return allDays;
      }, []);
    return days?.map((day: ClusterDay, index) => {
      return {
        ...day.day,
        images: day.day.images.map((image: Image) => image.id),
        order: index + 1,
      };
    });
  };

  return { submitBooking, getQuoteDays, getDays };
};
