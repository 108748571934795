import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Button } from "@app/components";
import Colors from "@app/types/colors.enum";
import { useForm, Controller } from "react-hook-form";

interface FormProps {
  onClose: () => void;
  logIn: (email: string, password: string) => void;
  resetPassword: () => void;
  loading: boolean;
}

interface LoginFormData {
  email: string;
  password: string;
}

const LoginForm = ({ onClose, logIn, loading, resetPassword }: FormProps) => {
  const { control, handleSubmit } = useForm({
    mode: "onTouched",
  });
  const [visible, setVisible] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(null);

  const onLogin = async (data: LoginFormData) => {
    try {
      await logIn(data.email, data.password);
      onClose();
    } catch (err) {
      setInvalidCredentials("Incorrect email or password");
    }
  };

  const onResetPassword = () => resetPassword();

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <TextField
              className="full-width text-field"
              label="Email"
              type="email"
              variant="outlined"
              error={!!error}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              helperText={error ? error.message : null}
            />
          )}
          rules={{
            required: "Email is required.",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: "Invalid email address",
            },
          }}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <TextField
              className="full-width text-field margin-top-40"
              label="Password"
              type={visible ? "text" : "password"}
              variant="outlined"
              error={!!error}
              onBlur={onBlur}
              value={value}
              helperText={error ? error.message : null}
              onChange={onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setVisible(!visible)}
                  >
                    <IconButton aria-label="delete">
                      <img
                        src={
                          visible
                            ? "/icons/visibility_on.svg"
                            : "/icons/visibility_off.svg"
                        }
                        width={24}
                        height={24}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          rules={{
            required: "Password is required",
          }}
        />
        {invalidCredentials && (
          <Box
            textAlign={"center"}
            marginLeft={"14px"}
            marginTop={"3px"}
            className={"text-color-red"}
          >
            {invalidCredentials}
          </Box>
        )}
        <Box width={"100%"} marginTop={2} marginBottom={6}>
          <Typography
            align={"right"}
            variant={"body1"}
            className="cursor-pointer tex-color-dark-gray"
            onClick={onResetPassword}
          >
            Forgot your password?
          </Typography>
        </Box>
        <Button
          type="submit"
          title="Log in"
          width={180}
          height={43}
          color={Colors.PRIMARY}
          hoverColor={Colors.HOVER_PRIMARY}
          textColor={Colors.WHITE}
          withBorder={false}
          loading={loading}
        />
      </Box>
    </form>
  );
};

export default LoginForm;
