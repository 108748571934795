import React, { useImperativeHandle, useState } from "react";
import { Dialog } from "@app/components";
import { Box, Typography } from "@material-ui/core";
import { PersonalizeBookingDialogRef } from "@app/bookings/types/refs";
import { PersonalizeFormData } from "@app/bookings/types/interfaces";
import { PersonalizeBookingForm } from "@app/bookings/components";

interface PersonalizeBookingDialogProps {
  personalizeBooking: (
    id: number,
    bookingPersonalize: PersonalizeFormData
  ) => void;
  loading: boolean;
}

const PersonalizeBookingDialog = React.forwardRef<
  PersonalizeBookingDialogRef,
  PersonalizeBookingDialogProps
>(({ personalizeBooking, loading }: PersonalizeBookingDialogProps, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [booking, setBooking] = useState<number>(null);

  useImperativeHandle(
    ref,
    (): PersonalizeBookingDialogRef => ({
      onOpen: (bookingId) => onOpen(bookingId),
    })
  );

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = (bookingId: number) => {
    setBooking(bookingId);
    setOpen(true);
  };

  const onSubmit = async (personalizedBooking: PersonalizeFormData) => {
    await personalizeBooking(booking, personalizedBooking);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Personalize itinerary"
      titleVariant={"h1"}
    >
      <Box
        width={600}
        margin={4}
        className="gap-l"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant={"body1"} align={"center"}>
          Help us provide the best experience to your clients
        </Typography>
        <Box>
          <PersonalizeBookingForm
            loading={loading}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        </Box>
      </Box>
    </Dialog>
  );
});

PersonalizeBookingDialog.displayName = "PersonalizeBookingDialog";

export default PersonalizeBookingDialog;
