import React, { ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";

interface PageContainer {
  title?: string;
  children?: ReactNode;
  renderHeader?: () => ReactNode;
}

const PageContainer = ({ title, children, ...props }: PageContainer) => {
  const renderTitle = () => {
    if (title) {
      return <Typography component="h1">{title}</Typography>;
    }

    if (props.renderHeader) {
      return props.renderHeader();
    }

    return null;
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      className={"page-min-height"}
      paddingBottom={6}
    >
      <Box paddingY={6}>{renderTitle()}</Box>
      {children}
    </Box>
  );
};

export default PageContainer;
