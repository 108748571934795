import React from "react";
import { Quote } from "@app/quotes/types/classes";
import { Box, CircularProgress, Tooltip } from "@material-ui/core";

interface DownloadButtonProps {
  quote: Quote;
  downloadPdf: (quote: Quote) => void;
  loading: boolean;
}

const QuoteDownloadButton = ({
  quote,
  downloadPdf,
  loading,
}: DownloadButtonProps) => {
  const handleOnClick = () => {
    downloadPdf(quote);
  };

  const renderButton = () => {
    if (loading) {
      return <CircularProgress size={20} />;
    }
    return (
      <img
        className={"cursor-pointer"}
        onClick={handleOnClick}
        alt="close"
        src={"/icons/download.svg"}
        width={15}
        height={18}
      />
    );
  };

  return (
    <Tooltip title={"Download"} placement="top">
      <div>
        <Box display={"flex"} alignContent={"center"} justifyContent={"center"}>
          {renderButton()}
        </Box>
      </div>
    </Tooltip>
  );
};

export default QuoteDownloadButton;
