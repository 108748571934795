import { Control, Controller, FieldValues } from "react-hook-form";
import { createStyles, TextField, Theme, WithStyles } from "@material-ui/core";
import React from "react";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { FieldPath } from "react-hook-form/dist/types";
import withStyles from "@material-ui/core/styles/withStyles";

interface TextInputProps extends WithStyles<typeof styles> {
  control: Control<FieldValues>;
  name: string;
  label: string;
  type: string;
  required: boolean;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  variant?: "outlined" | "standard" | "filled";
}

const styles = (theme: Theme) =>
  createStyles({
    input: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  });

export const TextInput = withStyles(styles)(
  ({
    variant = "outlined",
    control,
    name,
    label,
    type,
    required,
    rules = {},
    classes,
  }: TextInputProps) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <TextField
            className={`full-width ${classes.input}`}
            label={label}
            type={type}
            variant={variant}
            required={required}
            error={!!error}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            helperText={error ? error.message : null}
          />
        )}
        rules={
          required
            ? {
                ...rules,
                required: "This filed is required.",
              }
            : rules
        }
      />
    );
  }
);

export default TextInput;
