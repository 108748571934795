import { useForm } from "react-hook-form";
import {
  TextInput,
  CancelButton,
  PrimaryButton,
  RadioInput,
  DatePicker,
} from "@app/components";
import React from "react";
import { Box, FormLabel } from "@material-ui/core";
import { CheckboxInput } from "@app/components/";
import {
  PersonalizeFormData,
  PersonalizeFormRaw,
} from "@app/bookings/types/interfaces";

interface PersonalizeBookingFormProps {
  onSubmit: (data: PersonalizeFormData) => void;
  onClose: () => void;
  loading: boolean;
}

const PersonalizeBookingForm = ({
  onSubmit,
  onClose,
  loading,
}: PersonalizeBookingFormProps) => {
  const { control, handleSubmit, getValues } = useForm({
    mode: "onTouched",
  });

  const simpleOptions = ["Yes", "No"];
  const activities = ["Active", "Moderate", "Minimal"];
  const dietaryRestrictions = [
    "None",
    "Vegetarian",
    "Gluten free",
    "Dairy intolerant",
    "Other",
  ];
  const meats = ["Pork", "Beef", "Lamb", "Goat", "Poultry", "Fish"];
  const nightlife = [
    "Yes - but mostly local bars",
    "Yes - party all night, clubs",
    "No, not at all",
  ];
  const drinks = ["Beer", "Wine", "Local spirits"];
  const interests = [
    "Architecture",
    "Art",
    "Music",
    "Traditions",
    "Mythology",
    "History",
    "Other",
  ];

  const onSubmitForm = (rawData: PersonalizeFormRaw) => {
    const data: PersonalizeFormData = {
      ...rawData,
      celebrationDate: rawData?.celebrationDate
        ?.toISOString()
        ?.substring(0, 10),
    };
    onSubmit(data);
  };

  const validateCelebration = (value) => {
    if (getValues("celebration") === "Yes" && !value)
      return "This field is required.";
    return null;
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box display={"flex"} flexDirection={"column"} className="gap-xl">
        <RadioInput
          name="mobilityIssue"
          label="1. Mobility issues:"
          control={control}
          options={simpleOptions}
          defaultValue={simpleOptions[1]}
        />
        <RadioInput
          name="activityLevel"
          label="2. Activity level:"
          control={control}
          options={activities}
        />
        <Box display={"flex"} flexDirection={"column"} className="gap-s">
          <CheckboxInput
            name="dietaryRestrictions"
            label="3. Dietary restrictions"
            control={control}
            options={dietaryRestrictions}
          />
          <TextInput
            control={control}
            name="dietaryComments"
            label="Write your comment here..."
            type="text"
            required={false}
          />
        </Box>
        <CheckboxInput
          name="meat"
          label="4. Romania’s cuisine incorporates a lot of meat. Do you eat (check all that applies)?"
          control={control}
          options={meats}
          displayAs={"column"}
        />
        <Box display={"flex"} flexDirection={"column"} className="gap-s">
          <RadioInput
            name="allergies"
            label="5. Any allergies?"
            control={control}
            options={simpleOptions}
            defaultValue={simpleOptions[1]}
          />
          <TextInput
            control={control}
            name="allergyComments"
            label="Write your comment here..."
            type="text"
            required={false}
          />
        </Box>
        <Box display={"flex"} flexDirection={"column"} className="gap-s">
          <RadioInput
            name="celebration"
            label="6. Celebrating something?"
            control={control}
            options={simpleOptions}
          />
          <TextInput
            control={control}
            name="celebrationComments"
            label="Occasion"
            type="text"
            required={false}
            rules={{
              validate: validateCelebration,
            }}
          />
          <DatePicker
            control={control}
            name="celebrationDate"
            label="Date"
            required={false}
            disablePast={false}
            rules={{
              validate: validateCelebration,
            }}
          />
        </Box>
        <CheckboxInput
          name="drinks"
          label="7. In case of drinking tours do you prefer:"
          control={control}
          options={drinks}
          displayAs={"column"}
        />
        <Box display={"flex"} flexDirection={"column"} className="gap-s">
          <CheckboxInput
            name="sightseeing"
            label="8. We may be able to personalize your sightseeing tour based on your interest:"
            control={control}
            options={interests}
            displayAs={"column"}
          />
          <TextInput
            control={control}
            name="sightseeingComments"
            label="Write your comment here..."
            type="text"
            required={false}
          />
        </Box>
        <RadioInput
          name="nightlife"
          label="9. Are you interested in nightlife:"
          control={control}
          options={nightlife}
          displayAs={"column"}
        />
        <Box display={"flex"} flexDirection={"column"} className="gap-s">
          <FormLabel className={"text-color-black"}>
            10. Additional comments
          </FormLabel>
          <TextInput
            control={control}
            name="additionalComments"
            label="Write your comment here..."
            type="text"
            required={false}
          />
        </Box>
        <Box
          className="gap-l"
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
        >
          <CancelButton onClose={onClose} />
          <PrimaryButton loading={loading} title="Submit" />
        </Box>
      </Box>
    </form>
  );
};

export default PersonalizeBookingForm;
