import { getType } from "typesafe-actions";
import {
  Cluster,
  CustomItinerary,
  Insurance,
  Itinerary,
} from "@app/itineraries/types/interfaces";
import { LoadInsurances } from "@app/store/actions/insurances.actions";

export interface InsurancesState {
  insurances: { [id: number]: Insurance };
}

const initialState: InsurancesState = {
  insurances: {},
};

export default (state = initialState, action): InsurancesState => {
  switch (action.type) {
    case getType(LoadInsurances): {
      const insurances = action.payload.reduce(
        (result, insurance: Insurance) => {
          result[insurance.id] = insurance;
          return result;
        },
        {}
      );
      return { ...state, insurances: { ...insurances } };
    }

    default:
      return state;
  }
};
