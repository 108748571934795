import React from "react";
import Table from "@app/components/table";
import Columns from "@app/types/columns";
import {
  QuoteDownloadButton,
  QuotesPlaceholder,
} from "@app/quotes/components/";
import { User } from "@app/types/user";
import { Quote } from "@app/quotes/types/classes";
import { Button } from "@app/components";
import Colors from "@app/types/colors.enum";

interface TableProps {
  user: User;
  downloadPDF: (quoteId: Quote) => void;
  bookQuote: (quoteId: number) => void;
  loading: boolean;
  data: Quote[];
  pageSize: number;
  page: number;
  size: number;
  loadMore: () => void;
  loadPrevious: () => void;
}

const QuotesTable = ({
  user,
  downloadPDF,
  bookQuote,
  loading,
  data,
  pageSize,
  size,
  page,
  loadMore,
  loadPrevious,
}: TableProps) => {
  let columns: Columns[] = [
    {
      cell: (data: Quote) => data.name,
      name: "Quote name",
      style: { width: "17%" },
      align: "center",
      key: "name",
    },
    {
      cell: (data: Quote) =>
        new Date(data.date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }),
      name: "Quote date",
      style: { width: "10%" },
      align: "center",
      key: "date",
    },
    {
      cell: (data: Quote) => {
        return (
          <QuoteDownloadButton
            quote={data}
            downloadPdf={downloadPDF}
            loading={data.isDownloading}
          />
        );
      },
      name: "PDF",
      style: { paddingLeft: user.masterAccount ? "40%" : "52%" },
      align: "center",
      key: "pdf",
    },
    {
      cell: (data: Quote) => {
        return (
          <Button
            title="Book"
            width={150}
            height={29}
            color={Colors.PRIMARY}
            hoverColor={Colors.HOVER_PRIMARY}
            withBorder={false}
            size={"12"}
            textColor={Colors.WHITE}
            onClick={() => bookQuote(data.id)}
          />
        );
      },
      name: "",
      style: { width: "15%" },
      align: "center",
      key: "bookingButton",
    },
  ];

  if (user?.masterAccount) {
    columns = [
      {
        cell: (data: Quote) => data.agentName,
        name: "Agent name",
        style: { width: "12%" },
        align: "center",
        key: "agentName",
      },
      ...columns,
    ];
  }

  return (
    <Table
      data={data}
      loading={loading}
      page={page}
      size={size}
      loadMore={loadMore}
      loadPrevious={loadPrevious}
      pageSize={pageSize}
      columns={columns}
      paginatorText={"quotes"}
      emptyText={"There are no quotes!"}
      TablePlaceholder={QuotesPlaceholder}
    />
  );
};

export default QuotesTable;
