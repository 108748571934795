import { useApi } from "@app/hooks";
import { BookingDay } from "@app/itineraries/types/classes/booking-day.class";
import { QuoteDay } from "@app/itineraries/types/classes";

export const useQuoteDay = () => {
  const { request } = useApi();

  const addQuoteDay = async (data: QuoteDay) => {
    return await request<BookingDay>({
      url: `records/quote_day`,
      method: "POST",
      body: data,
    });
  };

  return { addQuoteDay };
};
