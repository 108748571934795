import { useApi } from "@app/hooks";
import { PaginatedResponse } from "@app/types";
import { Insurance } from "@app/itineraries/types/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectInsurances } from "@app/store/selectors/insurances.selectors";
import { useEffect } from "react";
import { LoadInsurances } from "@app/store/actions/insurances.actions";

export const useInsurance = () => {
  const { request, loading } = useApi();
  const insurances: Insurance[] = useSelector(selectInsurances);
  const dispatch = useDispatch();

  useEffect(() => {
    if (insurances.length !== 0 || loading) {
      return;
    }
    getInsurances();
  }, []);

  const getInsurances = async () => {
    const data = await request<PaginatedResponse<Insurance>>({
      url: "records/insurance",
      method: "GET",
      queryParams: {
        datafy_fields: "id,standard_percentage,cfar_percentage,age",
        pageSize: 50,
      },
    });
    dispatch(LoadInsurances(data.results));
  };

  return { insurances, loading };
};
