import { Crop } from "react-image-crop";

export const downloadFile = (response, name) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const readFile = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(<string>reader.result),
      false
    );
    reader.readAsDataURL(file);
  });
};

export const getCroppedImage = async (
  currentImg: any,
  imageSrc: string,
  pixelCrop: Crop,
  rotation = 0
): Promise<Blob> => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const scaleX = currentImg.naturalWidth / currentImg.width;
  const scaleY = currentImg.naturalHeight / currentImg.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = pixelCrop.width * pixelRatio * scaleX;
  canvas.height = pixelCrop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    currentImg,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
    0,
    0,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY
  );

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    });
  });
};
