import { useApi } from "@app/hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { User } from "@app/types/user";
import { UpdateUser } from "@app/store/actions/auth.actions";

export const useChangePassword = (): {
  loading: boolean;
  changePassword: (password: string) => Promise<void>;
} => {
  const { request, loading } = useApi();
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const changePassword = async (password: string): Promise<void> => {
    await request({
      url: "auth/changePassword",
      method: "POST",
      body: { password },
    });

    const _user = await request<User>({
      url: `records/users/${user.id}`,
      method: "PATCH",
      body: { first_login: false },
    });

    dispatch(UpdateUser(_user));
  };

  return { loading, changePassword };
};
