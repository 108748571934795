import styled from "styled-components";
import React from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "@app/hooks/use-query.hook";

const Nav = styled.nav`
  padding-top: 6px;
  display: flex;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
  }

  ,
  a ~ a {
    margin-left: 80px;

    @media (max-width: 1600px) {
      margin-left: 50px;
    }
  }
`;
const Navbar = () => {
  const { pathname } = useQuery();

  return (
    <Nav>
      <NavLink to="/itineraries" exact className="button-text">
        <div
          className={`${
            pathname === "/itineraries"
              ? "text-color-white"
              : pathname.includes("/itineraries")
              ? "text-color-primary"
              : ""
          }`}
        >
          Itineraries
        </div>
        <div
          className={`${
            pathname === "/itineraries"
              ? "circle"
              : pathname.includes("/itineraries")
              ? "primary-circle"
              : ""
          }`}
        />
      </NavLink>
      <NavLink to="/pre-post-tours" exact className="button-text">
        <div
          className={`${
            pathname.includes("/pre-post-tours") ? "text-color-primary" : ""
          }`}
        >
          Pre & Post Tours
        </div>
        <div
          className={`${
            pathname.includes("/pre-post-tours") ? "primary-circle" : ""
          }`}
        />
      </NavLink>
      <NavLink className="button-text" to="/bookings" exact>
        <div
          className={`${pathname === "/bookings" ? "text-color-primary" : ""}`}
        >
          Bookings
        </div>
        <div
          className={`${pathname === "/bookings" ? "primary-circle" : ""}`}
        />
      </NavLink>

      <NavLink className="button-text" to="/quotes" exact>
        <div
          className={`${pathname === "/quotes" ? "text-color-primary" : ""}`}
        >
          Quotes
        </div>
        <div className={`${pathname === "/quotes" ? "primary-circle" : ""}`} />
      </NavLink>
    </Nav>
  );
};

export default Navbar;
