import { useApi, usePagination } from "@app/hooks";
import { RawQuote } from "@app/quotes/types/interfaces";
import { Quote } from "@app/quotes/types/classes";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { PaginatedResponse } from "@app/types";
import { useState } from "react";
import { useDownloadQuoteHook } from "@app/quotes/hooks/download-quote-hook";

export const useQuotes = (pageSize = 15) => {
  const { request, loading } = useApi();
  const user = useSelector(selectCurrentUser);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const { getQuotePdf } = useDownloadQuoteHook(quotes, setQuotes);

  const getQuotes = async (
    page: number,
    pageSize: number
  ): Promise<PaginatedResponse<RawQuote | Quote>> => {
    const defaultFields = "id,created_at,name";
    const masterFields = user.masterAccount
      ? ",agent.first_name,agent.last_name"
      : "";

    const fields = defaultFields + masterFields;
    const filters = user.masterAccount
      ? { agency__eq: user.agency }
      : { agent__eq: user.id };
    const response = await request<PaginatedResponse<RawQuote | Quote>>({
      url: "records/quote",
      method: "GET",
      queryParams: {
        ...filters,
        datafy_fields: fields,
        pageSize: pageSize,
        page: page,
      },
    });
    response.results = response.results.map(
      (data: RawQuote) => new Quote(data)
    );

    return response;
  };

  const { page, size, loadMore, loadPrevious } = usePagination(
    getQuotes,
    setQuotes,
    pageSize
  );

  return {
    quotes,
    page,
    size,
    loadMore,
    loadPrevious,
    loading,
    getQuotePdf,
  };
};
