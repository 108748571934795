import React, { useRef } from "react";
import {
  Itinerary,
  ItineraryCluster,
  ItineraryTab,
} from "@app/itineraries/types/interfaces";
import { Box, Typography } from "@material-ui/core";
import { BackButton, TermsAndConditions } from "@app/components";
import {
  ItineraryButtons,
  ItineraryDays,
  ItineraryDescription,
  ItineraryInclusions,
  ItinerarySummary,
  ItineraryTabs,
} from "@app/itineraries/components/index";
import IconText from "@app/components/icon-text";
import QuoteDialog from "@app/itineraries/containers/quote-dialog";
import ItineraryBookingDialog from "@app/itineraries/containers/itinerary-booking-dialog";
import {
  ItineraryBookingDialogRef,
  QuoteDialogRef,
} from "@app/itineraries/types/refs";
import { useDownloadPdf } from "@app/itineraries/hooks/download-pdf.hook";
import { useGeneralInfosItinerary, useSnackbars } from "@app/hooks";
import { useSelector } from "react-redux";
import {
  selectCustomItineraryDays,
  selectItineraryDays,
} from "@app/store/selectors/itineraries.selectors";
import { selectPrePostTourDays } from "@app/store/selectors/pre-post-tours.selectors";

interface Props {
  itinerary: Itinerary;
  onBack?: () => void;
  backButtonTitle?: string;
  customItinerary?: boolean;
}

const ItineraryDetails = ({
  itinerary,
  onBack,
  backButtonTitle = "Back to itineraries",
  customItinerary = false,
}: Props) => {
  const downloadPdf = useDownloadPdf();
  const { openError } = useSnackbars();
  const itineraryBookingDialogRef = useRef<ItineraryBookingDialogRef>(null);
  const quoteDialogRef = useRef<QuoteDialogRef>(null);
  const { generalInfosItinerary } = useGeneralInfosItinerary();
  const days = customItinerary
    ? useSelector(selectCustomItineraryDays)
    : itinerary.type
    ? useSelector(selectPrePostTourDays(+itinerary.id))
    : useSelector(selectItineraryDays(+itinerary.id));

  const onDownloadPdf = async () => {
    try {
      customItinerary
        ? await downloadPdf.getCustomItineraryPdf(itinerary)
        : await downloadPdf.getItineraryPdf(itinerary);
    } catch (e) {
      openError({
        message: "Something went wrong. Please try again!",
      });
    }
  };

  const onOpenBookDialog = () => {
    itineraryBookingDialogRef.current.onOpen(
      customItinerary,
      null,
      !!itinerary.type
    );
  };

  const onOpenQuoteDialog = () => {
    quoteDialogRef.current.onOpen(customItinerary, !!itinerary.type);
  };

  const getClusters = () => {
    return itinerary.clusters
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map((cluster: ItineraryCluster) => cluster.cluster);
  };

  const itinerariesTabs = (): ItineraryTab[] => {
    const tabs = [
      {
        label: "Description",
        component: <ItineraryDescription itinerary={itinerary} />,
      },
      {
        label: "Itinerary",
        component: <ItineraryDays clusters={getClusters()} />,
      },
      {
        label: "Summary",
        component: <ItinerarySummary clusters={getClusters()} />,
      },
      {
        label: "Terms and conditions",
        component: (
          <TermsAndConditions generalInfosItinerary={generalInfosItinerary} />
        ),
      },
    ];
    if (!customItinerary) {
      tabs.splice(1, 0, {
        label: "Inclusions",
        component: <ItineraryInclusions itinerary={itinerary} />,
      });
    }
    return tabs;
  };

  return (
    <>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"start"}
        marginBottom={1}
      >
        <BackButton title={backButtonTitle} onClick={onBack} />
        <ItineraryButtons
          loading={downloadPdf.loading}
          onOpenBookDialog={onOpenBookDialog}
          onDownloadPdf={onDownloadPdf}
          onOpen={onOpenQuoteDialog}
        />
      </Box>
      <Typography variant={"h1"}>{itinerary?.name}</Typography>
      <Box
        paddingTop={"4px"}
        className={"gap-sm"}
        display={"flex"}
        flexDirection={"row"}
        marginBottom={3}
      >
        {itinerary.type && (
          <Box
            bgcolor="#f8f9fc"
            borderRadius={"8px"}
            width={"128px"}
            height={"43px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              className={"bold"}
              variant={"body1"}
              color={"secondary"}
            >
              {itinerary.type}
            </Typography>
          </Box>
        )}
        <Box
          textAlign={"left"}
          letterSpacing={"0px"}
          color={"#000"}
          display={"flex"}
          alignItems={"center"}
        >
          <IconText
            variant={"h4"}
            icon={"/icons/clock.svg"}
            text={`${days} ${days === 1 ? "day" : "days"}/${days - 1} ${
              days - 1 === 1 ? "night" : "nights"
            }`}
          />
        </Box>
      </Box>
      <ItineraryTabs tabs={itinerariesTabs()} />
      <QuoteDialog ref={quoteDialogRef} />
      <ItineraryBookingDialog ref={itineraryBookingDialogRef} />
    </>
  );
};

export default ItineraryDetails;
