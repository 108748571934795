import { Box } from "@material-ui/core";
import React, { useImperativeHandle, useState } from "react";
import RegisterAgencyForm from "@app/itineraries/components/register-agency-form";
import { Dialog } from "@app/components";
import { RegisterAgencyRef } from "@app/itineraries/types/refs";
import { useAgency } from "@app/modules/auth/hooks";
import { useConfirmationDialog } from "@app/hooks/confirmation-dialog.hook";
import { useDispatch } from "react-redux";
import * as authActions from "@app/store/actions/auth.actions";
import { useSendEmailNotification } from "@app/itineraries/hooks";

export const RegisterAgencyDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const { saveAgency, loading } = useAgency();
  const { openConfirmationDialog } = useConfirmationDialog();
  const { sendNewAgencyEmail } = useSendEmailNotification();
  const [error, setError] = useState<string>("");

  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    (): RegisterAgencyRef => ({
      onOpen: () => onOpen(),
    })
  );

  const onClose = () => setOpen(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onOpenConfirmationDialog = () => {
    openConfirmationDialog({
      title: "Request submitted!",
      message:
        "Thank you. Our team will check your request and get back to you as soon as possible.",
    });
  };

  const onSubmit = async (data: FormData) => {
    try {
      const agency = await saveAgency(data);
      sendNewAgencyEmail(agency);
      onClose();
      onOpenConfirmationDialog();
    } catch (err) {
      setError(err?.data?.message);
    }
  };

  const onLogIn = () => {
    onClose();
    dispatch(authActions.OpenLoginDialog());
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={"Travel Agency Registration"}
      titleVariant={"h1"}
    >
      <Box width={"636px"}>
        <Box
          textAlign={"center"}
          className=" body-text-1 cursor-pointer text-color-black"
          onClick={onLogIn}
        >
          Already have an agency account?{" "}
          <span className="button-text text-color-primary">Log in</span>
        </Box>
        <Box
          textAlign={"center"}
          marginTop={6}
          marginBottom={4}
          className="body-text-1 text-color-black"
        >
          W-9 Information and User agreement are required in order to complete
          your agency’s registration. Please complete the documents{" "}
          <a
            className="body-text-1 without-decoration"
            href="https://storage.googleapis.com/romania-adventures-development/user-agreement/Documents.zip"
            download
          >
            (Downloadable from{" "}
            <span className="text-color-primary button-text">here</span>)
          </a>{" "}
          and attach them to this form registration.
        </Box>
        <Box paddingX={2}>
          <RegisterAgencyForm
            onClose={onClose}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
        {error && (
          <Box paddingTop={2} textAlign={"center"} className={"text-color-red"}>
            {error?.charAt(0)?.toUpperCase() + error?.slice(1)}
          </Box>
        )}
      </Box>
    </Dialog>
  );
});

RegisterAgencyDialog.displayName = "RegisterAgencyDialog";

export default RegisterAgencyDialog;
