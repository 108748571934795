import React, { useRef } from "react";
import { Layout } from "@app/components";
import { useSnackbars } from "@app/hooks";
import { QuotesTable } from "@app/quotes/components/";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { Quote } from "@app/quotes/types/classes";
import PageContainer from "@app/components/page-container";
import ItineraryBookingDialog from "@app/itineraries/containers/itinerary-booking-dialog";
import { ItineraryBookingDialogRef } from "@app/itineraries/types/refs";
import { useQuotes } from "@app/quotes/hooks";

const Quotes = () => {
  const pageSize = 15;
  const { quotes, page, size, loadMore, loadPrevious, loading, getQuotePdf } =
    useQuotes(pageSize);
  const { openError } = useSnackbars();
  const user = useSelector(selectCurrentUser);
  const itineraryBookingDialogRef = useRef<ItineraryBookingDialogRef>(null);

  const downloadPDF = async (quote: Quote) => {
    try {
      await getQuotePdf(quote);
    } catch (e) {
      openError({
        message: "Something went wrong. Please try again!",
      });
    }
  };

  const onOpenBookDialog = (quoteId) => {
    itineraryBookingDialogRef.current.onOpen(false, quoteId);
  };

  return (
    <Layout>
      <PageContainer title={"Quotes"}>
        <QuotesTable
          user={user}
          pageSize={pageSize}
          data={quotes}
          bookQuote={onOpenBookDialog}
          downloadPDF={downloadPDF}
          page={page}
          size={size}
          loadMore={loadMore}
          loadPrevious={loadPrevious}
          loading={loading}
        />
      </PageContainer>
      <ItineraryBookingDialog ref={itineraryBookingDialogRef} />
    </Layout>
  );
};

export default Quotes;
