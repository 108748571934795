import React from "react";
import { Layout, PublicPage } from "@app/components";
import { Box, Typography } from "@material-ui/core";
import { usePrePostTours } from "@app/pre-post-tours/hooks";
import ItinerarySection from "@app/components/itinerary-section";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { useHistory } from "react-router-dom";
import * as authActions from "@app/store/actions/auth.actions";

const PrePostTours = () => {
  const dispatch = useDispatch();
  const { loading: loadingPrePostTours, prePostTours } = usePrePostTours();
  const user = useSelector(selectCurrentUser);
  const history = useHistory();

  const onSelectPrePost = (id: number) => {
    if (!user) {
      history.replace({
        state: {
          returnUrl: `/pre-post-tours/${id}`,
        },
      });
      return onOpenLoginModal();
    }
    history.push(`/pre-post-tours/${id}`);
  };

  const onOpenLoginModal = () => {
    dispatch(authActions.OpenLoginDialog());
  };

  return (
    <Layout>
      <PublicPage>
        <Box
          className={"page-min-height"}
          flexDirection={"column"}
          display={"flex"}
          alignItems={"center"}
          paddingTop={4}
        >
          <Typography variant={"h1"} align={"center"}>
            Pre & Post Tours
          </Typography>
          <Box marginTop={2} marginBottom={6}>
            <Typography variant={"body1"} align={"center"}>
              Visiting Romania on a cruise or river cruise? Extend your trip
              with a pre-or post- stay off the beaten path. Our hand-selected
              specialty locations offer you the chance to unwind and immerse in
              local life, in a laid back, authentic, and relaxing atmosphere.
            </Typography>
          </Box>
          <ItinerarySection
            itineraries={prePostTours}
            loading={loadingPrePostTours}
            onClick={onSelectPrePost}
          />
        </Box>
      </PublicPage>
    </Layout>
  );
};

export default PrePostTours;
