import { useApi } from "@app/hooks";
import { Quote } from "@app/quotes/types/classes";
import { SaveQuote } from "@app/modules/quotes/types/interfaces";
import { useState } from "react";
import { useDownloadQuoteHook } from "@app/quotes/hooks/download-quote-hook";

export const useQuote = () => {
  const { request, loading } = useApi();
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const { getQuotePdf } = useDownloadQuoteHook(quotes, setQuotes);

  const saveQuote = async (data: SaveQuote) => {
    return await request<SaveQuote>({
      url: `records/quote`,
      method: "POST",
      body: data,
    });
  };

  const getQuote = async (quoteId: number) => {
    return await request<SaveQuote>({
      url: `records/quote/${quoteId}`,
      method: "GET",
      queryParams: {
        datafy_fields:
          "agent,name,agency,length_of_stay,time_of_travel,looking_for,itinerary_price,itinerary_name,inclusions," +
          "quote_days.order,quote_days.name,quote_days.images.id,quote_days.summary,quote_days.top_description,quote_days.bottom_description," +
          "quote_days.quote_day_actions.name,quote_days.quote_day_actions.description,quote_days.quote_day_actions.image.id,itinerary.description,itinerary.images.id",
      },
    });
  };

  return {
    quotes,
    getQuotePdf,
    saveQuote,
    loading,
    getQuote,
  };
};
