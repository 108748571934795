import React from "react";
import {
  Box,
  Paper,
  Table as UITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Columns from "@app/types/columns";
import styled from "styled-components";
import { TableEmpty, TablePaginator } from "@app/components/index";

const StyledTableHeader = styled(TableRow)`
  background: #f8fafd 0 0 no-repeat padding-box;
  border-radius: 8px;
  height: 55px;
  color: #969798;
`;

const StyledTableHeaderCell = styled(TableCell)`
  //font-weight: 500;
  //font-size: 14px;
  //line-height: 24px;
  color: #969798;
  border-bottom: none;
  padding: 8px;
`;

const StyledTableCell = styled(TableCell)`
  //font-weight: 500;
  //font-size: 12px;
  line-height: 18px;
  padding: 16px 8px;
  border-bottom: 1px solid #dedede;
`;

interface TableProps {
  data: any[];
  loading: boolean;
  page: number;
  size: number;
  pageSize: number;
  loadMore: () => void;
  loadPrevious: () => void;
  columns: Columns[];
  emptyText: string;
  paginatorText: string;
  TablePlaceholder: React.FC;
}

const Table = ({
  data,
  loading,
  page,
  size,
  pageSize,
  loadMore,
  loadPrevious,
  columns,
  paginatorText,
  emptyText,
  TablePlaceholder,
}: TableProps) => {
  const empty = data.length === 0;

  const renderHeaderRow = columns.map((column) => (
    <StyledTableHeaderCell
      key={column.key}
      style={column.style}
      align={column.align}
    >
      {column.name}
    </StyledTableHeaderCell>
  ));

  const renderRows = data.map((object) => (
    <TableRow key={object.id}>
      {columns.map((column) => (
        <StyledTableCell
          key={column.name}
          style={column.style}
          align={column.align}
        >
          {column.cell(object)}
        </StyledTableCell>
      ))}
    </TableRow>
  ));

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      justifyContent={"space-between"}
      flex={1}
    >
      <Box>
        <TableContainer style={{ boxShadow: "none" }} component={Paper}>
          <UITable stickyHeader={false} aria-label="simple table">
            <TableHead>
              <StyledTableHeader>{renderHeaderRow}</StyledTableHeader>
            </TableHead>
            {!loading && !empty && <TableBody>{renderRows}</TableBody>}
          </UITable>
        </TableContainer>
        {loading && <TablePlaceholder />}
        {!loading && empty && (
          <TableEmpty>
            <div>{emptyText}</div>
          </TableEmpty>
        )}
      </Box>
      {!loading && !empty && (
        <Box marginTop={4}>
          <TablePaginator
            loadMore={loadMore}
            loadPrevious={loadPrevious}
            size={size}
            page={page}
            pageSize={pageSize}
            loading={loading}
            paginatorText={paginatorText}
          />
        </Box>
      )}
    </Box>
  );
};

export default Table;
