import { ItinerariesState } from "@app/store/reducers/itineraries.reducer";
import { createSelector } from "reselect";
import { Cluster, ItineraryCluster } from "@app/itineraries/types/interfaces";
import { PrePostToursState } from "@app/store/reducers/pre-post-tours.reducer";
import { AppState } from "@app/store/reducers";

const getPrePostToursState = (state: AppState): PrePostToursState =>
  state.prePostTours;

export const selectPrePostTours = createSelector(
  getPrePostToursState,
  (state) => {
    return Object.values(state.prePostTours);
  }
);

export const selectPrePostToursLoaded = createSelector(
  getPrePostToursState,
  (state) => state.prePostToursLoaded
);

export const selectPrePostTour = createSelector(
  getPrePostToursState,
  (state) => {
    return state.prePostTours[state.prePostTour];
  }
);

export const selectPrePostTourById = (id: number) =>
  createSelector(getPrePostToursState, (state) => state.prePostTours[id]);

export const selectPrePostTourPrice = (id: number) =>
  createSelector(getPrePostToursState, (state) =>
    state.prePostTours[id]?.clusters.reduce(
      (total, current: ItineraryCluster) =>
        total + (current.cluster?.price ? current.cluster.price : 0),
      0
    )
  );

export const selectPrePostTourDays = (id: number) =>
  createSelector(getPrePostToursState, (state) =>
    state.prePostTours[id]?.clusters.reduce(
      (total, current: ItineraryCluster) =>
        total + (current.cluster?.days ? current.cluster.days.length : 0),
      0
    )
  );
