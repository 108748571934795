import React, { ReactNode } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Colors from "@app/types/colors.enum";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const GenericAccordion = withStyles({
  root: {
    backgroundColor: Colors.BROWN,
    borderRadius: "8px !important",
    padding: "21px",
    margin: "0px !important",
    minHeight: "48px",
    "&:before": {
      height: "0px !important",
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: "0px",
    cursor: "auto !important",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
}))(MuiAccordionDetails);

interface Props {
  onExpand?: () => void;
  expanded?: boolean;
  summary: ReactNode;
  details: ReactNode;
}

export const Accordion = ({ onExpand, summary, details, expanded }: Props) => {
  return (
    <GenericAccordion onClick={onExpand} expanded={expanded}>
      <AccordionSummary>{summary}</AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </GenericAccordion>
  );
};

export default Accordion;
