import { useRaApi } from "@app/hooks";
import { downloadFile } from "@app/utils";
import { CustomItinerary, Itinerary } from "@app/itineraries/types/interfaces";

export const useDownloadPdf = () => {
  const { request, loading } = useRaApi();

  const getItineraryPdf = async (itinerary: Itinerary) => {
    const response = await request({
      url: "/itinerary/generate-pdf",
      method: "POST",
      data: {
        id: itinerary.id,
      },
      responseType: "blob",
    });
    const name = `Quote - ${itinerary.name}.pdf`;
    downloadFile(response, name);
  };

  const getCustomItineraryPdf = async (itinerary: CustomItinerary) => {
    const response = await request({
      url: "/custom-itinerary/generate-pdf",
      method: "POST",
      data: itinerary,
      responseType: "blob",
    });
    const name = `Quote - ${itinerary.name}.pdf`;
    downloadFile(response, name);
  };

  return { getItineraryPdf, getCustomItineraryPdf, loading };
};
