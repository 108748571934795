import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding: 27px 40px 27px 40px;
  width: 290px;
  font: normal normal normal 16px/21px Sofia Pro;
  display: flex;
  flex-direction: column;

  div + div {
    margin-top: 18px;
  }

  a {
    color: #000000;
    text-decoration: none;
  }
`;

const LogoutDiv = styled.div`
  color: #bb2d25;
`;

interface UserDropdownProps {
  logOut: () => void;
}

const UserDropdown = ({ logOut }: UserDropdownProps) => {
  return (
    <Container>
      <div>
        <NavLink to="/profile" exact>
          Settings
        </NavLink>
      </div>
      <LogoutDiv className={"cursor-pointer"} onClick={logOut}>
        Log out
      </LogoutDiv>
    </Container>
  );
};

export default UserDropdown;
