import { map } from "lodash";
import { downloadFile } from "@app/utils";
import { useRaApi } from "@app/hooks";
import { Booking } from "@app/bookings/types/classes";

export const useDownloadBookingHook = (bookings, setBookings) => {
  const { request: downloadApi } = useRaApi();

  const getBookingPdf = async (booking: Booking) => {
    setBookings(
      map(bookings, (_booking) => {
        if (_booking.id === booking.id) {
          _booking.isDownloading = true;
        }
        return _booking;
      })
    );

    try {
      const response = await downloadApi({
        url: "/booking/generate-pdf",
        method: "POST",
        data: {
          id: booking.id,
        },
        responseType: "blob",
      });

      const name = `${booking.clientName}.pdf`;
      await downloadFile(response, name);
    } finally {
      setBookings(
        map(bookings, (_booking) => {
          if (_booking.id === booking.id) {
            _booking.isDownloading = false;
          }
          return _booking;
        })
      );
    }
  };

  return { getBookingPdf };
};
