import React, { useRef } from "react";
import { Layout } from "@app/components";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { BookingsTable } from "@app/bookings/components";
import { useBookings } from "@app/bookings/hooks";
import PageContainer from "@app/components/page-container";
import { Booking } from "@app/bookings/types/classes";
import { useSnackbars } from "@app/hooks";
import PersonalizeBookingDialog from "@app/bookings/containers/personalize-booking-dialog";
import { PersonalizeBookingDialogRef } from "@app/bookings/types/refs";
import { PersonalizeFormData } from "@app/bookings/types/interfaces";

const Bookings = () => {
  const pageSize = 15;
  const user = useSelector(selectCurrentUser);

  const personalizeBookingDialogRef = useRef<PersonalizeBookingDialogRef>(null);
  const { openError, openSuccess } = useSnackbars();
  const {
    bookings,
    bookingsLoading,
    personalizeBooking,
    personalizeLoading,
    getBookingPdf,
    page,
    size,
    loadMore,
    loadPrevious,
  } = useBookings(pageSize);

  const downloadPDF = async (booking: Booking) => {
    try {
      await getBookingPdf(booking);
    } catch (e) {
      openError({
        message: "Something went wrong. Please try again!",
      });
    }
  };

  const onOpenPersonalizeBookingDialog = (bookingId) => {
    personalizeBookingDialogRef.current.onOpen(bookingId);
  };

  const onPersonalizedBooking = async (
    id: number,
    bookingPersonalize: PersonalizeFormData
  ) => {
    try {
      await personalizeBooking(id, bookingPersonalize);
      openSuccess({
        message: "Booking successfully personalized!",
      });
    } catch (e) {
      openError({
        message: "Something went wrong. Please try again!",
      });
    }
  };

  return (
    <Layout>
      <PageContainer title={"Booking"}>
        <BookingsTable
          pageSize={pageSize}
          user={user}
          data={bookings}
          page={page}
          size={size}
          loadMore={loadMore}
          loadPrevious={loadPrevious}
          downloadBooking={downloadPDF}
          onPersonaliseBooking={onOpenPersonalizeBookingDialog}
          loading={bookingsLoading}
        />
        <PersonalizeBookingDialog
          ref={personalizeBookingDialogRef}
          personalizeBooking={onPersonalizedBooking}
          loading={personalizeLoading}
        />
      </PageContainer>
    </Layout>
  );
};

export default Bookings;
