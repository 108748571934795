import React, { FunctionComponent } from "react";
import { Route } from "react-router-dom";

interface RouteProps {
  component: FunctionComponent;
  key: string;
  exact: boolean;
  path: string;
}

const PublicRoute = ({ component: Component, ...rest }: RouteProps) => {
  return <Route {...rest} render={() => <Component />} />;
};

export default PublicRoute;
