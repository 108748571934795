import { useApi } from "@app/hooks";

export const useResetPassword = (): {
  loading: boolean;
  sendResetPassword: (email: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;
} => {
  const { request, loading } = useApi();

  const sendResetPassword = async (email: string): Promise<void> => {
    await request({
      url: "auth/sendResetPassword",
      method: "POST",
      body: { email },
    });
  };

  const resetPassword = async (
    token: string,
    password: string
  ): Promise<void> => {
    await request({
      url: "auth/resetPassword",
      method: "POST",
      body: { token, password },
    });
  };

  return { loading, sendResetPassword, resetPassword };
};
