import { LoadingSpinner } from "@app/components";
import Colors from "@app/types/colors.enum";
import React from "react";
import { AgencyDetails } from "@app/profile/types/interfaces";
import { AgencyForm, AgencyLogo } from "@app/profile/components";
import { Box } from "@material-ui/core";

interface TravelAgencyProps {
  onSubmit(data: AgencyDetails): void;
  loadingUpdateAgency: boolean;
  loadingAgency: boolean;
  agency: AgencyDetails;
}

const TravelAgencyInformation = ({
  loadingUpdateAgency,
  loadingAgency,
  agency,
  onSubmit,
}: TravelAgencyProps) => {
  return (
    <Box display={"flex"} flexDirection={"row"} className={"gap-m"}>
      {loadingAgency ? (
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
        >
          <LoadingSpinner color={Colors.PRIMARY} size={48} />
        </Box>
      ) : (
        <>
          <AgencyLogo logoUrl={agency?.logo} />
          <AgencyForm
            loadingUpdateAgency={loadingUpdateAgency}
            agency={agency}
            onSubmit={onSubmit}
          />
        </>
      )}
    </Box>
  );
};

export default TravelAgencyInformation;
