import React from "react";
import { Action, Day } from "@app/itineraries/types/interfaces";
import styled from "styled-components";
import { Box, ImageList, Typography } from "@material-ui/core";
import ImageListItem from "@material-ui/core/ImageListItem";
import { Image } from "@app/types/image";
import DayAction from "@app/itineraries/components/day-action";
import { DayTitle } from "@app/itineraries/components/index";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface ItineraryDayDetailsProps {
  day: Day;
  index: number;
}

const useStyles = makeStyles({
  root: {
    padding: "0 16px 0 0 !important",
    height: "450px !important",
  },
});

const ItineraryDayDetails = ({ day, index }: ItineraryDayDetailsProps) => {
  const classes = useStyles();

  const getTopDescription = () => {
    return day.topDescription?.map((description: string, index: number) => (
      <Typography variant={"body1"} key={index}>
        {description}
      </Typography>
    ));
  };

  const getBottomDescription = () => {
    return day.bottomDescription?.map((description: string, index: number) => (
      <Typography variant={"body1"} key={index}>
        {description}
      </Typography>
    ));
  };
  const rowHeight = 450;

  const images = () => {
    switch (day?.images?.length) {
      case 1:
        return day.images.map((item: Image, index: number) => (
          <ImageListItem
            className={`width-100 ${classes.root}`}
            key={index}
            cols={1}
            rows={1}
          >
            <img
              className={"image-radius"}
              src={item?.file}
              alt={item?.file}
              width={"100%"}
              height={rowHeight}
            />
          </ImageListItem>
        ));
      case 2:
        return day.images.map((item: Image, index: number) => (
          <ImageListItem className={classes.root} key={index} cols={1} rows={2}>
            <img
              className={"image-radius"}
              src={item?.file}
              alt={item?.file}
              width={"100%"}
              height={rowHeight}
            />
          </ImageListItem>
        ));

      case 3:
        return day.images.map((item: Image, index: number) => (
          <ImageListItem
            className={
              index !== 0
                ? `width-25 ${classes.root}`
                : `width-50 ${classes.root}`
            }
            key={index}
            cols={1}
            rows={3}
          >
            <img
              className={"image-radius"}
              src={item?.file}
              alt={item?.file}
              width={"100%"}
              height={rowHeight}
            />
          </ImageListItem>
        ));
      case 4:
        return day.images.map((item: Image, index: number) => (
          <ImageListItem
            className={`width-25 ${classes.root}`}
            key={index}
            cols={1}
            rows={4}
          >
            <img
              className={"image-radius"}
              src={item?.file}
              alt={item?.file}
              width={"100%"}
              height={rowHeight}
            />
          </ImageListItem>
        ));
      default:
        return <></>;
    }
  };

  const actions = () => {
    return day.actions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((action: Action, index: number) => (
        <DayAction key={index} action={action} />
      ));
  };

  return (
    <div>
      <DayTitle title={`Day ${index + 1} - ${day.name}`} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        className={"gap-xs"}
        paddingBottom={4}
      >
        {getTopDescription()}
      </Box>
      <ImageList
        style={{
          margin: "0 0 32px 0 !important",
          width: "100%",
        }}
        rowHeight={509}
      >
        {images()}
      </ImageList>
      <Box
        display={"flex"}
        flexDirection={"column"}
        className={"gap-xs"}
        paddingBottom={4}
      >
        {getBottomDescription()}
      </Box>
      <Box display={"flex"} flexDirection={"column"} className={"gap-xl"}>
        {actions()}
      </Box>
    </div>
  );
};

export default ItineraryDayDetails;
