import React from "react";
import ContentLoader from "react-content-loader";
import { Box } from "@material-ui/core";

const ItineraryPlaceholder = () => {
  return (
    <Box>
      <ContentLoader viewBox="0 0 1500 1100" height={1100} width={"100%"}>
        <rect x="0" y="13" rx="4" ry="4" width="200" height="30" />
        <rect x="0" y="100" rx="4" ry="4" width="500" height="50" />
        <rect x="1000" y="30" rx="4" ry="4" width="500" height="50" />
        <rect x="0" y="200" rx="4" ry="4" width="1500" height="40" />
        <rect x="0" y="300" rx="4" ry="4" width="1500" height="800" />
      </ContentLoader>
    </Box>
  );
};

export default ItineraryPlaceholder;
