import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Button, IconButton, ReadonlyInput, TextInput } from "@app/components";
import Colors from "@app/types/colors.enum";
import { AgencyDetails } from "@app/profile/types/interfaces";
import { Box } from "@material-ui/core";

interface AgencyFormProps {
  onSubmit(data: AgencyDetails): void;
  loadingUpdateAgency: boolean;
  agency: AgencyDetails;
}

const AgencyForm = ({
  loadingUpdateAgency,
  agency,
  onSubmit,
}: AgencyFormProps) => {
  const { control, handleSubmit, setValue } = useForm({
    mode: "all",
  });
  const [readonly, setReadonly] = useState(true);

  useEffect(() => {
    if (!loadingUpdateAgency) {
      setReadonly(true);
    }
  }, [loadingUpdateAgency]);

  useEffect(() => {
    if (!readonly) {
      setValue("websiteUrl", agency.websiteUrl);
      setValue("contactEmail", agency.contactEmail);
      setValue("contactPhoneNumber", agency.contactPhoneNumber);
    }
  }, [readonly]);

  const renderForm = () => {
    if (readonly) {
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          width={"100%"}
          className={"gap-m"}
        >
          <ReadonlyInput
            value={agency?.websiteUrl || "Optional"}
            label="Website URL"
            withBorder={true}
          />
          <ReadonlyInput
            value={agency?.contactPhoneNumber || "Optional"}
            label="Contact phone number"
            withBorder={true}
          />
          <ReadonlyInput
            value={agency?.contactEmail || "Optional"}
            label="Contact Email"
            withBorder={true}
          />
          <IconButton
            title="Edit"
            iconPath="/icons/edit.svg"
            height={20}
            width={59}
            iconWidth={15}
            iconHeight={15}
            hoverColor={Colors.PRIMARY}
            onClick={() => setReadonly(false)}
          />
        </Box>
      );
    }
    return (
      <form className={"width-100"} onSubmit={handleSubmit(onSubmit)}>
        <Box
          className={"gap-m"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
        >
          <TextInput
            required={false}
            name="websiteUrl"
            type="text"
            label="Website URL"
            control={control}
            rules={{
              pattern: {
                value:
                  /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/i,
                message: "Invalid website url",
              },
            }}
          />
          <TextInput
            required={false}
            name="contactPhoneNumber"
            type="text"
            label="Contact phone number"
            control={control}
            rules={{
              pattern: {
                value: /^[0-9]*$/i,
                message: "Invalid phone number",
              },
            }}
          />
          <TextInput
            required={false}
            name="contactEmail"
            type="text"
            label="Contact Email"
            control={control}
            rules={{
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                message: "Invalid email address",
              },
            }}
          />
          <Box marginTop={2}>
            <Button
              type="submit"
              title="Save"
              loading={loadingUpdateAgency}
              width={180}
              height={43}
              color={Colors.PRIMARY}
              hoverColor={Colors.HOVER_PRIMARY}
              textColor={Colors.WHITE}
              withBorder={false}
            />
          </Box>
        </Box>
      </form>
    );
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-end"}
      width={549}
    >
      {renderForm()}
    </Box>
  );
};

export default AgencyForm;
