import { useApi } from "@app/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Itinerary } from "@app/itineraries/types/interfaces";
import { useEffect } from "react";
import { PaginatedResponse } from "@app/types";
import {
  selectPrePostTours,
  selectPrePostToursLoaded,
} from "@app/store/selectors/pre-post-tours.selectors";
import { LoadPrePostTours } from "@app/store/actions/pre-post-tours.actions";

export const usePrePostTours = (): {
  prePostTours: Itinerary[];
  loading: boolean;
} => {
  const { request, loading } = useApi();
  const prePostToursLoaded: boolean = useSelector(selectPrePostToursLoaded);
  const prePostTours: Itinerary[] = useSelector(selectPrePostTours);
  const dispatch = useDispatch();

  useEffect(() => {
    if (prePostToursLoaded || loading) {
      return;
    }

    loadPrePostTours();
  }, []);

  const loadPrePostTours = async () => {
    const filters = {
      type__isnull: false,
      active__eq: true,
    };
    const data = await request<PaginatedResponse<Itinerary>>({
      url: "records/itinerary",
      method: "GET",
      queryParams: {
        ...filters,
        datafy_fields:
          "id,name,cover_description,clusters.cluster.price,clusters.cluster.days,cover_image.file,type",
        pageSize: 30,
      },
    });

    dispatch(LoadPrePostTours(data.results));
  };

  return { prePostTours, loading };
};
