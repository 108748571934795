import React, { useEffect, useRef } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import PublicRoute from "./public-route";
import PrivateRoute from "./private-route";
import SnackbarManager from "@app/components/snackbar-manager";
import ConfirmationDialogManager from "@app/components/confirmation-dialog-manager";
import ScrollToTop from "@app/components/scroll-to-top";
import SecurePasswordDialog from "@app/modules/auth/containers/secure-password-dialog";
import SetupLogoDialog from "@app/profile/containers/setup-logo-dialog";
import { SecurePasswordDialogRef } from "@app/itineraries/types/refs";
import { SetupLogoDialogRef } from "@app/profile/types/refs";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import LoadingDialogManager from "@app/components/loading-dialog-manager";
import { PageNotFound } from "@app/components";

const Router = () => {
  const user = useSelector(selectCurrentUser);
  const securePasswordDialogRef = useRef<SecurePasswordDialogRef>(null);
  const setupLogoDialogRef = useRef<SetupLogoDialogRef>(null);

  useEffect(() => {
    if (user && user.firstLogin) {
      // open the Secure password and Setup Logo dialog
      securePasswordDialogRef.current.open(() => {
        if (user.masterAccount) {
          setupLogoDialogRef.current.open();
        }
      });
    }
  }, [user]);

  return (
    <BrowserRouter>
      <SecurePasswordDialog ref={securePasswordDialogRef} />
      <SetupLogoDialog ref={setupLogoDialogRef} />
      <LoadingDialogManager />
      <SnackbarManager />
      <ConfirmationDialogManager />

      <ScrollToTop>
        <Switch>
          {publicRoutes.map(({ key, exact, path, component }) => (
            <PublicRoute
              key={key}
              exact={exact}
              path={path}
              component={component}
            />
          ))}
          {privateRoutes.map(({ key, exact, path, component }) => (
            <PrivateRoute
              key={key}
              exact={exact}
              path={path}
              component={component}
            />
          ))}
          <Route
            exact
            path={"/"}
            render={(props) => <Redirect to="/itineraries" />}
          />

          <Route component={PageNotFound} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
