import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  Method,
} from "axios";
import { RA_API_URL } from "@app/constants";
import { useState } from "react";
import { ApiError } from "@app/types/api-error";
import { useApi } from "@app/hooks/use-api.hook";

interface ApiErrorMessage {
  detail: string;
  code: string;
}

interface RequestParams {
  url: string;
  method: Method;
  body?: { [id: string]: any };
  headers?: { [id: string]: string };
  queryParams?: { [id: string]: any };
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: RA_API_URL,
});

export const useRaApi = () => {
  const [loading, setLoading] = useState(false);
  const { refreshIfExpired, getAccessToken } = useApi();

  const axiosRequest = async (config: AxiosRequestConfig): Promise<any> => {
    setLoading(true);
    return axiosInstance
      .request(config)
      .then((response: any) => {
        setLoading(false);
        return response;
      })
      .catch((response: AxiosError<ApiErrorMessage>) => {
        setLoading(false);
        const message = new ApiError();
        message.status = response?.response?.status || 500;
        message.message =
          response?.response?.data?.detail || "Something went wrong";
        message.code = response?.response?.data?.code || "error";
        message.data = response.response?.data || {};

        return Promise.reject(message);
      });
  };

  const request = async (config: AxiosRequestConfig) => {
    await refreshIfExpired();
    config.headers = config.headers || {};
    const token = getAccessToken();
    if (token) {
      config.headers["x-authentication"] = token;
    }

    return axiosRequest(config);
  };

  return { request, loading };
};
