import React, { useEffect, useState } from "react";
import { Layout } from "@app/components";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomItinerary } from "@app/store/selectors/itineraries.selectors";
import { Prompt, useHistory } from "react-router-dom";
import { useClusters } from "@app/itineraries/hooks/clusters.hook";
import { Cluster, ItineraryCluster } from "@app/itineraries/types/interfaces";
import {
  ClusterDetails,
  ItineraryBuilder,
  ItineraryDetails,
} from "@app/itineraries/components";
import { useLoadingDialog } from "@app/hooks/loading-dialog.hook";
import { SetCustomItineraryFilters } from "@app/store/actions/itineraries.actions";
import { useGeneralInfoCustomItinerary } from "@app/itineraries/hooks";
import { SetSelectedCluster } from "@app/store/actions/clusters.actions";

const CreateItinerary = () => {
  const [isBlocking, setIsBlocking] = useState(true);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const customItinerary = useSelector(selectCustomItinerary);
  const [created, setCreated] = useState(false);
  const history = useHistory();
  const { clusters, loading } = useClusters();
  const { openLoadingDialog, closeLoadingDialog } = useLoadingDialog();
  const dispatch = useDispatch();
  const [generalInfosCustomItinerary, setGeneralInfosCustomItinerary] =
    useState(null);
  const { customItineraryGeneralInfos } = useGeneralInfoCustomItinerary();
  const [selectedClusters, setSelectedClusters] = useState([]);
  useEffect(() => {
    setGeneralInfosCustomItinerary(customItineraryGeneralInfos);
  }, [customItineraryGeneralInfos]);

  useEffect(() => {
    if (!customItinerary) {
      backToEdit(false);
    }
  }, [customItinerary]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const backToEdit = (openDialog = true) => {
    if (selectedCluster || created) {
      setCreated(false);
      setSelectedCluster(null);
      setTimeout(() => {
        dispatch(SetSelectedCluster(null));
      }, 3000);
    } else {
      setIsBlocking(false);
      setTimeout(() => backToItineraries(openDialog), 50);
    }
  };

  const backToItineraries = (openDialog: boolean = false) => {
    history.push("/itineraries", {
      openCreateItineraryDialog: openDialog,
    });
  };

  const onView = (cluster, selectedClusters) => {
    dispatch(SetSelectedCluster(cluster.id));
    setSelectedClusters(selectedClusters);
    setSelectedCluster(cluster);
  };

  const onReset = () => {
    setSelectedClusters([]);
  };

  const getItineraryClusters = (
    selectedClusters: Cluster[]
  ): ItineraryCluster[] => {
    return selectedClusters.map((cluster: Cluster, index) => {
      return {
        order: index,
        cluster: cluster,
      };
    });
  };

  const onCreateItinerary = (selectedClusters: Cluster[]) => {
    setSelectedClusters(selectedClusters);

    openLoadingDialog({
      title: "Creating itinerary...",
      message: "",
    });
    const itineraryClusters = getItineraryClusters(selectedClusters);
    dispatch(
      SetCustomItineraryFilters({
        ...customItinerary,
        clusters: itineraryClusters,
        name: "Your own itinerary",
      })
    );

    setGeneralInfosCustomItinerary({
      ...generalInfosCustomItinerary,
      clusters: itineraryClusters,
      name: "Your own itinerary",
    });

    setTimeout(() => {
      setCreated(true);
      closeLoadingDialog();
    }, 1500);
  };

  const renderContent = () => {
    if (selectedCluster) {
      return (
        <Box>
          <ClusterDetails
            cluster={selectedCluster}
            onBack={() => backToEdit(true)}
          />
        </Box>
      );
    }
    if (created) {
      return (
        <Box>
          <ItineraryDetails
            itinerary={generalInfosCustomItinerary}
            onBack={() => backToEdit(true)}
            backButtonTitle={"Back"}
            customItinerary={true}
          />
        </Box>
      );
    }
    return (
      <ItineraryBuilder
        loading={loading}
        onView={(cluster: Cluster, selectedClusters: Cluster[]) =>
          onView(cluster, selectedClusters)
        }
        clusters={clusters}
        backToEdit={() => backToEdit(true)}
        lengthOfStay={customItinerary?.lengthOfStay}
        backToItineraries={() => backToItineraries(false)}
        onCreateItinerary={(selectedClusters: Cluster[]) =>
          onCreateItinerary(selectedClusters)
        }
        selectedClusters={selectedClusters}
        onReset={onReset}
        onSetSelectedClusters={(_selectedClusters: Cluster[]) =>
          setSelectedClusters(_selectedClusters)
        }
      />
    );
  };

  return (
    <>
      <Prompt
        when={isBlocking}
        message={() =>
          `Are you sure you want to leave the page? All the data will be lost`
        }
      />
      <Layout>
        <Box className={"page-min-height"} paddingTop={4} paddingBottom={4}>
          {renderContent()}
        </Box>
      </Layout>
    </>
  );
};

export default CreateItinerary;
