import React from "react";
import {
  CssBaseline,
  MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core";
import { useLoadUser } from "@app/hooks";
import Router from "@app/routes";
import theme from "@app/theme";
import { ThemeProvider } from "styled-components";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ScreenLoader } from "@app/components";

function App() {
  const { loading } = useLoadUser();

  const renderApp = () => {
    if (loading) {
      return <ScreenLoader />;
    }
    return (
      <>
        <Router />
      </>
    );
  };

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            {renderApp()}
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
