import React from "react";

interface HTMLContentProps {
  content: any;
}

const HTMLContent = ({ content }: HTMLContentProps) => {
  const markup = { __html: content };
  return <div dangerouslySetInnerHTML={markup}></div>;
};

export default HTMLContent;
