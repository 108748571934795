import React, { FunctionComponent } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuth } from "@app/store/selectors/auth.selectors";
import { useLoadUser } from "@app/hooks";
import { ScreenLoader } from "@app/components";

interface RouteProps {
  component: FunctionComponent;
  key: string;
  exact: boolean;
  path: string;
}

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuth = useSelector(selectIsAuth);
  const { loading } = useLoadUser();

  const { pathname } = useLocation();
  return (
    <Route
      {...rest}
      render={() =>
        isAuth ? (
          <Component />
        ) : loading ? (
          <ScreenLoader />
        ) : (
          <Redirect
            to={{
              pathname: "/itineraries",
              search: "login=true",
              state: { returnUrl: pathname },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
