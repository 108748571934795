import React, { useImperativeHandle, useRef, useState } from "react";
import { ChangePasswordDialogRef } from "@app/itineraries/types/refs";
import { Dialog } from "@app/components";
import { Box } from "@material-ui/core";
import { ChangePasswordForm } from "@app/modules/auth/components";
import { useResetPassword } from "@app/modules/auth/hooks";
import { useQuery, useSnackbars } from "@app/hooks";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { OpenLoginDialog } from "@app/store/actions/auth.actions";
import { useConfirmationDialog } from "@app/hooks/confirmation-dialog.hook";

const ChangePasswordDialog = React.forwardRef<ChangePasswordDialogRef>(
  (props, ref) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const { loading, resetPassword } = useResetPassword();
    const { openConfirmationDialog } = useConfirmationDialog();
    const { openError } = useSnackbars();
    const { query, state } = useQuery();
    const history = useHistory();
    const tokenRef = useRef<string>(null);

    useImperativeHandle(
      ref,
      (): ChangePasswordDialogRef => ({
        open: (token: string) => {
          tokenRef.current = token;
          onOpen();
        },
      })
    );

    const onOpen = () => setOpen(true);
    const onClose = () => {
      setOpen(false);
      removeQueryCode();
    };

    const onChangePassword = async (password: string) => {
      try {
        await resetPassword(tokenRef.current, password);
        onClose();
        openConfirmationDialog({
          title: "Password updated!",
          button: "Log In",
          onSuccess: () => dispatch(OpenLoginDialog()),
        });
        removeQueryCode();
      } catch (e) {
        openError({ message: e.message });
      }
    };

    const removeQueryCode = () => {
      if (!query.has("resetPasswordCode")) {
        return;
      }

      query.delete("resetPasswordCode");
      history.replace({
        search: query.toString(),
        state: state,
      });
    };

    return (
      <Dialog open={open} onClose={onClose} title={"Reset password"}>
        <Box width={500}>
          <ChangePasswordForm loading={loading} onSubmit={onChangePassword} />
        </Box>
      </Dialog>
    );
  }
);

ChangePasswordDialog.displayName = "ChangePasswordDialog";

export default ChangePasswordDialog;
