import React from "react";
import styled from "styled-components";

interface SocialMedia {
  link: string;
  img: string;
  alt: string;
}

const List = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  a {
    margin-right: 15px;
  }
`;

const SocialBar = () => {
  const socialMediaAccounts: SocialMedia[] = [
    {
      link: "https://www.facebook.com/romanianadventuresforyou/",
      img: "/socials/facebook.svg",
      alt: "facebook-logo",
    },
    // {
    //   link: "https://www.linnify.com/",
    //   img: "/socials/youtube.svg",
    //   alt: "youtube-logo",
    // },
    // {
    //   link: "https://www.linnify.com/",
    //   img: "/socials/twitter.svg",
    //   alt: "twitter-logo",
    // },
    {
      link: "https://www.instagram.com/romanianadventuresforyou/",
      img: "/socials/instagram.svg",
      alt: "instagram-logo",
    },
    {
      link: "https://www.linkedin.com/company/romanian-adventures/",
      img: "/socials/linkedin.svg",
      alt: "linkedin-logo",
    },
  ];
  const socialMedia = socialMediaAccounts.map((profile, index) => {
    return (
      <a href={profile.link} target={"_blank"} rel={"noreferrer"} key={index}>
        <img src={profile.img} alt={profile.alt} width={25} height={25} />
      </a>
    );
  });
  return <List>{socialMedia}</List>;
};

export default SocialBar;
