import Navbar from "./navbar";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@app/components";
import { useDispatch, useSelector } from "react-redux";
import UserButton from "./user-button";
import Colors from "@app/types/colors.enum";
import { NavLink } from "react-router-dom";
import {
  selectCurrentUser,
  selectIsAuth,
} from "@app/store/selectors/auth.selectors";
import { LogOut, OpenLoginDialog } from "@app/store/actions/auth.actions";
import { useApi } from "@app/hooks";
import { Box } from "@material-ui/core";

const HEADER_HEIGHT = 400;

const StyledDiv = styled.div<{
  backgroundPath: string;
  secondBackground: boolean;
}>`
  background-image: url(${(props) => props.backgroundPath});
  background-repeat: no-repeat;
  background-position: center;
  // background-size: 100%
  //   ${(props) => (props.secondBackground ? "100px" : `${HEADER_HEIGHT}px`)};
  background-size: cover;
  width: 100%;
  height: ${(props) =>
    props.secondBackground ? "100px" : `${HEADER_HEIGHT}px`};
  position: fixed;
  display: flex;
  top: 0;
  z-index: 10;
`;

interface HeaderProps {
  parallaxHeader?: boolean;
}

const Header = ({ parallaxHeader }: HeaderProps) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const isAuth = useSelector(selectIsAuth);
  const { logOut } = useApi();
  const [secondBackgroundImage, setSecondBackgroundImage] = useState(false);

  useEffect(() => {
    if (parallaxHeader) {
      window.addEventListener("scroll", onScroll);
    } else {
      document.getElementById("header").style.height = `100px`;
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [parallaxHeader]);

  const onScroll = () => {
    if (document.body.scrollTop || document.documentElement.scrollTop) {
      const maxScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      const difference = HEADER_HEIGHT - maxScroll;
      const imageHeight = difference > 100 ? difference : 100;
      if (imageHeight === 100) {
        setSecondBackgroundImage(true);
      } else {
        setSecondBackgroundImage(false);
      }
      document.getElementById("header").style.height = `${imageHeight}px`;
    } else {
      setSecondBackgroundImage(false);
      document.getElementById("header").style.height = `${HEADER_HEIGHT}px`;
    }
  };

  const onOpenLoginModal = () => dispatch(OpenLoginDialog());
  const onLogout = () => logOut();

  const renderLoginButtonOrUser = () => {
    if (isAuth) {
      return (
        <UserButton
          title={`Welcome, ${user?.firstName}`}
          iconSrc="/icons/binoculars.svg"
          iconWidth={17}
          iconHeight={15}
          logOut={onLogout}
          loading={!user}
        />
      );
    }

    return (
      <Button
        title="Log in"
        width={182}
        height={43}
        color={Colors.WHITE}
        hoverColor={Colors.PRIMARY}
        withBorder={true}
        textColor={Colors.BLACK}
        onClick={onOpenLoginModal}
      />
    );
  };

  return (
    <StyledDiv
      id="header"
      backgroundPath={
        !parallaxHeader || secondBackgroundImage
          ? "/background2.png"
          : "/background.jpg"
      }
      secondBackground={!parallaxHeader || secondBackgroundImage}
    >
      <Box
        width={"100%"}
        marginX={"auto"}
        height={100}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className={"layout-container"}
      >
        <NavLink to="/itineraries" exact className="button-text flex-row-start">
          <img alt="logo" src="/logos/logo.webp" height={55} />
        </NavLink>
        <Navbar />
        {renderLoginButtonOrUser()}
      </Box>
    </StyledDiv>
  );
};

export default Header;
