import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectConfirmationDialog } from "@app/store/selectors/core.selectors";
import { ConfirmationDialog } from "@app/components/index";
import { CloseConfirmationDialog } from "@app/store/actions/core.actions";

const ConfirmationDialogManager = () => {
  const confirmationDialog = useSelector(selectConfirmationDialog);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(false);
  }, [confirmationDialog]);

  const onClose = (success?: boolean) => {
    dispatch(CloseConfirmationDialog());
    if (success && confirmationDialog.onSuccess) {
      confirmationDialog.onSuccess();
    } else if (confirmationDialog.onClose) {
      confirmationDialog.onClose();
    }
  };

  const onOptionalAction = () => {
    setLoading(true);
    confirmationDialog.optionalFunction();
  };

  return (
    <>
      {confirmationDialog && (
        <ConfirmationDialog
          open={true}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          buttonText={confirmationDialog.button}
          optionalActionButtonTitle={
            confirmationDialog.optionalActionButtonTitle
          }
          optionalFunction={onOptionalAction}
          optionalButtonLoading={loading}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ConfirmationDialogManager;
