import { Dialog, PrimaryButton } from "@app/components";
import { Box, Button, Typography } from "@material-ui/core";
import React, { useImperativeHandle, useState } from "react";
import { useHistory } from "react-router-dom";
import { SetupLogoDialogRef } from "@app/profile/types/refs";
import Colors from "@app/types/colors.enum";

const SetupLogoDialog = React.forwardRef<SetupLogoDialogRef>((props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const onSetupLogo = () => {
    onClose();
    history.push("profile", { agencyTab: true });
  };

  useImperativeHandle(
    ref,
    (): SetupLogoDialogRef => ({
      open: () => onOpen(),
    })
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} onClose={onClose} title={"Upload your company logo"}>
      <Box width={500}>
        <Box paddingX={6}>
          <Typography variant={"body2"} align={"center"}>
            Romanian Adventures recommends uploading your logo, in order to{" "}
            {"\n"} brand the generated PDFs.
          </Typography>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginY={6}
          marginX={"auto"}
          width={110}
          height={110}
          borderRadius={"50%"}
          style={{ backgroundColor: Colors.LIGHT_GRAY }}
        >
          <img width={30} src={"/icons/image-gallery.svg"} />
        </Box>

        <Box display={"flex"} justifyContent={"center"} className={"gap-l"}>
          <Button onClick={onClose} variant={"outlined"}>
            Add later
          </Button>
          <PrimaryButton title="Add file" onClick={onSetupLogo} />
        </Box>
      </Box>
    </Dialog>
  );
});

SetupLogoDialog.displayName = "SetupLogoDialog";

export default SetupLogoDialog;
