import React from "react";
import { useSelector } from "react-redux";
import { selectSnackbars } from "@app/store/selectors/core.selectors";
import MessageSnackBar from "@app/components/message-snack-bar";
import { NavLink } from "react-router-dom";

const SnackbarManager = () => {
  const snackbar = useSelector(selectSnackbars);

  return (
    <>
      {snackbar && (
        <MessageSnackBar
          open={true}
          message={snackbar.message}
          onClose={snackbar.onClose}
          icon={snackbar.icon}
          vertical={"bottom"}
          horizontal={"left"}
        >
          {snackbar.linkMessage && snackbar.link ? (
            <NavLink
              to={snackbar.link}
              className="button-text text-color-primary"
            >
              {snackbar.linkMessage}
            </NavLink>
          ) : (
            <></>
          )}
        </MessageSnackBar>
      )}
    </>
  );
};

export default SnackbarManager;
