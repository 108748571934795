import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Itinerary } from "@app/itineraries/types/interfaces";
import { Booking, Passenger } from "@app/itineraries/types/classes";
import Dialog from "@app/components/dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ItineraryBookingDialogRef } from "@app/itineraries/types/refs";
import {
  useBookings,
  useBookingSubmit,
  useGeneralInfoCustomItinerary,
  useInsurance,
} from "@app/itineraries/hooks";
import { useSelector } from "react-redux";
import {
  selectCustomItinerary,
  selectCustomItineraryPrice,
  selectItineraryById,
  selectItineraryPrice,
} from "@app/store/selectors/itineraries.selectors";
import { useParams } from "react-router-dom";
import { selectCurrentUser } from "@app/store/selectors/auth.selectors";
import { useConfirmationDialog } from "@app/hooks/confirmation-dialog.hook";
import { BookItineraryForm } from "@app/itineraries/components";
import { useQuote } from "@app/quotes/hooks";
import {
  selectPrePostTourById,
  selectPrePostTourPrice,
} from "@app/store/selectors/pre-post-tours.selectors";
import { useAgency } from "@app/profile/hooks/agency.hook";
import { useGeneralInfosItinerary, useSnackbars } from "@app/hooks";
import camelcaseKeys from "camelcase-keys";

interface QueryParams {
  id: string;
}

const ItineraryBookingDialog = React.forwardRef<ItineraryBookingDialogRef>(
  (props, ref) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [quoteId, setQuoteId] = useState<number>(null);
    const [useCustomItinerary, setUseCustomItinerary] =
      useState<boolean>(false);
    const [usePrePostTour, setUsePrePostTour] = useState<boolean>(false);
    const { getBookingPdf } = useBookings();
    const { id } = useParams<QueryParams>();
    const [quote, setQuote] = useState(null);
    const itinerary = useCustomItinerary
      ? useSelector(selectCustomItinerary)
      : usePrePostTour
      ? useSelector(selectPrePostTourById(+id))
      : useSelector(selectItineraryById(+id));
    const price = useCustomItinerary
      ? useSelector(selectCustomItineraryPrice)
      : (itinerary as Itinerary)?.type
      ? useSelector(selectPrePostTourPrice(+id))
      : useSelector(selectItineraryPrice(+id));
    const { getQuote } = useQuote();
    const { insurances } = useInsurance();
    const { agency } = useAgency();
    const user = useSelector(selectCurrentUser);
    const { openConfirmationDialog, closeConfirmationDialog } =
      useConfirmationDialog();
    const { openError } = useSnackbars();
    const createdBooking = useRef(null);
    const { generalInfosItinerary } = useGeneralInfosItinerary();
    const { customItineraryGeneralInfos } = useGeneralInfoCustomItinerary();
    const { submitBooking, getDays, getQuoteDays } = useBookingSubmit();

    useImperativeHandle(
      ref,
      (): ItineraryBookingDialogRef => ({
        onOpen: (
          useCustomItinerary = false,
          quoteId = null,
          usePrePostTour = false
        ) => onOpen(useCustomItinerary, quoteId, usePrePostTour),
      })
    );

    const onClose = () => setOpen(false);

    const onOpen = (
      useCustomItinerary: boolean,
      quoteId: number,
      usePrePostTour: boolean
    ) => {
      setQuoteId(quoteId);
      setUseCustomItinerary(useCustomItinerary);
      setUsePrePostTour(usePrePostTour);
      setOpen(true);
    };

    useEffect(() => {
      if (quoteId) {
        getQuoteById(quoteId);
      }
    }, [quoteId]);

    const getQuoteById = async (quoteId) => {
      const quote = await getQuote(quoteId);
      setQuote(quote);
    };

    const onSubmit = async (booking: Booking, passengers: Passenger[]) => {
      setLoading(true);
      try {
        const _createdBooking = await submitBooking(
          booking,
          passengers,
          useCustomItinerary,
          quote,
          itinerary,
          customItineraryGeneralInfos
        );
        createdBooking.current = camelcaseKeys(_createdBooking?.data, {
          deep: true,
        });
        onClose();
        setLoading(false);
        openConfirmationDialog({
          title: "Booking request sent successfully!",
          message:
            "An invoice for the non-refundable payment of 399$ will be sent via email.",
          optionalActionButtonTitle: "Download PDF",
          optionalFunction: onDownloadPDF,
        });
      } catch (e) {
        setLoading(false);
        onClose();
        openError({
          message: "Something went wrong. Please try again!",
        });
      }
    };

    const onDownloadPDF = async () => {
      try {
        await getBookingPdf(createdBooking.current);
        closeConfirmationDialog();
      } catch (e) {
        openError({
          message: "Something went wrong. Please try again!",
        });
        closeConfirmationDialog();
      }
    };

    const numberOfDays = (): number => {
      return quote ? getQuoteDays(quote)?.length : getDays(itinerary)?.length;
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        title="Book itinerary"
        titleVariant={"h1"}
      >
        <Box
          width={550}
          margin={4}
          className="gap-l"
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography variant={"body1"} align={"center"}>
            Book the selected itinerary
          </Typography>
          <Box>
            <BookItineraryForm
              onSubmit={onSubmit}
              onClose={onClose}
              loading={loading}
              numberOfDays={numberOfDays()}
              insurances={insurances}
              agency={agency}
              agent={user}
              generalInfosItinerary={generalInfosItinerary}
              itineraryPrice={quote ? quote.itineraryPrice : price}
            />
          </Box>
        </Box>
      </Dialog>
    );
  }
);

ItineraryBookingDialog.displayName = "ItineraryBookingDialog";

export default ItineraryBookingDialog;
