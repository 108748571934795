import { Control, Controller, FieldValues } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import Colors from "@app/types/colors.enum";

interface CheckboxInputProps {
  name: string;
  control: Control<FieldValues>;
  options: string[];
  label: string;
  minimumChecked?: number;
  displayAs?: string;
}

const CheckboxInput = ({
  name,
  control,
  options,
  label,
  minimumChecked = 1,
  displayAs = "row",
}: CheckboxInputProps) => {
  const [selected, setSelected] = useState([]);

  const handleCheck = (checkedId) => {
    const newSelectedIds = selected?.includes(checkedId)
      ? selected?.filter((id) => id !== checkedId)
      : [...(selected ?? []), checkedId];
    setSelected([...newSelectedIds]);
    return newSelectedIds;
  };

  const checkMinimumCheck = (value) => {
    return value.length < minimumChecked
      ? `You need to have at least ${minimumChecked} checked boxes`
      : null;
  };

  const generateCheckboxOptions = (onChange) => {
    return (
      <Box display={"flex"} flexDirection={displayAs}>
        {options.map((option) => (
          <FormControlLabel
            className={"text-color-black"}
            key={option}
            label={option}
            control={
              <Checkbox
                onChange={() => onChange(handleCheck(option))}
                color="primary"
              />
            }
          />
        ))}
      </Box>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div>
            <FormLabel className={"text-color-black"}>{label}</FormLabel>
            {generateCheckboxOptions(onChange)}
            <Box color={Colors.ERROR}>{error?.message}</Box>
          </div>
        );
      }}
      rules={{
        validate: checkMinimumCheck,
      }}
    />
  );
};

export default CheckboxInput;
