import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Dialog } from "@app/components";
import { CropperDialogRef } from "@app/profile/types/refs";
import { getCroppedImage } from "@app/utils";
import { v4 as uuidv4 } from "uuid";
import { useUpdateAgency } from "@app/profile/hooks/update-agency.hook";
import { Box } from "@material-ui/core";
import Colors from "@app/types/colors.enum";
import { useSnackbars } from "@app/hooks";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const AgencyLogoDialog = React.forwardRef<CropperDialogRef>((props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState<Crop>({
    height: 0,
    x: 0,
    y: 0,
    unit: "%",
    width: 30,
    aspect: 1,
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Crop>(null);
  const { openError, openSuccess } = useSnackbars();
  const { uploadImage, loading } = useUpdateAgency();

  useImperativeHandle(
    ref,
    (): CropperDialogRef => ({
      onOpen: (image) => onOpen(image),
    })
  );

  const onClose = () => setOpen(false);

  const onOpen = (image: string) => {
    setImage(image);
    setOpen(true);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImage(
        imgRef.current,
        image,
        croppedAreaPixels
      );
      const formData = new FormData();
      formData.append("logo", croppedImage, "Logo-" + uuidv4() + ".png");
      await uploadImage(formData);
      openSuccess({
        message: "Logo successfully saved!",
      });
    } catch (e) {
      openError({ message: "Failed to upload image" });
    }
    setOpen(false);
  }, [croppedAreaPixels]);

  const onCropComplete = useCallback((c) => {
    setCroppedAreaPixels(c);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Crop image"
      titleVariant={"h1"}
    >
      <Box width={550} height={550} marginRight={4} marginLeft={4}>
        <Box
          className={"gap-sm"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box
            height={"500px"}
            width={"500px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <ReactCrop
              circularCrop
              src={image}
              style={{ maxHeight: "500px" }}
              imageStyle={{ maxHeight: "500px" }}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => onCropComplete(c)}
            />
          </Box>
          <Button
            title="Crop & Save"
            width={140}
            height={43}
            color={Colors.PRIMARY}
            hoverColor={Colors.HOVER_PRIMARY}
            withBorder={false}
            textColor={Colors.WHITE}
            onClick={showCroppedImage}
            loading={loading}
          />
        </Box>
      </Box>
    </Dialog>
  );
});

export default AgencyLogoDialog;
