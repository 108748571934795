import { Layout, LoadingSpinner, PageNotFound } from "@app/components";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import Colors from "@app/types/colors.enum";
import { Box } from "@material-ui/core";
import { ItineraryDetails } from "@app/itineraries/components";
import { usePrePostTour } from "@app/pre-post-tours/hooks/use-pre-post-tour.hook";
import ItineraryPlaceholder from "@app/itineraries/components/itinerary-placeholder";

interface QueryParams {
  id: string;
}

const PrePostTour = () => {
  const { id } = useParams<QueryParams>();
  const { prePostTour, loading } = usePrePostTour(+id);
  const history = useHistory();

  const backToItineraries = () => {
    history.push("/pre-post-tours");
  };

  const renderContent = () => {
    if (loading || !prePostTour) {
      return <ItineraryPlaceholder />;
    }

    return (
      <Box paddingTop={4} className={"page-min-height"}>
        <ItineraryDetails
          backButtonTitle={"Back to tours"}
          itinerary={prePostTour}
          onBack={backToItineraries}
        />
      </Box>
    );
  };

  const displayNotFound = () => {
    return <PageNotFound />;
  };

  const renderPage = () => {
    if (!loading && !prePostTour) {
      return displayNotFound();
    }
    return <Layout>{renderContent()}</Layout>;
  };

  return renderPage();
};

export default PrePostTour;
