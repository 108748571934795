import { getType } from "typesafe-actions";
import * as actions from "@app/store/actions/pre-post-tours.actions";
import { Itinerary } from "@app/itineraries/types/interfaces";

export interface PrePostToursState {
  prePostTours: { [id: number]: Itinerary };
  prePostTour: number;
  prePostToursLoaded: boolean;
}

const initialState: PrePostToursState = {
  prePostTours: {},
  prePostTour: null,
  prePostToursLoaded: false,
};

export default (state = initialState, action): PrePostToursState => {
  switch (action.type) {
    case getType(actions.LoadPrePostTours): {
      const prePostTours = action.payload.reduce(
        (result, prePostTour: Itinerary) => {
          result[prePostTour.id] = prePostTour;
          return result;
        },
        {}
      );
      return {
        ...state,
        prePostTours: { ...prePostTours },
        prePostToursLoaded: true,
      };
    }
    case getType(actions.LoadPrePostTour): {
      const prePostTour: Itinerary = action.payload;
      return {
        ...state,
        prePostTour: prePostTour.id,
        prePostTours: {
          ...state.prePostTours,
          [prePostTour.id]: {
            ...state.prePostTours[prePostTour.id],
            ...prePostTour,
            loaded: true,
          },
        },
      };
    }

    default:
      return state;
  }
};
