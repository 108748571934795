import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();

  const onReturn = () => {
    history.push("/itineraries");
  };

  return (
    <Box
      marginTop={"120px"}
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"row"}
    >
      <Box width={"50%"} height={"100%"}>
        <img
          src={"/not-found.png"}
          height={"100%"}
          width={"48%"}
          alt={"not-found"}
          style={{
            objectFit: "cover",
            position: "absolute",
          }}
        />
      </Box>
      <Box
        width={"50%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box fontSize={"300px"} color={"#F5F7FA"}>
          404
        </Box>
        <Box
          position={"absolute"}
          fontSize={"24px"}
          className={"bold"}
          color={"#274D4F"}
          top={"300px"}
        >
          Oh no! Page not found.
        </Box>
        <Box
          marginTop={4}
          marginBottom={8}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box color={"#001129"} fontSize={"20px"}>
            Just because your path is different doesn’t mean you are lost.
          </Box>
          <Box color={"#001129"} fontSize={"20px"}>
            The page you are looking for is not available right now.
          </Box>
        </Box>
        <Button variant={"contained"} color="primary" onClick={onReturn}>
          Return to Homepage
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
