import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import IconText from "./icon-text";
import Colors from "@app/types/colors.enum";
import { Itinerary } from "@app/itineraries/types/interfaces";
import { useSelector } from "react-redux";
import {
  selectItineraryDays,
  selectItineraryPrice,
} from "@app/store/selectors/itineraries.selectors";
import {
  selectPrePostTourDays,
  selectPrePostTourPrice,
} from "@app/store/selectors/pre-post-tours.selectors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    "border-radius": "16px",
    opacity: 1,
    width: "100%",
    height: "416px",
  },
  content: {
    padding: "25px 32px 17px 32px",
  },
  media: {
    "border-radius": "16px 16px 0 0",
    height: "188px",
  },
  label: {
    top: "30px",
    position: "absolute",
    width: "106px",
    height: "41px",
    "line-height": "41px",
    "border-radius": "0px 4px 4px 0px",
    background: "#F1BE7C",
  },
  //   .MuiCardActionArea-focusHighlight {
  //   height: 416px;
  // }
});

interface ItineraryItemProps {
  itinerary: Itinerary;
  onClick: () => void;
}

const ItineraryItem = ({ itinerary, onClick }: ItineraryItemProps) => {
  const classes = useStyles();
  const price = itinerary.type
    ? useSelector(selectPrePostTourPrice(itinerary.id))
    : useSelector(selectItineraryPrice(itinerary.id));
  const days = itinerary.type
    ? useSelector(selectPrePostTourDays(itinerary.id))
    : useSelector(selectItineraryDays(itinerary.id));

  return (
    <Card className={classes.root} onClick={onClick}>
      <CardActionArea>
        {itinerary.type && (
          <Typography
            variant={"body1"}
            align={"center"}
            className={`${classes.label} bold text-color-white`}
          >
            {itinerary.type}
          </Typography>
        )}
        <CardMedia
          className={classes.media}
          image={itinerary.coverImage?.file}
          component="img"
        />
        <CardContent className={classes.content}>
          <Box height={"58px"} paddingBottom={"10px"}>
            <Typography variant={"h4"} className="no-margin">
              {itinerary.name}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} paddingBottom={"17px"}>
            <Box marginRight={"13px"}>
              <IconText
                variant={"caption"}
                icon={"/icons/price-tag.svg"}
                text={price + "$"}
              />
            </Box>
            <Box>
              <IconText
                variant={"caption"}
                icon={"/icons/clock.svg"}
                text={`${days} ${days === 1 ? "day" : "days"}/${days - 1} ${
                  days - 1 === 1 ? "night" : "nights"
                }`}
              />
            </Box>
          </Box>
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize={"12px"}
            height={"57px"}
            lineHeight={1.5}
            marginBottom={2}
          >
            {itinerary.coverDescription}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            {/*<IconText*/}
            {/*  icon={"/icons/pin.svg"}*/}
            {/*  text={itinerary.city + ", " + itinerary.state}*/}
            {/*/>*/}
            <Typography variant={"caption"} className={"bold"}>
              See details
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ItineraryItem;
