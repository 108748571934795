enum Colors {
  WHITE = "#fff",
  PRIMARY = "#f1be7c",
  HOVER_PRIMARY = "#D59C5B",
  BLACK = "#000",
  SECONDARY = "#274D4F",
  HOVER_SECONDARY = "#163133",
  LIGHT_GRAY = "#DEDEDE",
  DARK_GRAY = "#969798",
  GRAY = "#B6B6BF",
  ERROR = "#bb2d25",
  BROWN = "#c6b7a2",
}

export default Colors;
