import React, { useImperativeHandle, useState } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { ImageSliderDialogRef } from "@app/types/image-slider-dialog-ref.interface";
import { Image } from "@app/types/image";
import { ImageSwiper } from "@app/components/index";
import theme from "@app/theme";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
    cursor: "pointer",
    zIndex: 10,
  },
});

const ImageSliderDialog = React.forwardRef<ImageSliderDialogRef>(
  (props, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const [images, setImages] = useState([]);
    const classes = useStyles();

    useImperativeHandle(
      ref,
      (): ImageSliderDialogRef => ({
        onOpen: (images: Image[]) => onOpen(images),
      })
    );

    const onOpen = (images: Image[]) => {
      setImages(images);
      setOpen(true);
    };

    const onClose = () => setOpen(false);

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <Box
          className={classes.closeButton}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          onClick={onClose}
        >
          <Typography variant={"body1"} className={"text-color-white"}>
            Close
          </Typography>
          <IconButton aria-label="close">
            <img
              src="/icons/close-white.svg"
              width={16}
              height={16}
              alt={"close"}
            />
          </IconButton>
        </Box>

        <ImageSwiper images={images} />
      </Dialog>
    );
  }
);

ImageSliderDialog.displayName = "ImageSliderDialog";

export default ImageSliderDialog;
