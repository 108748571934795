import { combineReducers } from "redux";
import authReducer, { AuthState } from "./auth.reducer";
import itinerariesReducer, { ItinerariesState } from "./itineraries.reducer";
import coreReducer, { CoreState } from "@app/store/reducers/core.reducer";
import agencyReducer, { AgencyState } from "@app/store/reducers/agency.reducer";
import clustersReducer, {
  ClustersState,
} from "@app/store/reducers/clusters.reducer";
import prePostToursReducer, {
  PrePostToursState,
} from "@app/store/reducers/pre-post-tours.reducer";
import insurancesReducer, {
  InsurancesState,
} from "@app/store/reducers/insurances.reducer";

export interface AppState {
  auth: AuthState;
  itineraries: ItinerariesState;
  core: CoreState;
  agency: AgencyState;
  clusters: ClustersState;
  prePostTours: PrePostToursState;
  insurances: InsurancesState;
}

export const reducers = combineReducers({
  auth: authReducer,
  itineraries: itinerariesReducer,
  core: coreReducer,
  agency: agencyReducer,
  clusters: clustersReducer,
  prePostTours: prePostToursReducer,
  insurances: insurancesReducer,
});
