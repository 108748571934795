import React from "react";
import { Action } from "@app/itineraries/types/interfaces";
import { Box, Typography } from "@material-ui/core";

interface DayActionProps {
  action: Action;
}

const DayAction = ({ action }: DayActionProps) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"start"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        className="width-75 gap-sm"
      >
        <Box
          bgcolor="#f8f9fc"
          borderRadius={"8px"}
          width={"190px"}
          height={"43px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant={"body1"} className={"bold text-color-green"}>
            {action.name}
          </Typography>
        </Box>
        <Typography variant={"body1"}>{action.description}</Typography>
      </Box>
      <img
        className="width-25 image-radius"
        src={action?.image?.file}
        height={159}
        width={"100%"}
        alt="action"
      />
    </Box>
  );
};

export default DayAction;
