import React from "react";
import { Itinerary } from "@app/itineraries/types/interfaces";
import styled from "styled-components";
import Colors from "@app/types/colors.enum";
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 64px;
  gap: 32px;
`;
interface ItineraryInclusionsProps {
  itinerary: Itinerary;
}

const StyledList = styled.ul`
  margin-top: 0;
  li {
    text-align: left;
    font: normal normal normal 16px/24px Sofia Pro;
    letter-spacing: 0px;
    color: #000000;
    padding-bottom: 16px;

    ::marker {
      color: ${Colors.PRIMARY};
      font-size: 20pt !important;
    }
  }
`;

const ItineraryInclusions = ({ itinerary }: ItineraryInclusionsProps) => {
  return (
    <StyledContainer>
      <img
        className="border-radius-16"
        src={itinerary.coverImage?.file}
        width={"50%"}
        alt="cover"
      />
      <StyledList>
        {itinerary.inclusions.map((inclusion: string, index: number) => (
          <li key={index}>{inclusion}</li>
        ))}
      </StyledList>
    </StyledContainer>
  );
};

export default ItineraryInclusions;
