import { AppState } from "@app/store/reducers";
import { createSelector } from "reselect";
import { ClustersState } from "@app/store/reducers/clusters.reducer";

const getClustersState = (state: AppState): ClustersState => state.clusters;

export const selectClusters = createSelector(getClustersState, (state) =>
  Object.values(state.clusters)
);
export const selectedCluster = createSelector(
  getClustersState,
  (state) => state.clusters[state.selectedCluster]
);
