import { getType } from "typesafe-actions";
import {
  SetUser,
  LogOut,
  OpenLoginDialog,
  CloseLoginDialog,
  UpdateUser,
} from "../actions/auth.actions";
import { User } from "@app/types/user";

export interface AuthState {
  currentUser: User;
  openLogin: boolean;
}

const initialState: AuthState = {
  currentUser: null,
  openLogin: false,
};

export default (state = initialState, action): AuthState => {
  switch (action.type) {
    case getType(LogOut): {
      return {
        ...state,
        openLogin: !!action.payload,
        currentUser: null,
      };
    }
    case getType(SetUser): {
      const user: User = action.payload;
      return { ...state, currentUser: user };
    }
    case getType(OpenLoginDialog): {
      return { ...state, openLogin: true };
    }
    case getType(CloseLoginDialog): {
      return { ...state, openLogin: false };
    }
    case getType(UpdateUser): {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
