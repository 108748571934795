import { useApi } from "@app/hooks";
import { PaginatedResponse } from "@app/types";
import { Cluster } from "@app/itineraries/types/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectClusters } from "@app/store/selectors/clusters.selectors";
import { useEffect } from "react";
import { LoadClusters } from "@app/store/actions/clusters.actions";

export const useClusters = () => {
  const { request, loading } = useApi();
  const clusters: Cluster[] = useSelector(selectClusters);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clusters.length !== 0 || loading) {
      return;
    }
    loadClusters();
  }, []);

  const loadClusters = async () => {
    const filters = {
      active__eq: true,
    };
    const data = await request<PaginatedResponse<Cluster>>({
      url: "records/cluster",
      method: "GET",
      queryParams: {
        ...filters,
        datafy_fields:
          "id,name,price,description,location,short_description,image.file,days.id,days.order,days.day.name,days.day.images.file,days.day.images.id,days.day.actions.name,days.day.actions.description,days.day.actions.image.file,days.day.actions.image.id,days.day.summary," +
          "days.day.top_description,days.day.bottom_description",
        pageSize: 100,
      },
    });
    dispatch(LoadClusters(data.results));
  };

  return { clusters, loading };
};
