import React, { useImperativeHandle, useState } from "react";
import { Dialog } from "@app/components";
import { ResetPasswordDialogRef } from "@app/itineraries/types/refs";
import {
  ResetPasswordForm,
  ResetPasswordSuccess,
} from "@app/modules/auth/components";
import { useResetPassword } from "@app/modules/auth/hooks";
import { Box } from "@material-ui/core";

const ResetPasswordDialog = React.forwardRef<ResetPasswordDialogRef>(
  (props, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const { loading, sendResetPassword } = useResetPassword();

    useImperativeHandle(
      ref,
      (): ResetPasswordDialogRef => ({
        onOpen: () => onOpen(),
      })
    );

    const onOpen = () => {
      setSuccess(false);
      setOpen(true);
    };
    const onClose = () => setOpen(false);
    const onResetPassword = () => setSuccess(false);

    const onSend = async (email: string) => {
      try {
        await sendResetPassword(email);
      } finally {
        setSuccess(true);
      }
    };

    const title = success ? "Instructions sent" : "Reset your password";

    return (
      <Dialog open={open} onClose={onClose} title={title}>
        <Box width={500}>
          {!success && <ResetPasswordForm loading={loading} onReset={onSend} />}
          {success && (
            <ResetPasswordSuccess
              onResetPassword={onResetPassword}
              done={onClose}
            />
          )}
        </Box>
      </Dialog>
    );
  }
);

ResetPasswordDialog.displayName = "ResetPasswordDialog";

export default ResetPasswordDialog;
