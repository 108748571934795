import React from "react";
import {
  Box,
  createStyles,
  Theme,
  Typography,
  WithStyles,
} from "@material-ui/core";
import { PrimaryButton } from "@app/components";
import { withStyles } from "@material-ui/core/styles";
import Lottie from "react-lottie";

interface Props extends WithStyles<typeof styles> {
  done: () => void;
  onResetPassword: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    resetPassword: {
      cursor: "pointer",
    },
  });

const lottieOptions = {
  loop: false,
  autoplay: true,
  path: "/animations/success_animation.json",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ResetPasswordSuccess = withStyles(styles)((props: Props) => {
  const { classes } = props;

  return (
    <>
      <Box marginBottom={4} marginX={4}>
        <Typography variant={"body2"} align={"center"}>
          Check your e-mail! {"\n"} We have sent the password recovery to your
          email.
        </Typography>
      </Box>

      <Box marginY={4} display={"flex"} justifyContent={"center"}>
        <Lottie options={lottieOptions} height={110} width={118} />
      </Box>

      <Box marginY={4} display={"flex"} justifyContent={"center"}>
        <PrimaryButton onClick={props.done} title={"Done"} />
      </Box>

      <Typography component="div" variant={"body2"} align={"center"}>
        Did not receive the email? Check your spam or
        <Typography
          display={"inline"}
          variant={"body2"}
          color={"primary"}
          className={classes.resetPassword}
          onClick={props.onResetPassword}
        >
          {" "}
          Try another email address
        </Typography>
      </Typography>
    </>
  );
});

export default ResetPasswordSuccess;
