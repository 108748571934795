import React, { useImperativeHandle, useState } from "react";
import { CreateItineraryDialogRef } from "@app/itineraries/types/refs";
import { Box, Typography } from "@material-ui/core";
import { Dialog } from "@app/components";
import { CreateItineraryForm } from "@app/itineraries/components";
import { SetCustomItineraryFilters } from "@app/store/actions/itineraries.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomItinerary } from "@app/itineraries/types/interfaces";

const CreateItineraryDialog = React.forwardRef<CreateItineraryDialogRef>(
  (props, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useImperativeHandle(
      ref,
      (): CreateItineraryDialogRef => ({
        onOpen: () => onOpen(),
      })
    );

    const onClose = () => {
      dispatch(SetCustomItineraryFilters(null));

      setOpen(false);
    };

    const onOpen = () => {
      setOpen(true);
    };

    const onSubmit = (data: CustomItinerary) => {
      dispatch(SetCustomItineraryFilters(data));
      onClose();
      history.push(`/itineraries/new`);
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        title="Design your own itinerary"
        titleVariant={"h1"}
      >
        <Box
          width={550}
          marginY={4}
          className="gap-l"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant={"body1"} align={"center"}>
            Please select the following, in order to move forward and create an
            itinerary
          </Typography>
          <Box>
            <CreateItineraryForm onClose={onClose} onSubmit={onSubmit} />
          </Box>
        </Box>
      </Dialog>
    );
  }
);

CreateItineraryDialog.displayName = "CreateItineraryDialog";

export default CreateItineraryDialog;
