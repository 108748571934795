import { Box } from "@material-ui/core";
import React, { useImperativeHandle, useRef, useState } from "react";
import { Dialog } from "@app/components";
import RegisterAgentForm from "@app/itineraries/components/register-agent-form";
import {
  LoginDialogRef,
  RegisterAgencyRef,
  RegisterAgentRef,
} from "@app/itineraries/types/refs";
import { useAgent } from "@app/modules/auth/hooks";
import AgentRequest from "@app/types/agent-request";
import { useConfirmationDialog } from "@app/hooks/confirmation-dialog.hook";
import { RegisterAgencyDialog } from "@app/modules/auth/containers/register-agency-dialog";
import { useSendEmailNotification } from "@app/itineraries/hooks";

const RegisterAgentDialog = React.forwardRef<RegisterAgentRef>((props, ref) => {
  const [open, setOpen] = useState(false);
  const { loading, saveAgent } = useAgent();
  const { sendNewAgentEmail } = useSendEmailNotification();
  const { openConfirmationDialog } = useConfirmationDialog();
  const [error, setError] = useState<string>("");
  const registerAgencyRef = useRef<RegisterAgencyRef>(null);

  useImperativeHandle(
    ref,
    (): RegisterAgentRef => ({
      onOpen: () => onOpen(),
    })
  );

  const onClose = () => setOpen(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onOpenConfirmationDialog = () => {
    openConfirmationDialog({
      title: "Request submitted!",
      message:
        "Thank you. Our team will check your request and get back to you as soon as possible.",
    });
  };

  const onSubmit = async (data: AgentRequest) => {
    try {
      const agent = await saveAgent(data);
      sendNewAgentEmail(agent);
      onClose();
      onOpenConfirmationDialog();
    } catch (e) {
      setError(e?.data?.message);
    }
  };

  const onRegisterAgency = () => {
    onClose();
    registerAgencyRef.current.onOpen();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        titleVariant={"h1"}
        title={"Travel Agent Registration"}
      >
        <Box width={"536px"} marginLeft={2} marginRight={2}>
          <Box marginBottom={4} className="body-text-1" textAlign={"center"}>
            Don’t have an ARC/IATA/TRUE/CLIA number?{" "}
            <span
              className="button-text cursor-pointer text-color-primary"
              onClick={onRegisterAgency}
            >
              Register your agency.
            </span>
          </Box>
          <RegisterAgentForm
            onClose={onClose}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
        {error && (
          <Box paddingTop={2} textAlign={"center"} className={"text-color-red"}>
            {error?.charAt(0)?.toUpperCase() + error?.slice(1)}
          </Box>
        )}
      </Dialog>
      <RegisterAgencyDialog ref={registerAgencyRef} />
    </>
  );
});

RegisterAgentDialog.displayName = "RegisterAgentDialog";

export default RegisterAgentDialog;
