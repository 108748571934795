import { AppState } from "@app/store/reducers";
import { createSelector } from "reselect";
import { InsurancesState } from "@app/store/reducers/insurances.reducer";

const getInsurancesState = (state: AppState): InsurancesState =>
  state.insurances;

export const selectInsurances = createSelector(getInsurancesState, (state) =>
  Object.values(state.insurances)
);
