import styled from "styled-components";
import React from "react";
import Colors from "@app/types/colors.enum";
interface InputProps {
  label: string;
  value: string;
  withBorder?: boolean;
}

const StyledLabel = styled.div`
  text-align: left;
  font: normal normal normal 16px/24px Sofia Pro;
  letter-spacing: 0px;
  color: ${Colors.DARK_GRAY};
`;

const StyledValue = styled.div`
  text-align: left;
  font: normal normal normal 18px/21px Sofia Pro;
  letter-spacing: 0px;
  padding-top: 14px;
  padding-bottom: 24px;
  color: ${Colors.BLACK};
`;
const StyledContainer = styled.div<{ withBorder: boolean }>`
  width: 549px;
  display: flex;
  flex-direction: column;
  border-bottom: ${(props) =>
    props.withBorder ? `1px solid ${Colors.GRAY};` : ""};
`;

export const ReadonlyInput = ({
  label,
  value = "",
  withBorder,
}: InputProps) => {
  return (
    <StyledContainer withBorder={withBorder}>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue>{value}</StyledValue>
    </StyledContainer>
  );
};

export default ReadonlyInput;
