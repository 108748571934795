import React from "react";
import { Dialog, LoadingSpinner } from "@app/components";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Colors from "@app/types/colors.enum";

interface Props {
  title: string;
  open: boolean;
  message: string;
  onClose: (success?: boolean) => void;
}

const LoadingDialog = ({ title, open, message, ...props }: Props) => {
  const onClose = () => props.onClose();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      titleVariant={"h1"}
      hideClose={true}
      disableClosing={true}
    >
      <Box
        width={400}
        marginY={4}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        className="gap-l"
      >
        {message && (
          <Typography variant={"body1"} align={"center"}>
            {message}
          </Typography>
        )}{" "}
        <LoadingSpinner color={Colors.PRIMARY} size={64} />
      </Box>
    </Dialog>
  );
};

export default LoadingDialog;
