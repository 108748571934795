import React from "react";
import { Cluster, ClusterDay } from "@app/itineraries/types/interfaces";
import ItineraryDayDetails from "@app/itineraries/components/itineary-day-details";
import { Box } from "@material-ui/core";

interface ItineraryDaysProps {
  clusters: Cluster[];
}

const ItineraryDays = ({ clusters }: ItineraryDaysProps) => {
  const getItineraryDays = () => {
    const days = clusters.reduce((allDays: ClusterDay[], cluster: Cluster) => {
      allDays.push(
        ...cluster.days.sort((a, b) => (a.order > b.order ? 1 : -1))
      );
      return allDays;
    }, []);
    return days.map((clusterDay: ClusterDay, index: number) => {
      return (
        <ItineraryDayDetails key={index} day={clusterDay.day} index={index} />
      );
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      className={"gap-xl"}
      marginBottom={8}
    >
      {getItineraryDays()}
    </Box>
  );
};

export default ItineraryDays;
