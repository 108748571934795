import React from "react";
import { useForm } from "react-hook-form";
import { TextInput, CancelButton, PrimaryButton } from "@app/components";
import AgentRequest from "@app/types/agent-request";
import { Box } from "@material-ui/core";

interface FormProps {
  onClose: () => void;
  onSubmit: (data: AgentRequest) => void;
  loading: boolean;
}

const RegisterAgentForm = ({ onClose, onSubmit, loading }: FormProps) => {
  const { control, handleSubmit } = useForm({
    mode: "onTouched",
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display={"flex"} flexDirection={"column"} className={"gap-l"}>
        <TextInput
          required={true}
          name="customerId"
          type="text"
          label="Customer ID"
          control={control}
        />

        <TextInput
          required={true}
          name="emailAddress"
          type="email"
          label="Email address"
          control={control}
          rules={{
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: "Invalid email address",
            },
          }}
        />
        <TextInput
          required={true}
          name="firstName"
          type="text"
          label="First name"
          control={control}
        />
        <TextInput
          required={true}
          name="lastName"
          type="text"
          label="Last name"
          control={control}
        />
        <TextInput
          required={true}
          name="phoneNumber"
          type="tel"
          label="Phone number"
          control={control}
          rules={{
            pattern: {
              value: /^[0-9]*$/i,
              message: "Invalid phone number",
            },
            maxLength: {
              value: 15,
              message: "The maximum length is 15",
            },
          }}
        />

        <Box
          marginTop={3}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          className={"gap-l"}
        >
          <CancelButton onClose={onClose} />
          <PrimaryButton loading={loading} title="Submit" />
        </Box>
      </Box>
    </form>
  );
};

export default RegisterAgentForm;
