import { useDispatch } from "react-redux";
import {
  CloseLoadingDialog,
  OpenLoadingDialog,
} from "@app/store/actions/core.actions";
import { LoadingDialog } from "@app/types/loading-dialog.interface";

export const useLoadingDialog = () => {
  const dispatch = useDispatch();

  const openLoadingDialog = (loadingDialog: LoadingDialog) => {
    dispatch(OpenLoadingDialog(loadingDialog));
  };

  const closeLoadingDialog = () => {
    dispatch(CloseLoadingDialog());
  };

  return { openLoadingDialog, closeLoadingDialog };
};
