import { RawBooking } from "@app/bookings/types/interfaces/raw-booking";
import { BookingStatuses } from "@app/bookings/types/enums";

export class Booking {
  id: number;
  bookingNo: number;
  clientName: string;
  agentName: string;
  amount: number;
  commission: number;
  arrivalDate: string;
  returnDate: string;
  depositPayment: string;
  finalPayment: string;
  isDownloading: boolean;
  isPersonalized: boolean;
  status: BookingStatuses;

  constructor(data: RawBooking) {
    this.id = data.id;
    this.bookingNo = data.id;
    this.isDownloading = false;
    this.isPersonalized = data.mobilityIssue !== null;
    this.arrivalDate = data.arrivalDate;
    this.returnDate = data.returnDate;
    this.depositPayment = data.depositPayment;
    this.finalPayment = data.finalPayment;
    this.clientName = data.clientName;
    this.amount = data.insurancePrice + data.itineraryPrice;
    this.commission = data.itineraryCommission + data.insuranceCommission;
    this.status = data.status;
    if (data?.agent?.firstName) {
      this.agentName = data.agent.firstName + " " + data.agent.lastName;
    }
  }
}
