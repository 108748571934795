import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  CancelButton,
  DatePicker,
  IconButton,
  PrimaryButton,
  RadioInput,
  TextInput,
} from "@app/components";
import PassengerCard from "@app/itineraries/components/passenger-card";
import Colors from "@app/types/colors.enum";
import { Insurance } from "@app/itineraries/types/interfaces";
import { Booking } from "@app/itineraries/types/classes";
import { User } from "@app/types/user";
import { Box } from "@material-ui/core";
import { AgencyDetails } from "@app/profile/types/interfaces";
import { GeneralInfosItinerary } from "@app/types";

interface FormProps {
  onSubmit: (data, passengers) => void;
  onClose: () => void;
  loading: boolean;
  insurances: Insurance[];
  numberOfDays: number;
  agency: AgencyDetails;
  agent: User;
  itineraryPrice: number;
  generalInfosItinerary: GeneralInfosItinerary;
}

const BookItineraryForm = ({
  onSubmit,
  onClose,
  loading,
  insurances,
  itineraryPrice,
  agency,
  numberOfDays,
  agent,
  generalInfosItinerary,
}: FormProps) => {
  const { control, handleSubmit, watch } = useForm({
    mode: "all",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "passengers",
  });
  const [returnDate, setReturnDate] = useState(null);
  const options = ["Yes", "No"];
  const insuranceOptions = [
    "Standard insurance",
    "With CFAR coverage",
    "Decline insurance",
  ];
  const insurance = watch("insurance");
  const passengers = watch("passengers");

  const arrivalDate = watch("arrivalDate");

  useEffect(() => addPassenger(), []);

  useEffect(() => {
    if (arrivalDate) {
      const result = new Date(arrivalDate);
      result.setDate(result.getDate() + (numberOfDays - 1));
      setReturnDate(result);
    }
  }, [arrivalDate]);

  const insuranceSelected = () => {
    return insurance !== "Decline insurance";
  };

  const addPassenger = () => {
    append({});
  };

  const removePassenger = (index) => () => {
    remove(index);
  };

  const getItineraryPrice = () => {
    return itineraryPrice * fields.length;
  };

  const getItineraryCommission = () => {
    return (
      (((getItineraryPrice() *
        generalInfosItinerary?.itineraryCommissionPercentage) /
        100) *
        agency?.itineraryCommission) /
      100
    );
  };

  const getInsuranceCommission = () => {
    return (getInsurancePrice() * agency?.insuranceCommission) / 100;
  };

  const getInsurancePrice = () => {
    return passengers?.reduce(
      (totalInsurance: number, passenger) =>
        totalInsurance +
        (getPassengerInsurance(passenger) * itineraryPrice) / 100,
      0
    );
  };

  const getPassengerInsurance = (passenger) => {
    if (passenger?.birthday) {
      const age = computeAge(passenger.birthday);
      const orderedInsurances = insurances?.sort((a, b) =>
        a.age < b.age ? 1 : -1
      );
      return (
        orderedInsurances.find(
          (insurance: Insurance) => age >= insurance.age
        )?.[
          insurance === "Standard insurance"
            ? "standardPercentage"
            : "cfarPercentage"
        ] || 0
      );
    }
    return 0;
  };

  const computeAge = (birthday: Date) => {
    const now = new Date();

    let years = now.getFullYear() - birthday.getFullYear();

    if (
      now.getMonth() < birthday.getMonth() ||
      (now.getMonth() == birthday.getMonth() &&
        now.getDate() < birthday.getDate())
    ) {
      years--;
    }
    return years;
  };

  const passengersCards = () => {
    return fields.map((item, index: number) => {
      return (
        <PassengerCard
          key={item.id}
          index={index}
          control={control}
          onDelete={removePassenger(index)}
        />
      );
    });
  };

  const getFinalPayment = (arrivalDate: number) => {
    const currentDay = new Date();
    const finalPayment = new Date(arrivalDate - 90 * 24 * 60 * 60 * 1000);
    return finalPayment >= currentDay ? finalPayment : currentDay;
  };

  const getDepositPayment = (arrivalDate: Date) => {
    const currentDay = new Date();
    const depositPayment = new Date(
      new Date().getTime() + 3 * 24 * 60 * 60 * 1000
    );
    return arrivalDate >= depositPayment ? depositPayment : currentDay;
  };

  const getInsuranceDeposit = (arrivalDate: Date) => {
    const currentDay = new Date();
    const depositPayment = new Date(
      new Date().getTime() + 12 * 24 * 60 * 60 * 1000
    );
    return arrivalDate >= depositPayment ? depositPayment : currentDay;
  };

  const onSubmitForm = (data) => {
    const insuranceDeposit = getInsuranceDeposit(new Date(data.arrivalDate))
      .toISOString()
      .substring(0, 10);
    const booking: Booking = {
      arrivalDate: data.arrivalDate.toISOString().substring(0, 10),
      returnDate: data.returnDate.toISOString().substring(0, 10),
      noOfRooms: data.numberOfRooms,
      welcomeGiftBasket: data.welcomeGift === "Yes",
      agent: agent.id,
      agency: agent.agency,
      depositPayment: getDepositPayment(new Date(data.arrivalDate))
        .toISOString()
        .substring(0, 10),
      depositBalance: insuranceDeposit,
      insuranceDeposit: insuranceDeposit,
      finalPayment: getFinalPayment(data.arrivalDate)
        .toISOString()
        .substring(0, 10),
      itineraryPrice: getItineraryPrice(),
      insuranceCommission: insuranceSelected() ? getInsuranceCommission() : 0,
      itineraryCommission: getItineraryCommission(),
      insurancePrice: insuranceSelected() ? getInsurancePrice() : 0,
      insuranceCommissionPercentage: agency.insuranceCommission,
      itineraryCommissionPercentage: agency.itineraryCommission,
      clientName: passengers[0].fullname,
    };
    if (data.travellingWith) {
      booking.travellingWith = data.travellingWith;
    }

    onSubmit(booking, passengers);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        className="gap-l
      "
      >
        <DatePicker
          control={control}
          name="arrivalDate"
          label="Arrival Date"
          required={true}
          disablePast={true}
        />
        <DatePicker
          control={control}
          name="returnDate"
          label="Return Date"
          required={true}
          minDateMessage={
            "Return date should not be before arrival date and difference between return date and arrival date should match number of nights"
          }
          maxDateMessage={
            "Return date should not be before arrival date and difference between return date and arrival date should match number of nights"
          }
          minDate={returnDate}
          maxDate={returnDate}
          disablePast={true}
        />
        <TextInput
          control={control}
          name="numberOfRooms"
          label="No of rooms"
          type="number"
          required={true}
          rules={{
            min: {
              value: 1,
              message: "The number of rooms must be a positive integer",
            },
          }}
        />
        {passengersCards()}
        {fields.length < 8 && (
          <IconButton
            iconPath="/icons/add.svg"
            title="Add passenger"
            width={155}
            height={20}
            iconHeight={15}
            iconWidth={15}
            hoverColor={Colors.PRIMARY}
            color={Colors.PRIMARY}
            onClick={addPassenger}
          />
        )}
        <div className="body-text-1 text-color-black">
          No. of passengers: {fields.length}
        </div>
        <div>
          <Box className="body-text-1 text-color-black" paddingBottom={"16px"}>
            Travelling with
          </Box>
          <TextInput
            control={control}
            name="travellingWith"
            label="Booking number or passenger name (optional)"
            type="text"
            required={false}
          />
        </div>
        <Box display={"flex"} alignItems={"flex-end"} flexDirection={"column"}>
          <div className="button-text text-color-black">
            Total itinerary price: {getItineraryPrice().toFixed(2)}$
          </div>
          <div className="body-text-2 text-color-black">
            Itinerary commission: {getItineraryCommission().toFixed(2)}$
          </div>
        </Box>
        <RadioInput
          name="welcomeGift"
          label="Are you interested in adding a welcome gift basket for your client upon arrival? More details in pricing
        to be provided via email after booking confirmed."
          control={control}
          options={options}
        />
        <RadioInput
          name="insurance"
          label="Insurance"
          control={control}
          options={insuranceOptions}
        />
        {insuranceSelected() && (
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            flexDirection={"column"}
          >
            <div className="button-text text-color-black">
              Total insurance price: {getInsurancePrice()?.toFixed(2)}$
            </div>
            <div className="body-text-2 text-color-black">
              Insurance commission: {getInsuranceCommission()?.toFixed(2)}$
            </div>
          </Box>
        )}
        <div className="body-text-2 margin-top-16">
          CFAR coverage is up to 75% of the prepaid, nonrefundable trip cost.
          CFAR is optional and available for purchase at the individual level.{" "}
          Trip cancellation must be 48 hours or more prior to scheduled
          departure. CFAR is available if purchased within 21 days of the date
          your initial deposit for your trip is received, and insure the full
          cost of any subsequent arrangements added to your trip within 21 days
          of the date of your payment. For $0 Trip Cost there is no CFAR.
          <strong>
            {" "}
            This benefit is not available to residents of New York State.
          </strong>{" "}
          This Cancel for Any Reason Benefit does not cover penalties associated
          with any Travel Arrangements not provided by Retail Travel Supplier or
          the failure of Retail Travel Supplier to provide the bargained-for
          Travel Arrangements due to cessation of operations for any reason. You
          can see the insurance policy{" "}
          <a
            href="https://storage.googleapis.com/romania-adventures-development/insurance-policy/TTP%20Plan%20doc.pdf"
            target={"blank"}
          >
            here
          </a>
        </div>
        <div>
          <Box
            height={"64px"}
            bgcolor={Colors.BROWN}
            borderRadius={"8px"}
            marginTop={"16px"}
            display={"flex"}
            flexDirection={"column"}
            paddingLeft={"16px"}
            justifyContent={"center"}
          >
            <div className="button-text text-color-white">
              Total package price:{" "}
              {(
                getItineraryPrice() +
                (insuranceSelected() ? getInsurancePrice() : 0)
              ).toFixed(2)}
              $
            </div>
            <div className="body-text-2 text-color-white">
              Total package commission:{" "}
              {(
                getItineraryCommission() +
                (insuranceSelected() ? getInsuranceCommission() : 0)
              ).toFixed(2)}
              $
            </div>
          </Box>
          <div className="body-text-2 margin-top-16">
            *Disclaimer: Payments are calculated based on the number of
            travelers indicated above, on a double occupancy basis. Where
            applicable, a single supplement will apply - adjustments made after
            booking is confirmed
          </div>
        </div>
        <Box
          className="gap-l"
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
        >
          <CancelButton onClose={onClose} />
          <PrimaryButton loading={loading} title="Submit" />
        </Box>
      </Box>
    </form>
  );
};

export default BookItineraryForm;
