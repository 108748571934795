import React, { useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import { Button, Dialog } from "@app/components";
import Colors from "@app/types/colors.enum";
import LoginForm from "@app/itineraries/components/login-form";
import {
  LoginDialogRef,
  RegisterAgencyRef,
  RegisterAgentRef,
  ResetPasswordDialogRef,
} from "@app/itineraries/types/refs";
import { useAuth } from "@app/modules/auth/hooks";
import * as authActions from "@app/store/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectOpenLoginDialog } from "@app/store/selectors/auth.selectors";
import { useQuery } from "@app/hooks";
import { RegisterAgencyDialog } from "@app/modules/auth/containers/register-agency-dialog";
import RegisterAgentDialog from "@app/modules/auth/containers/register-agent-dialog";
import ResetPasswordDialog from "@app/modules/auth/containers/reset-password-dialog";

const LoginDialog = React.forwardRef<LoginDialogRef>((props, ref) => {
  const dispatch = useDispatch();
  const openLoginDialog = useSelector(selectOpenLoginDialog);
  const { loginIn, logIn } = useAuth();
  const resetPasswordDialogRef = useRef<ResetPasswordDialogRef>(null);
  const registerAgencyRef = useRef<RegisterAgencyRef>(null);
  const registerAgentRef = useRef<RegisterAgentRef>(null);
  const { state } = useQuery();

  const onClose = () => dispatch(authActions.CloseLoginDialog());

  const onRegisterAgent = () => {
    onClose();
    registerAgentRef.current.onOpen();
  };

  const onRegisterAgency = () => {
    onClose();
    // onCloseRegisterAgentModal();
    registerAgencyRef.current.onOpen();
  };

  const onResetPassword = () => {
    onClose();
    resetPasswordDialogRef.current.onOpen();
  };

  const onLogin = (email: string, password: string) =>
    logIn(email, password, state?.returnUrl);

  return (
    <>
      <Dialog
        open={openLoginDialog}
        onClose={onClose}
        title={""}
        titleVariant={"h1"}
      >
        <Box paddingTop={1}>
          <Box marginBottom={4}>
            <Typography align={"center"} variant={"h1"} color={"secondary"}>
              Log into your
            </Typography>
            <Typography align={"center"} variant={"h6"}>
              TRAVEL ADVISOR ACCOUNT
            </Typography>
          </Box>
          <LoginForm
            onClose={onClose}
            logIn={onLogin}
            loading={loginIn}
            resetPassword={onResetPassword}
          />
          <Box marginBottom={4} marginTop={6}>
            <Typography align={"center"} variant={"body1"}>
              Don&#39;t have an account?
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className={"gap-m"}
          >
            <Button
              title="Register agency"
              width={262}
              height={43}
              color={Colors.SECONDARY}
              withBorder={false}
              textColor={Colors.WHITE}
              hoverColor={Colors.HOVER_SECONDARY}
              borderRadius={8}
              onClick={onRegisterAgency}
            />
            <Button
              title="Register agent"
              width={262}
              height={43}
              color={Colors.SECONDARY}
              withBorder={false}
              textColor={Colors.WHITE}
              hoverColor={Colors.HOVER_SECONDARY}
              borderRadius={8}
              onClick={onRegisterAgent}
            />
          </Box>
        </Box>
      </Dialog>
      <RegisterAgencyDialog ref={registerAgencyRef} />
      <RegisterAgentDialog ref={registerAgentRef} />
      <ResetPasswordDialog ref={resetPasswordDialogRef} />
    </>
  );
});

LoginDialog.displayName = "LoginDialog";

export default LoginDialog;
