import { createAction } from "typesafe-actions";
import { User } from "@app/types/user";

const LOG_OUT = "[Auth] Log Out";
const OPEN_LOGIN_DIALOG = "[Auth] Open Login Dialog";
const CLOSE_LOGIN_DIALOG = "[Auth] Close Login Dialog";
const SET_USER = "[Auth] Set User";
const UPDATE_USER = "[Auth] Update User";

export const LogOut = createAction(
  LOG_OUT,
  (openLoginModal: boolean) => openLoginModal
)<boolean>();
export const SetUser = createAction(SET_USER, (user: User) => user)<User>();
export const UpdateUser = createAction(
  UPDATE_USER,
  (user: User) => user
)<User>();

export const OpenLoginDialog = createAction(OPEN_LOGIN_DIALOG)<void>();
export const CloseLoginDialog = createAction(CLOSE_LOGIN_DIALOG)<void>();
