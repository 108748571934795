import React from "react";
import { Itinerary } from "@app/itineraries/types/interfaces";
import ItineraryItem from "./itinerary-item";
import { Box } from "@material-ui/core";
import { ItineraryCardListPlaceholder } from "@app/itineraries/components";

interface ItinerarySectionProps {
  itineraries: Itinerary[];
  onClick: (id: number) => void;
  loading: boolean;
}

const ItinerarySection = ({
  itineraries,
  loading,
  onClick,
}: ItinerarySectionProps) => {
  const itinerariesList = itineraries.map((itinerary) => (
    <ItineraryItem
      key={itinerary.id}
      itinerary={itinerary}
      onClick={() => onClick(itinerary.id)}
    />
  ));

  if (loading) {
    return <ItineraryCardListPlaceholder numberOfCards={8} />;
  }

  return (
    <Box
      width={"100%"}
      alignItems={"center"}
      justifyItems={"center"}
      display={"grid"}
      gridTemplateColumns={"repeat(auto-fill, minmax(308px, 1fr))"}
      className={"gap-l"}
      marginBottom={4}
    >
      {itinerariesList}
    </Box>
  );
};

export default ItinerarySection;
